<div class="container mt-5">
  <h1>Uitgaande Creditnota</h1>
  <form (ngSubmit)="onSubmit()" style="margin-top:50px;" #aankoopForm="ngForm">

    <div class="form-group row">
      <label for="datum" class="col-form-label col-12 col-md-3">Datum</label>
      <div class="col-12 col-md-4">
        <div class="input-group">
        <input class="form-control" ngbDatepicker #d="ngbDatepicker" [(ngModel)]="uitgCreditNota.datum" name="datum" required>
        <div class="input-group-append" (click)="d.toggle()" type="button">
          <button type="button" class="btn btn-secondary"><span class="fa fa-calendar"></span></button>
         </div>
         </div>
      </div>
      <div class="col-12 col-md-5">
        <div class="error col-form-label" *ngIf="aankoopForm.controls?.datum?.invalid">
          Vul een geldige datum in.
        </div>
      </div>
    </div>



    <div class="form-group row">
      <label for="klant" class="col-12 col-md-3 col-form-label">Klant
        <span class="error" *ngIf="!uitgCreditNota.klant._id"><strong>*</strong></span>
      </label>

      <div class="col-12 col-md-4">
        <select id="klant" name="klant" [(ngModel)]="uitgCreditNota.klant._id" (change)="getKlant(klantName.options[klantName.selectedIndex].text)"
          required class="form-control" #klantName="ngModel">
          <option></option>
          <option *ngFor="let klant of klanten" [ngValue]="klant._id">{{klant.name}}</option>
        </select>
      </div>
    </div>

    <div class="form-group row">
      <label for="klant" class="col-12 col-md-3 col-form-label">
        Intracommunautair <i class="fa fa-info-circle" aria-hidden="true" ngbTooltip="Een intracommunautaire levering is een verkoop van goederen door een btw-plichtige verkoper uit een EU-lidstaat aan een btw-plichtige koper met een geldig btw-nummer uit een andere EU-lidstaat. Hierbij moeten de goederen effectief van de ene lidstaat naar de andere lidstaat worden vervoerd."></i>
      </label>
      <div class="col-12 col-md-4 col-form-label">
        <input type="checkbox" name="intracommunautair" id="intracommunautair" [checked]="uitgCreditNota.intracommunautair" [(ngModel)]="uitgCreditNota.intracommunautair">
      </div>
    </div>

    <div class="form-group row" *ngIf="uitgCreditNota.intracommunautair">
      <label for="klant" class="col-12 col-md-3 col-form-label">
        IC Type
        <span *ngIf="!uitgCreditNota.icType" class="error"><strong>*</strong></span>
      </label>
      <div class="col-12 col-md-4">
        <select class="form-control" name="icType" id="icType" [(ngModel)]="uitgCreditNota.icType" required>
          <option value="Diensten">Diensten</option>
          <option value="Levering">Levering</option>
          <option value="Driehoeksverkeer">Driehoeksverkeer</option>
        </select>
      </div>
    </div>

    <div class="form-group row">
      <label for="datum" class="col-12 col-md-3 col-form-label">Bedrag excl. Btw
        <span class="error" *ngIf="!uitgCreditNota.bedragExclBTW"><strong>*</strong></span>
      </label>
      <div class="col-12 col-md-4">
        <input appNumber #bedragExclBtw="ngModel" class="form-control" type="text" [(ngModel)]="uitgCreditNota.bedragExclBTW" name="bedragExclBTW" required (ngModelChange)="calculateBTW()">
      </div>
      <div class="col-12 col-md-5 col-form-label">
        <span class="error" *ngIf="bedragExclBtw.errors?.number">Vul een geldig getal in</span>
      </div>
    </div>

    <div class="form-group row">
      <label for="datum" class="col-12 col-md-3 col-form-label">Btw
        <span class="error" *ngIf="!uitgCreditNota.BTW && uitgCreditNota.BTW !== 0"><strong>*</strong></span>
      </label>
      <div class="col-12 col-md-2">
        <select class="form-control" [(ngModel)]="uitgCreditNota.BTW" name="BTW" required (ngModelChange)="calculateBTW()">
          <option [value]="-1">n.v.t</option>
          <option [value]="0">0</option>
          <option [value]="6">6</option>
          <option [value]="12">12</option>
          <option [value]="21">21</option>
        </select>
      </div>
      <div class="col-12 col-md-4">
        <input type="text" class="form-control" disabled [ngModel]="calculatedBTW | currencyFormat" name="calculatedBTW" maxlength="5">
      </div>
    </div>

    <div class="form-group row">
      <label for="datum" class="col-12 col-md-3 col-form-label">Bedrag incl. Btw</label>
      <div class="col-12 col-md-4">
        <input class="form-control" type="text" [ngModel]="uitgCreditNota.bedragInclBTW | currencyFormat" name="bedragInclBTW" disabled>
      </div>
    </div>

    <div class="form-group row">
      <label for="datum" class="col-12 col-md-3 col-form-label">Omschrijving</label>
      <div class="col-12 col-md-4">
        <input class="form-control" type="text" [(ngModel)]="uitgCreditNota.omschrijving" name="omschrijving">
      </div>
    </div>

    <div class="row">
      <div class="col-12 error mb-3">
        * Verplicht in te vullen
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <button type="button" class="btn btn-light mr-2" (click)="navigateAway()">Annuleren</button>
        <button type="submit" *ngIf="!isEdit" class="btn btn-primary" [disabled]="!aankoopForm.valid">Toevoegen</button>
        <button type="submit" *ngIf="isEdit" class="btn btn-primary" [disabled]="!aankoopForm.valid">Opslaan</button>
      </div>
    </div>
  </form>

</div>

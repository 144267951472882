import { PaginationService } from './../_services/pagination/pagination.service';
import { AuthenticationService } from './../_services/authentication.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LeverancierService } from './leverancier.service';
import { Leverancier } from '../../models/Leverancier';
import { take } from 'rxjs/operators';
import { OrderPipe } from 'ngx-order-pipe';
import { PaginatedComponent } from '../paginated/paginated.component';
import { CurrencyFormatPipe } from '../_pipes/currency-format.pipe';

@Component({
  selector: 'app-leverancier',
  templateUrl: './leverancier.component.html'
})
export class LeverancierComponent extends PaginatedComponent implements OnInit {
  leveranciers: any = [];
  currentUser;

  orderByReversed = {
    name: false
  }

  studentenLeveranciers = [
    { leverancier: new Leverancier('Scarabee'), created: false },
    { leverancier: new Leverancier('Prik & Tik'), created: false },
    { leverancier: new Leverancier('Pak & Zak'), created: false },
    { leverancier: new Leverancier('Lidl '), created: false }
  ]

  constructor(private leverancierService: LeverancierService,
              orderPipe: OrderPipe,
              private router: Router,
              private authenticationService: AuthenticationService,
              paginationService: PaginationService,
              currencyFormatPipe: CurrencyFormatPipe) {
                super(paginationService, 'leverancier', currencyFormatPipe, orderPipe)
                this.currentUser = JSON.parse(localStorage.getItem('currentUser'));

  }

  loadItems() {
    return this.leverancierService.getAllLeveranciers(this.currentUser);
  }

  createLeverancier() {
    this.router.navigate(['/leverancier/create']);
  }

  editLeverancier(leverancier: Leverancier) {
    this.router.navigate(['/leverancier/edit/', leverancier._id])
  }

  deleteLeverancier(leverancier: Leverancier) {
    this.leverancierService.removeLeverancier(leverancier._id.toString())
    .pipe(take(1))
    .subscribe(
      (response: Leverancier[]) => {
        if(response)
          this.leveranciers = response;
          this.refresh$.next(true);
      });
  }

  generateStudentenLeveranciers() {
    this.studentenLeveranciers.forEach(lev => {
        this.leverancierService.addLeverancier(lev.leverancier).pipe(take(1)).subscribe((leveranciers: any) => {
        lev.created = true;
        this.addIds(leveranciers);
        this.refresh$.next(true);
      })
    })
  }

  studentenLeveranciersGenerated() {
    console.log(this.paginatedItemsOriginal.find(item => {
      item.name === this.studentenLeveranciers[0].leverancier.name
    }))
    return this.paginatedItemsOriginal.find(item => item.name === this.studentenLeveranciers[0].leverancier.name)
  }

  get expired() {
    return this.authenticationService.accountExpired;
  }
}

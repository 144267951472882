import { OrderPipe } from 'ngx-order-pipe';
import { CurrencyFormatPipe } from './../../_pipes/currency-format.pipe';
import { AuthenticationService } from './../../_services/authentication.service';
import { UitgCreditNota } from './../../../models/Creditnota';
import { PaginationService } from './../../_services/pagination/pagination.service';
import { KlantService } from './../../klant/klant.service';
import { Klant } from './../../../models/Klant';
import { PaginatedComponent } from '../../paginated/paginated.component';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { UitgCreditnotaService } from './uitg-creditnota.service';
import { take } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import * as _ from 'lodash';


@Component({
  selector: 'app-uitg-creditnota',
  templateUrl: './uitg-creditnota.component.html'
})
export class UitgCreditnotaComponent extends PaginatedComponent implements OnInit {

  klanten: Klant[];

  expired = false;

  orderByReversed = {
    datum: false,
    klant: false
  }

  constructor(private authService: AuthenticationService,
              private uitgCreditNotasService: UitgCreditnotaService,
              private klantenService: KlantService,
              protected router: Router,
              protected paginationService: PaginationService,
              currencyFormatPipe: CurrencyFormatPipe,
              orderPipe: OrderPipe) {
                super(paginationService, 'uitgCreditNotas', currencyFormatPipe, orderPipe);
                this.expired = this.authService.accountExpired;
              }

  ngOnInit() {
    this.klantenService.getAllKlanten(this.currentUser)
    .pipe(take(1))
    .subscribe((klanten: Klant[]) => this.klanten = klanten);
  }

  loadItems() {
    return this.isLeerkracht() ? this.uitgCreditNotasService.getStudentenUitgCreditNotas(this.currentUser) :
    (this.currentUser.role === 'Boekhouder' ? this.uitgCreditNotasService.getKlantenUitgCreditNotas(this.currentUser) :
    this.uitgCreditNotasService.getAllUitgCreditNotas(this.currentUser));
  }

  createUitgCreditNota() {
    this.router.navigate(['/uitgaande-creditnotas/create']);
  }

  editUitgCreditNota(creditNota){
    this.router.navigate(['/uitgaande-creditnotas/edit/', creditNota._id]);
  }

  deleteUitgCreditNota(creditNota: UitgCreditNota) {
    if(confirm('Bent u zeker dat u deze uitgaande creditnota wil verwijderen?')) {
    this.uitgCreditNotasService.removeUitgCreditNota(creditNota._id.toString())
    .pipe(take(1))
    .subscribe(
      response => {
        this.refresh$.next(true);
      });
    }
  }
}

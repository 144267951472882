<div class="wrapper">
<app-header></app-header>
<div class="container-fluid">
<alert></alert>
</div>
<router-outlet></router-outlet>
</div>
<footer class="footer">
<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="col-12 col-md-6 col-xl-5 d-inline-block text-muted">&copy; Applicatie ontwikkeld door <a href="https://www.linkedin.com/in/nicholaslievens/" target="_blank">Nicholas Lievens</a></div>
      <div class="col-12 col-md-12 col-xl-5 d-inline-block">Technische ondersteuning: <a href="mailto:lievens.nicholas@gmail.com">lievens.nicholas@gmail.com</a></div>
    </div>
  </div>
</div>
</footer>
<ng-http-loader
[debounceDelay]="100"
[spinner]="spinkit.skThreeBounce">
</ng-http-loader>

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { Aankoop } from '../../../models/Aankoop';
import { map } from 'rxjs/operators';

@Injectable()
export class AankoopService {


  constructor(private http: HttpClient) {

  }

  getAllAankopen(currentUser) {
    let headers = new HttpHeaders;
    headers.append('Cache-Control', 'no-cache');
    headers.append('Pragma', 'no-cache');
    headers.append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT');
    return this.http.get('/api/aankopen/' + currentUser._id, {headers: headers})
      .pipe(map(res => res));
  }

  addAankoop(aankoop: Aankoop) {
    let URI = '/api/aankoop/';
    let headers = new HttpHeaders;
    let body = {
      datum: aankoop.datum,
      leverancier: aankoop.leverancier,
      type: aankoop.type,
      bedragExclBTW: aankoop.bedragExclBTW,
      BTW: aankoop.BTW,
      bedragInclBTW: aankoop.bedragInclBTW,
      omschrijving: aankoop.omschrijving,
      bijlage: aankoop.bijlage,
      status: aankoop.status,
      intracommunautair: aankoop.intracommunautair,
      icType: aankoop.icType
    };
    headers.append('Content-Type', 'application/json');
    return this.http.post(URI, body, {headers: headers})
    .pipe(map(res => res));
  }

  updateAankoop(aankoop: Aankoop){
    let URI = `/api/aankoop/${aankoop._id}`;
    let headers = new HttpHeaders;
    let body = {
      datum: aankoop.datum,
      leverancier: aankoop.leverancier,
      type: aankoop.type,
      bedragExclBTW: aankoop.bedragExclBTW,
      BTW: aankoop.BTW,
      bedragInclBTW: aankoop.bedragInclBTW,
      omschrijving: aankoop.omschrijving,
      bijlage: aankoop.bijlage,
      status: aankoop.status,
      intracommunautair: aankoop.intracommunautair,
      icType: aankoop.icType
    }
    headers.append('Content-Type', 'application/json');
    return this.http.put(URI, body, {headers})
    .pipe(map(res => res));
  }

  getStudentenAankopen(currentUser) {
    let headers = new HttpHeaders;
    headers.append('Cache-Control', 'no-cache');
    headers.append('Pragma', 'no-cache');
    headers.append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT');
    return this.http.get('/api/aankopen/studenten/' + currentUser.leerkrachtCode, {headers: headers})
      .pipe(map(res => res));
  }

  getKlantenAankopen(currentUser) {
    let headers = new HttpHeaders;
    headers.append('Cache-Control', 'no-cache');
    headers.append('Pragma', 'no-cache');
    headers.append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT');
    return this.http.get('/api/aankopen/klanten/' + currentUser.boekhouderCode, {headers: headers})
      .pipe(map(res => res));
  }

  getAankoop(aankoopId: string){
    let headers = new HttpHeaders;
    headers.append('Cache-Control', 'no-cache');
    headers.append('Pragma', 'no-cache');
    headers.append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT');
    return this.http.get('/api/aankoop/' + aankoopId, {headers: headers}).pipe(map((response: Response) => response));
  }

  removeAankoop(aankoopId: string) {
    let URI = `/api/aankoop/${aankoopId}`;
    let headers = new HttpHeaders;
    headers.append('Cache-Control', 'no-cache')
    headers.append('Pragma', 'no-cache')
    headers.append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
    headers.append('Content-Type', 'application/json');
    return this.http.delete(URI, {headers: headers})
    .pipe(map(res => res));
  }

  uploadAttachment(file) {
    console.log('upload attachment')
    let URI = `/api/upload`;
    let headers = new HttpHeaders;
    headers.append('Cache-Control', 'no-cache')
    headers.append('Pragma', 'no-cache')
    headers.append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
    console.log(file)
    return this.http.post(URI, file, {headers: headers})
    .pipe(map(res => res))
  }

  deleteAttachment(attachment) {
    let URI = '/api/remove';
    return this.http.post(URI, attachment)
  }
}

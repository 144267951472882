<div class="container mt-5">
  <h1>Leverancier</h1>

  <form (ngSubmit)="onSubmit()" style="margin-top:50px;" #leverancierForm="ngForm">
  <div class="form-group row">
    <label for="type" class="col-12 col-md-3 col-form-label">Type/Nummer</label>
    <div class="col-12 col-md-4">
      <input class="form-control" type="text" [(ngModel)]="leverancier.type" name="type" >
    </div>
  </div>

  <div class="form-group row">
    <label for="name" class="col-12 col-md-3 col-form-label">Naam
      <span *ngIf="!leverancier.name"><strong>*</strong></span>
    </label>
    <div class="col-12 col-md-4">
      <input class="form-control" type="text" [(ngModel)]="leverancier.name" name="name" required>
    </div>
  </div>

  <div class="form-group row">
    <label for="btwNr" class="col-12 col-md-3 col-form-label">BTW Nummer</label>
    <div class="col-12 col-md-4">
      <input class="form-control d-inline-block" appBTW type="text" [(ngModel)]="leverancier.btwNr" name="btwNr" #inputBtw="ngModel">
    </div>
    <div class="col-12 col-md-5 col-form-label">
      <span class="error" *ngIf="inputBtw.invalid">Btw nummer incorrect <i class="fa fa-info-circle" ngbTooltip="Een Btw nummer bestaat uit een landcode en nummer, bijvoorbeeld: BE 0000 000 000"></i></span>
    </div>
  </div>


  <div class="form-group row">
    <label for="contactpersoon" class="col-12 col-md-3 col-form-label">Contactpersoon</label>
    <div class="col-12 col-md-4">
      <input class="form-control" type="text" [(ngModel)]="leverancier.contactpersoon" name="contactpersoon" >
    </div>
  </div>

  <div class="form-group row">
    <label for="email" class="col-12 col-md-3 col-form-label">Email</label>
    <div class="col-12 col-md-4">
      <input class="form-control" type="text" [(ngModel)]="leverancier.email"
      name="email" >
    </div>
  </div>

  <div class="form-group row">
    <label for="leverancierSubscr" class="col-12 col-md-3 col-form-label">Leverancier sinds</label>
    <div class="col-12 col-md-4">
    <div class="input-group">
    <input class="form-control" placeholder="dd/mm/jjjj" [(ngModel)]="leverancier.leverancierSubscr" name="leverancierSubscr"
    ngbDatepicker #d="ngbDatepicker">
    <div class="input-group-append">
      <button class="btn btn-secondary" (click)="d.toggle()" type="button">
        <span class="fa fa-calendar"></span>
      <!-- <img src="img/calendar-icon.svg" style="width: 1.2rem; height: 1rem; cursor: pointer;"/> -->
      </button>
    </div>
   </div>
    </div>
  </div>

  <div class="form-group row">
    <label for="land" class="col-12 col-md-3 col-form-label">Land</label>
    <div class="col-12 col-md-4">
      <input class="form-control" type="text" [(ngModel)]="leverancier.land" name="land" >
    </div>
  </div>

  <div class="form-group row">
    <label for="adresStraat" class="col-12 col-md-3">Straat + Nr</label>
    <div class="col-12 col-md-4">
      <input class="form-control" type="text" [(ngModel)]="leverancier.adresStraat" name="adresStraat" >
    </div>
  </div>

  <div class="form-group row">
    <label for="postcode" class="col-12 col-md-3 col-form-label">Postcode</label>
    <div class="col-12 col-md-4">
      <input class="form-control" type="text" [(ngModel)]="leverancier.postcode" name="postcode" >
    </div>
  </div>

  <div class="form-group row">
    <label for="adresGemeente" class="col-12 col-md-3 col-form-label">Gemeente</label>
    <div class="col-12 col-md-4">
      <input class="form-control" type="text" [(ngModel)]="leverancier.adresGemeente" name="adresGemeente" >
    </div>
  </div>


  <div class="form-group row">
    <label for="telnr" class="col-12 col-md-3 col-form-label">Telefoon</label>
    <div class="col-12 col-md-4">
      <input class="form-control" type="text" [(ngModel)]="leverancier.telnr" name="telnr" >
    </div>
  </div>

  <div class="form-group row">
    <label for="iban" class="col-12 col-md-3 col-form-label">IBAN</label>
    <div class="col-12 col-md-4">
      <input class="form-control" type="text" [(ngModel)]="leverancier.iban" name="iban" appIBAN #inputIBAN="ngModel">
    </div>
    <div class="col-12 col-md-5 col-form-label">
      <span class="error" *ngIf="inputIBAN.errors">IBAN is niet correct</span>
    </div>
  </div>

  <div class="form-group row">
    <label for="bic" class="col-12 col-md-3 col-form-label">BIC</label>
    <div class="col-12 col-md-4">
      <input class="form-control" type="text" [(ngModel)]="leverancier.bic" name="bic" appBIC #inputBIC="ngModel">
    </div>
    <div class="col-12 col-md-5">
      <span class="error" *ngIf="inputBIC.errors">BIC is niet correct</span>
    </div>
  </div>

  <div class="form-group row">
    <label for="rpr" class="col-12 col-md-3 col-form-label">RPR</label>
    <div class="col-12 col-md-4">
      <input class="form-control" type="text" [(ngModel)]="leverancier.rpr" name="rpr">
    </div>
  </div>

  <div class="error">
    * Verplicht in te vullen
  </div>

  <button type="button" class="btn btn-light mr-2" (click)="navigateAway()">Annuleren</button>
  <button type="submit" *ngIf="!isEdit" class="btn btn-primary" [disabled]="!leverancierForm.valid">Toevoegen</button>
  <button type="submit" *ngIf="isEdit" class="btn btn-primary" [disabled]="!leverancierForm.valid">Opslaan</button>
</form>
</div>

<div class="modal-header">
  <h4 class="modal-title">Transacties {{productNr}} - {{productNaam}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <table class="table table-striped">
    <thead>
    <th>Datum</th>
    <th>Verschil</th>
    <th>Voorlopig aantal</th>
    <th>Oorsprong</th>
    <th>Opmerking</th>
  </thead>
  <tbody>
    <tr *ngFor="let beweging of voorraadBewegingenReversed; let i = index;">
      <td>{{beweging.bewegingDatum | date}}</td>
      <td>{{beweging.verschil}}</td>
      <td>{{getVoorlopig(i)}}</td>
      <td>{{beweging.oorsprong}}</td>
      <td>{{beweging.opmerking}}</td>
    </tr>
  </tbody>
  </table>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Sluiten</button>
</div>

import { OrderPipe, OrderModule } from 'ngx-order-pipe';
import { CurrencyFormatPipe } from './../_pipes/currency-format.pipe';
import { SeparatorPipe } from './../_pipes/separator.pipe';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableFilterMenuComponent } from './table-filter-menu/table-filter-menu.component';
import { PaginationComponent } from './pagination/pagination.component';
import { StudentBedrijfSelectorComponent } from './student-bedrijf-selector/student-bedrijf-selector.component';

const PIPES = [
  SeparatorPipe,
  CurrencyFormatPipe
]

@NgModule({
  declarations: [
    TableFilterMenuComponent,
    PaginationComponent,
    ...PIPES,
    StudentBedrijfSelectorComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    OrderModule
  ],
  exports: [
    TableFilterMenuComponent,
    PaginationComponent,
    StudentBedrijfSelectorComponent,
    FormsModule,
    NgbModule,
    OrderModule,
    ...PIPES
  ],
  providers: [
    ...PIPES
  ]
})
export class SharedModule { }

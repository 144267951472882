import { AuthenticationService } from './../_services/authentication.service';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { KlantService } from './klant.service';
import { Klant } from '../../models/Klant';
import { Router } from '@angular/router'
import { OrderPipe } from 'ngx-order-pipe';
import { PaginatedComponent } from '../paginated/paginated.component';
import { PaginationService } from '../_services/pagination/pagination.service';
import { CurrencyFormatPipe } from '../_pipes/currency-format.pipe';

@Component({
  selector: 'app-klant',
  templateUrl: './klant.component.html'
})
export class KlantComponent extends PaginatedComponent implements OnInit {
  klanten: any = [];
  currentUser;

  orderByReversed = {
    name: false
  }

  constructor(paginationService: PaginationService,
              currencyFormatPipe: CurrencyFormatPipe,
              orderPipe: OrderPipe,
              private klantService: KlantService,
              private router: Router,
              private authenticationService: AuthenticationService) {
                super(paginationService, 'klant', currencyFormatPipe, orderPipe)
                this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
               }

  loadItems() {
    return this.klantService.getAllKlanten(this.currentUser)
  }

  createKlant(){
    this.router.navigate(['/klant/create'])
  }

  editKlant(klant: Klant){
    this.router.navigate(['/klant/edit/', klant._id])
  }

  deleteKlant(klant: Klant) {
    this.klantService.removeKlant(klant._id.toString()).subscribe(
      (response: Klant[]) => {
        if(response)
          this.klanten = response;
          this.refresh$.next(true);
      });
  }

  get expired() {
    return this.authenticationService.accountExpired;
  }
}

<!-- <div class="container" *ngIf="!enabled">
  <div class="alert alert-warning mt-4">Deze feature komt binnenkort beschikbaar.</div>
</div> -->
<div class="container">
  <h1 class="mb-4 mt-4">Klantenlisting btw</h1>

  <app-table-filter-menu [showKwartaalFilter]="false"></app-table-filter-menu>

  <div class="row mt-4">
    <div class="col-12">
      <ng-container>
        <div class="table-responsive">
          <table class="table table-sm table-striped">
            <thead>
              <tr>
                <th>Klant</th>
                <th style="min-width:150px;" class="text-right">Omzet (excl. btw)</th>
                <th style="min-width:175px;" class="text-right">Aangerekende btw</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let listKlant of klantenIds">
              <tr *ngIf="listKlant.klant.btwNr && listKlant.list">
                <ng-container>
                  <td>{{listKlant.klant.name}} ({{listKlant.klant.btwNr}})</td>
                  <td class="text-right">{{listKlant.totaal | currencyFormat}}</td>
                  <td class="text-right">{{listKlant.btwTotaal | currencyFormat}}</td>
                </ng-container>
              </tr>
            </ng-container>
            </tbody>
            <tfoot>
              <tr style="background-color: #343434; color: white;">
                <td>Totaal</td>
                <td class="text-right">{{totaalOmzet | currencyFormat}}</td>
                <td class="text-right">{{totaalBTW | currencyFormat}}</td>
              </tr>
            </tfoot>
          </table>
      </div>
      </ng-container>
    </div>
  </div>
</div>

import { NumberDirective } from './_validators/number.validator';
import { BICValidatorDirective } from './_validators/bic.validator';
import { IBANValidatorDirective } from './_validators/iban.validator';
import { BtwValidatorDirective } from './_validators/btw.validator';
import { ProductenService } from './inventaris/producten.service';
import { DateValidatorDirective } from './_validators/date.validator';
import { VerkoopComponent } from './_boeken/verkoop/verkoop.component';
import { SharedModule } from './shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './header/header.component';
import { AlertComponent } from './directives/alert/alert.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { AlertService } from './_services/alert.service';
import { AuthenticationService } from './_services/authentication.service';
import { UserService } from './_services/user.service';
import { AuthGuard } from './guards/auth.guard';
import { routing } from './app.routing';
import { KlantComponent } from './klant/klant.component';
import { LeverancierComponent } from './leverancier/leverancier.component';
import { KlantService } from './klant/klant.service';
import { KlantEditComponent } from './klant/klant-edit/klant-edit.component';
import { LeverancierEditComponent } from './leverancier/leverancier-edit/leverancier-edit.component';
import { LeverancierService } from './leverancier/leverancier.service';

import { Angulartics2Module } from 'angulartics2';
import { OffertesService } from './offertes/offertes.service';
import { FacturenService } from './facturen/facturen.service';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BtwOverviewComponent } from './btw-overview/btw-overview.component';
import { InventarisComponent } from './inventaris/inventaris.component';
import { AccountComponent } from './account/account.component';
import { PaginationService } from './_services/pagination/pagination.service';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './helpers/jwt.interceptor';

import { registerLocaleData } from '@angular/common';
import localeNlBE from '@angular/common/locales/nl-BE';
import localeNlBEExtra from '@angular/common/locales/extra/nl-BE';
import { VerkoopEditComponent } from './_boeken/verkoop/verkoop-edit/verkoop-edit.component';
import { FinancieelComponent } from './_boeken/financieel/financieel.component';
import { FinancieelEditComponent } from './_boeken/financieel/financieel-edit/financieel-edit.component';
import { UitgCreditnotaComponent } from './_boeken/uitg-creditnota/uitg-creditnota.component';
import { OntvCreditnotaComponent } from './_boeken/ontv-creditnota/ontv-creditnota.component';
import { OntvCreditnotaEditComponent } from './_boeken/ontv-creditnota/ontv-creditnota-edit/ontv-creditnota-edit.component';
import { UitgCreditnotaEditComponent } from './_boeken/uitg-creditnota/uitg-creditnota-edit/uitg-creditnota-edit.component';
import { VerkoopService } from './_boeken/verkoop/verkoop.service';
import { FinancieelService } from './_boeken/financieel/financieel.service';
import { OntvCreditnotaService } from './_boeken/ontv-creditnota/ontv-creditnota.service';
import { UitgCreditnotaService } from './_boeken/uitg-creditnota/uitg-creditnota.service';
import { AankoopComponent } from './_boeken/aankoop/aankoop.component';
import { AankoopEditComponent } from './_boeken/aankoop/aankoop-edit/aankoop-edit.component';
import { AankoopService } from './_boeken/aankoop/aankoop.service';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { VoorraadbewegingModalComponent } from './inventaris/voorraadbeweging-modal/voorraadbeweging-modal.component';
import { KlantenlistingComponent } from './klantenlisting/klantenlisting.component';
import { IcListingComponent } from './ic-listing/ic-listing.component';

registerLocaleData(localeNlBE, 'nl-BE', localeNlBEExtra);

@NgModule({
  declarations: [
    AppComponent,
    VerkoopComponent,
    HeaderComponent,
    VerkoopEditComponent,
    AankoopComponent,
    AankoopEditComponent,
    AlertComponent,
    LoginComponent,
    RegisterComponent,
    HomeComponent,
    KlantComponent,
    LeverancierComponent,
    KlantEditComponent,
    LeverancierEditComponent,
    FinancieelComponent,
    FinancieelEditComponent,
    BtwOverviewComponent,
    UitgCreditnotaComponent,
    OntvCreditnotaComponent,
    OntvCreditnotaEditComponent,
    UitgCreditnotaEditComponent,
    InventarisComponent,
    AccountComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    DateValidatorDirective,
    BtwValidatorDirective,
    IBANValidatorDirective,
    BICValidatorDirective,
    NumberDirective,
    VoorraadbewegingModalComponent,
    KlantenlistingComponent,
    IcListingComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    routing,
    Angulartics2Module.forRoot(),
    NgbModule,
    SharedModule,
    NgxChartsModule,
    NgHttpLoaderModule.forRoot(),
    ReactiveFormsModule
  ],
  providers: [
    VerkoopService,
    AankoopService,
    AuthGuard,
    AlertService,
    AuthenticationService,
    UserService,
    KlantService,
    LeverancierService,
    FinancieelService,
    OffertesService,
    FacturenService,
    OntvCreditnotaService,
    UitgCreditnotaService,
    ProductenService,
    PaginationService,
    {provide: 'DEFAULT_ENTITY', useValue: 'unknown'},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
  },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }

<div class="container-fluid mt-5">
  <h1>Verkopen</h1>
  <div class="row">
    <div class="col-12">
      <div class="alert alert-danger" *ngIf="expired">Uw account is verstreken. Ga naar de <a href="account">account</a> pagina om een betaling uit te voeren en het account opnieuw te activeren.</div>
    </div>
  </div>
  <div class="row mt-4">
    <app-table-filter-menu #tableMenuFilter [itemsOriginal]="paginatedItemsOriginal" class="col-sm-8"></app-table-filter-menu>
    <div class="col-sm-4">
      <button class="btn btn-primary float-right" *ngIf="!expired" [disabled]="klanten?.length === 0" (click)="createVerkoop()">Nieuwe Verkoop</button>
    </div>
  </div>
<div class="row">
  <div class="col-12">
    <div class="table-responsive">
  <table class="table table-sm table-striped mt-4">
    <thead>
      <tr>
        <th style="min-width:40px;">
          <span class="fa fa-sort" (click)="setOrderBy('id')"></span> #
        </th>
        <th style="width:10%;">
            <span class="fa fa-sort" (click)="setOrderBy('datum')"></span> Datum
        </th>
        <th style="min-width:150px;" (click)="setOrderBy('klant.name')">
            <span class="fa fa-sort"></span> Klant
        </th>
        <th style="min-width:150px;" class="text-right">Bedrag excl. Btw</th>
        <th style="min-width:150px; cursor:pointer;" (click)="btwValue = !btwValue" class="text-right">Btw
          <span class="fa fa-percent" *ngIf="btwValue"></span>
          <span class="fa fa-euro" *ngIf="!btwValue"></span>
        </th>
        <th style="min-width:150px;" class="text-right">Bedrag incl. Btw</th>
        <th style="width:10%;" class="pl-4">Omschrijving</th>
        <th style="min-width:100px;">
            <span class="fa fa-sort" (click)="setOrderBy('factuur.factuurNummer')"></span> Factuur
        </th>
        <th style="width:5%;"><span class="fa fa-sort" (click)="setOrderBy('status')"></span> Betaald</th>
        <th style="width:5%;" colspan="2">Acties</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let verkoop of paginatedRange; let i = index">
      <tr>
        <td>{{verkoop.id}}</td>
        <td>{{verkoop.datum | date:'dd/MM/yyyy'}}</td>
        <td>{{verkoop.klant.name}}</td>
        <td class="text-right">{{verkoop.bedragExclBTW | currencyFormat}}</td>
        <td class="text-right" *ngIf="btwValue">{{verkoop.BTW === -1 ? 'n.v.t' : verkoop.BTW + '%'}}</td>
        <td class="text-right" *ngIf="!btwValue">{{getBtwBedrag(verkoop) | currencyFormat}}</td>
        <td class="text-right">{{verkoop.bedragInclBTW | currencyFormat}}</td>
        <td class="pl-4">{{verkoop.omschrijving}}</td>
        <td>
          <span class="badge badge-primary" [routerLink]="['/facturen/edit/' + verkoop.factuur?._id]" style="cursor:pointer;">{{verkoop.factuur?.factuurNummer}}</span>
        </td>
        <td>
          <span class="badge badge-danger" *ngIf="!verkoop.status">NIET BETAALD</span>
          <span class="badge badge-success" *ngIf="verkoop.status">BETAALD</span>
        </td>
        <td>
          <div class="btn-group btn-group-sm pull-right" role="group">
            <button class="btn btn-secondary btn-light" (click)="deleteVerkoop(verkoop)">
              <span class="fa fa-trash"></span>
            </button>
            <button class="btn btn-secondary btn-light" (click)="editVerkoop(verkoop)">
              <span class="fa fa-pencil"></span>
            </button>
          </div>
        </td>
      </tr>
    </ng-container>
      <tr style="background-color:#343434; color:white;">
        <td colspan="3">
          Totaal {{selectedYear}}:
        </td>
        <td class="text-right" colspan="1">{{paginatedItemsExclBtw | currencyFormat}}</td>
        <td class="text-right">{{getTotaalBtw() | currencyFormat}}</td>
        <td class="text-right" colspan="1">{{paginatedItemsInclBtw | currencyFormat}}</td>
        <td colspan="4"></td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <td colspan="10">
        </td>
      </tr>
    </tfoot>
  </table>
</div>
</div>
</div>
<app-pagination #pagination
  [(nrOfItems)]="numberOfItems"
  [(paginationMin)]="paginationMin"
  [(paginationMax)]="paginationMax"
  [totalItems]="paginatedItems.length">
</app-pagination>
<button class="btn btn-primary" type="button" (click)="downloadFile(paginatedItems,'verkopen','verkopen')">Export CSV</button>
</div>

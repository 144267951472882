import { User } from './user';

export class ProductGroep {
  _id?: string;
  groepNummer: string;
  naam: string;
  producten: Product[] = [];
  user: User;
}

export class ProductInfo {
  _id?: string;
  jaar: number;
  beginVoorraad: number;
  aantal: number;
  eindVoorraad: number;
  btwPercentage;
  prijs: number;
  verkoopPrijs: number;

  constructor(productInfoJaar) {
    this.jaar = productInfoJaar;
  }
}

export class Product {
  _id?: string;
  artikelNummer: string;
  naam: string;
  productInfo: ProductInfo[] = [];

  constructor(productInfoJaar) {
    if (this.productInfo.length === 0) {
      this.productInfo.push(new ProductInfo(productInfoJaar));
    }
  }
}

export class Inventaris {
  jaar: number;
  productGroepen = [];
  user: User;
}

export class VoorraadBeweging {
  bewegingDatum: Date;
  verschil: number;
  oorsprong: string;
  opmerking: string;
  productInfoId: string;
}

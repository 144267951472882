<div class="container-fluid mt-5">
  <h1>Leveranciers</h1>
  <div class="alert alert-info" *ngIf="!studentenLeveranciersGenerated()">Als student kan je enkele leveranciers genereren. <button type="button" class="btn btn-primary" (click)="generateStudentenLeveranciers()">Genereer</button></div>
  <button class="btn btn-primary mt-4 mb-3 pull-right" (click)="createLeverancier()" *ngIf="!expired">Nieuwe Leverancier</button>
<div class="table-responsive">
  <table class="table table-sm table-striped" style="margin-top:50px;">
<thead>
  <tr>
    <th style="min-width:40px;">
      <span class="fa fa-sort" (click)="setOrderBy('id')"></span> #
    </th>
    <th style="min-width:150px;"><span class="fa fa-sort sort" (click)="setOrderBy('type')"></span> Type/Nummer</th>
    <th style="min-width:150px;">
      <span class="fa fa-sort" (click)="setOrderBy('name')"></span> Leverancier
    </th>
    <th style="min-width:150px;">BTW Nummer</th>
    <th style="min-width:200px;">IBAN</th>
    <th>Telefoon</th>
    <th style="min-width:200px;">Adres</th>
    <th>Acties</th>
  </tr>
</thead>
<tbody>
  <tr *ngFor = "let leverancier of paginatedRange;">
    <td>{{leverancier.id}}</td>
    <td>{{leverancier.type}}</td>
    <td>{{leverancier.name}}</td>
    <td>{{leverancier.btwNr}}</td>
    <td>{{leverancier.iban}}</td>
    <td>{{leverancier.telnr}}</td>
    <td>
      {{leverancier.adresStraat}} {{leverancier.adresGemeente}}
    </td>
    <td>
      <div class="btn-group btn-group-sm pull-right" role="group">
      <!-- <button class="btn btn-secondary btn-light" (click)="deleteLeverancier(leverancier)" [disabled]=true data-toggle="tooltip" title="Functie niet beschikbaar"><span class="fa fa-trash"></span></button> -->
      <button class="btn btn-secondary btn-light" (click)="editLeverancier(leverancier)"><span class="fa fa-pencil"></span></button>
      </div>
    </td>
  </tr>
</tbody>
<tfoot>
    <tr>
      <td colspan="10">
      </td>
    </tr>
  </tfoot>
</table>
</div>
<app-pagination #pagination
  [(nrOfItems)]="numberOfItems"
  [(paginationMin)]="paginationMin"
  [(paginationMax)]="paginationMax"
  [totalItems]="paginatedItems.length">
</app-pagination>
</div>

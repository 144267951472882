import { AuthenticationService } from './../_services/authentication.service';
import { CurrencyFormatPipe } from './../_pipes/currency-format.pipe';
import { AankoopService } from './../_boeken/aankoop/aankoop.service';
import { VerkoopService } from './../_boeken/verkoop/verkoop.service';
import { Component, OnInit } from '@angular/core';

import { User } from '../../models/user';
import { UserService } from '../_services/user.service';
import { take } from 'rxjs/operators';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';

@Component({
   templateUrl: 'home.component.html'
})

export class HomeComponent implements OnInit {
   currentUser: User;
   users: User[] = [];
   verkopen;
   aankopen;
   totaalVerkopen;
   totaalAankopen

   multi: any[];
   view: any[] = [700, 300];

   // options
   legend = true;
   showLabels = true;
   animations = true;
   xAxis = true;
   yAxis = true;
   showYAxisLabel = false;
   showXAxisLabel = true;
   xAxisLabel = 'Jaar';
   yAxisLabel = 'Totaal Verkopen';
   yAxisLabelAankopen = 'Totaal Aankopen';
   timeline = true;

   colorScheme = {
     domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
   };

   years = [2017, 2018, 2019, 2020,2021,2022,2023,2024,2025];
   currYear = new Date().getFullYear();

  somVerkopen = [];
  somAankopen = [];

  graphVerkopen = [];

  expired;

   constructor(private userService: UserService,
               private authService: AuthenticationService,
               private verkoopService: VerkoopService,
               private aankoopService: AankoopService,
               private currencyFormatPipe: CurrencyFormatPipe) {
       this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
       this.totaalVerkopen = 0;
       this.totaalAankopen = 0;
    }

   ngOnInit() {
      console.log('account expired', this.authService.accountExpired)
       this.expired = this.authService.accountExpired;
       this.loadResources();
   }



   loadResources() {
    forkJoin([this.verkoopService.getAllVerkopen(this.currentUser), this.aankoopService.getAllAankopen(this.currentUser)])
      .pipe(take(1))
      .subscribe(result => {
        const [verkopen, aankopen] = result;
        this.totaalVerkopen = this.calculateTotaal(new Date().getFullYear(), verkopen);
        this.totaalAankopen = this.calculateTotaal(new Date().getFullYear(), aankopen);
        this.initGraphsPerYear(verkopen);
        this.initGraphsPerYear(aankopen);
        this.somVerkopen = [...this.initGraphsPerYear(verkopen)];
        this.somAankopen = [...this.initGraphsPerYear(aankopen)];
    });
   }

   initGraphsPerYear(values) {
    const somValues = [];
    this.years.forEach(year => somValues.push(
      {
        'name': '' + year,
        'label': '' + year,
        'value': this.calculateTotaal(year + 1, values)
     }));
     return somValues;
   }


   calculateTotaal(nextYear, values) {
       let totaal = 0;

       values.filter( verkoop => {
        const endDatum = new Date('' + nextYear + '/01/01');
        const verkoopDatum = new Date(verkoop.datum);
        const compareDatum = new Date('' + (nextYear - 1) + '/01/01');
        return verkoopDatum >= compareDatum && verkoopDatum < endDatum;
      }).forEach(value => totaal += value.bedragExclBTW);

      return totaal;
   }

   removeStudent(student) {
       let r = confirm('Weet u zeker dat u ' + student.firstName + ' ' + student.lastName + ' wil verwijderen?')
       if(r === true) {
        const _leerkracht = Object.assign(this.currentUser, {removeStudent: student});
        console.log(_leerkracht);
        this.userService.update(_leerkracht)
        .pipe(take(1))
        .subscribe((result: User) => {
          const index = this.currentUser.studenten.indexOf(student);
          this.currentUser.studenten.splice(index, 1);
        });
       }
   }

  yToEuro = (val) => {
    return this.currencyFormatPipe.transform(val);
  }
}

<div class="container-fluid mt-5">
  <h1>Uitgaande Creditnota's</h1>
  <div class="row">
    <div class="col-12">
      <div class="alert alert-danger" *ngIf="expired">Uw account is verstreken. Ga naar de <a href="account">account</a> pagina om een betaling uit te voeren en het account opnieuw te activeren.</div>
    </div>
  </div>
  <div class="row mt-4">
    <app-table-filter-menu #tableMenuFilter class="col-sm-8"></app-table-filter-menu>

    <div class="col-sm-4">
      <button class="btn btn-primary pull-right" *ngIf="!expired" [disabled]="klanten?.length === 0" (click)="createUitgCreditNota()">Nieuwe Uitg. Creditnota</button>
    </div>
  </div>
<div class="row">
  <div class="col-12">
    <div class="table-responsive">
  <table class="table table-sm table-striped mt-4">
    <thead>
      <tr>
        <th>
            #
        </th>
        <th (click)="sortBy('datum')">
            <span class="fa fa-sort" (click)="setOrderBy('datum')"></span> Datum
        </th>
        <th style="min-width:150px;" (click)="setOrderBy('klant.name')">
            <span class="fa fa-sort"></span> Klant
        </th>
        <th style="min-width:150px;" class="text-right">Bedrag excl. Btw</th>
        <th style="min-width:150px; cursor:pointer;" class="text-right" (click)="btwValue = !btwValue">Btw
            <span class="fa fa-percent" *ngIf="btwValue"></span>
            <span class="fa fa-euro" *ngIf="!btwValue"></span>
          </th>
        <th style="min-width:150px;" class="text-right">Bedrag incl. Btw</th>
        <th class="pl-4">Omschrijving</th>
        <th colspan="2">Acties</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let uitgCreditNota of paginatedRange; let i = index">
        <td>{{i+1}}</td>
        <td>{{uitgCreditNota.datum | date:'dd/MM/yyyy'}}</td>
        <td>{{uitgCreditNota.klant.name}}</td>
        <td class="text-right">{{uitgCreditNota.bedragExclBTW | currencyFormat}}</td>
        <td class="text-right" *ngIf="btwValue">{{uitgCreditNota.BTW === -1 ? 'n.v.t' : uitgCreditNota.BTW + '%'}}</td>
        <td class="text-right" *ngIf="!btwValue">{{(uitgCreditNota.bedragInclBTW - uitgCreditNota.bedragExclBTW) | currencyFormat}}</td>
        <td class="text-right">{{uitgCreditNota.bedragInclBTW | currencyFormat}}</td>
        <td class="pl-4">{{uitgCreditNota.omschrijving}}</td>
          <td>
            <div class="btn-group btn-group-sm pull-right" role="group">
              <button class="btn btn-secondary btn-light" (click)="deleteUitgCreditNota(uitgCreditNota)">
                <span class="fa fa-trash"></span>
              </button>
              <button class="btn btn-secondary btn-light" (click)="editUitgCreditNota(uitgCreditNota)">
                <span class="fa fa-pencil"></span>
              </button>
            </div>
          </td>
      </tr>
      <tr style="background-color:#343434; color:white;">
        <td colspan="3">
          Totaal {{tableMenuFilter.selectedYear}}:
        </td>
        <td class="text-right" colspan="1">{{paginatedItemsExclBtw | currencyFormat}}</td>
        <td class="text-right">{{totaalBtw() | currencyFormat}}</td>
        <td class="text-right" colspan="1">{{paginatedItemsInclBtw | currencyFormat}}</td>
        <td colspan="4"></td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <td colspan="10">
        </td>
      </tr>
    </tfoot>
  </table>
</div>
</div>
</div>
<app-pagination #pagination
    [(nrOfItems)]="numberOfItems"
    [(paginationMin)]="paginationMin"
    [(paginationMax)]="paginationMax"
    [totalItems]="paginatedItems.length">
</app-pagination>
<button class="btn btn-primary" type="button" (click)="downloadFile(paginatedItems,'uitgCreditNotas','uitgCreditNotas')">Export CSV</button>
</div>

<div class="container-fluid mt-5">
  <h1>Ontvangen Creditnota's</h1>
  <div class="row">
    <div class="col-12">
      <div class="alert alert-danger" *ngIf="expired">Uw account is verstreken. Ga naar de <a href="account">account</a> pagina om een betaling uit te voeren en het account opnieuw te activeren.</div>
    </div>
  </div>
  <div class="row mt-4">
    <app-table-filter-menu #tableMenuFilter class="col-sm-8"></app-table-filter-menu>

    <div class="col-sm-4">
      <button class="btn btn-primary pull-right" *ngIf="!expired" [disabled]="leveranciers?.length === 0" (click)="createOntvCreditNota()">Nieuwe Ontv. Creditnota</button>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="table-responsive">
  <table class="table table-sm table-striped mt-4">
    <thead>
      <tr>
        <th style="min-width:40px;">
            <span class="fa fa-sort"></span> #
        </th>
        <th>
            <span class="fa fa-sort" (click)="setOrderBy('datum')"></span> Datum
        </th>
        <th>
          <span class="fa fa-sort" (click)="setOrderBy('type')"></span> Type
        </th>
        <th style="min-width:150px;">
            <span class="fa fa-sort" (click)="setOrderBy('leverancier.name')"></span> Leverancier
        </th>
        <th style="min-width:150px;" class="text-right">Bedrag excl. Btw</th>
        <th class="text-right" (click)="btwValue = !btwValue" style="cursor:pointer; min-width:150px;">Btw
            <span class="fa fa-percent" *ngIf="btwValue"></span>
            <span class="fa fa-euro" *ngIf="!btwValue"></span>
          </th>
        <th style="min-width:150px;" class="text-right">Bedrag incl. Btw</th>
        <th class="pl-4">Omschrijving</th>
        <th colspan="2">Acties</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let ontvCreditNota of paginatedRange; let i = index">
        <td>{{i+1}}</td>
        <td>{{ontvCreditNota.datum | date:'dd/MM/yyyy'}}</td>
        <td>
          <span class="badge badge-warning" *ngIf="ontvCreditNota.type === 'Investeringen'">I</span>
          <span class="badge badge-primary" *ngIf="ontvCreditNota.type === 'Handelsgoederen, grond- en hulpstoffen'">H</span>
          <span class="badge badge-info" *ngIf="ontvCreditNota.type === 'Diensten en diverse goederen'">D</span>
        </td>
        <td>{{ontvCreditNota.leverancier?.name}}</td>
        <td class="text-right">{{ontvCreditNota.bedragExclBTW | currencyFormat}}</td>
        <td class="text-right" *ngIf="btwValue">{{ontvCreditNota.BTW === -1 ? 'n.v.t' : ontvCreditNota.BTW + '%'}}</td>
        <td class="text-right" *ngIf="!btwValue">{{(ontvCreditNota.bedragInclBTW - ontvCreditNota.bedragExclBTW) | currencyFormat}}</td>
        <td class="text-right">{{ontvCreditNota.bedragInclBTW | currencyFormat}}</td>
        <td class="pl-4">{{ontvCreditNota.omschrijving}}</td>
          <td>
            <div class="btn-group btn-group-sm pull-right" role="group">
              <button class="btn btn-secondary btn-light" (click)="deleteOntvCreditNota(ontvCreditNota)">
                <span class="fa fa-trash"></span>
              </button>
              <button class="btn btn-secondary btn-light" (click)="editOntvCreditNota(ontvCreditNota)">
                <span class="fa fa-pencil"></span>
              </button>
            </div>
          </td>
      </tr>
      <tr style="background-color:#343434; color:white;">
        <td colspan="4">
          Totaal {{tableMenuFilter.selectedYear}}:
        </td>
        <td class="text-right" colspan="1">{{paginatedItemsExclBtw | currencyFormat}}</td>
        <td class="text-right">{{totaalBtw() | currencyFormat}}</td>
        <td class="text-right" colspan="1">{{paginatedItemsInclBtw | currencyFormat}}</td>
        <td colspan="4"></td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <td colspan="10">
        </td>
      </tr>
    </tfoot>
  </table>
</div>
</div>
</div>
<app-pagination #pagination
    [(nrOfItems)]="numberOfItems"
    [(paginationMin)]="paginationMin"
    [(paginationMax)]="paginationMax"
    [totalItems]="paginatedItems.length">
</app-pagination>
<button class="btn btn-primary" type="button" (click)="downloadFile(paginatedItems,'ontvCreditNotas','ontvCreditNotas')">Export CSV</button>
</div>

<div class="form-group row" *ngIf="currentUser.role === 'Student' || currentUser.role === 'Leerkracht'">
  <div class="col-12">
    <label class="form-label">Kies bedrijf</label>
    <select class="form-control"
    [(ngModel)]="myValue"
    name="selectedBedrijf">
      <option></option>
      <option *ngFor="let bedrijf of bedrijven" [ngValue]="bedrijf">{{bedrijf?.naam}}</option>
    </select>
  </div>
</div>
<div class="form-group row" *ngIf="currentUser.role === 'Student'  && myValue && myValue.naam === 'Pak & Zak'">
  <div class="col-12">
    <div>Logo</div>
    <div class="bedrijfs-logo" #bedrijfsLogo id="bedrijfs-logo"
    style="background-color:white;">
      <img  src='../../assets/pictures/pakenzak.jpg'>
    </div>
  </div>
</div>
<div class="form-group row" *ngIf="currentUser.role === 'Student'  && myValue && myValue.naam === 'Spel & Co'">
  <div class="col-12">
    <div>Logo</div>
    <div class="bedrijfs-logo" #bedrijfsLogo id="bedrijfs-logo"
    style="background-color:white;">
      <img style="width:160px;"  src='../../assets/pictures/spelenco.png'>
    </div>
  </div>
</div>

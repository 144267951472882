<div class="container mt-5">
  <h1>Klant</h1>

  <form (ngSubmit)="onSubmit()" style="margin-top:50px;" #klantForm="ngForm">
  <div class="form-group row">
    <div class="col-12 col-md-3">
      <label for="type" class="col-form-label">Type/Nummer</label>
    </div>
    <div class="col-12 col-md-4">
      <input class="form-control" type="text" [(ngModel)]="klant.type" name="type">
    </div>
  </div>

  <div class="form-group row">
    <div class="col-12 col-md-3">
     <label for="klant" class="col-form-label">Naam
        <div class="error d-inline-block" *ngIf="!klant.name">
          <strong>*</strong>
        </div>
     </label>
    </div>
    <div class="col-12 col-md-4">
      <input class="form-control d-inline-block" type="text" [(ngModel)]="klant.name" name="name" required>
    </div>
  </div>

  <div class="form-group row">
    <div class="col-12 col-md-3">
      <label for="datum" class="col-form-label">BTW Nummer</label>
    </div>
    <div class="col-12 col-md-4">
      <input class="form-control d-inline-block" type="text" [(ngModel)]="klant.btwNr" #inputBTW="ngModel" appBTW name="btwNr">
    </div>
    <div class="col-12 col-md-5 col-form-label">
      <span class="error" *ngIf="inputBTW.invalid">Btw nummer incorrect <i class="fa fa-info-circle" ngbTooltip="Een Btw nummer bestaat uit een landcode en nummer, bijvoorbeeld: BE 0000 000 000"></i></span>
    </div>
  </div>


  <div class="form-group row">
    <div class="col-12 col-md-3">
      <label for="datum" class="col-form-label">Contactpersoon</label>
    </div>
    <div class="col-12 col-md-4">
      <input class="form-control" type="text" [(ngModel)]="klant.contactpersoon" name="contactpersoon">
    </div>
  </div>

  <div class="form-group row">
    <div class="col-12 col-md-3">
     <label for="datum" class="col-form-label">Email</label>
    </div>
    <div class="col-12 col-md-4">
      <input class="form-control" type="text" [(ngModel)]="klant.email"
      name="email">
    </div>
  </div>

  <div class="form-group row">
    <div class="col-12 col-md-3">
      <label for="datum" class="col-form-label">Klant sinds</label>
    </div>
    <div class="col-12 col-md-4">
      <div class="input-group">
      <input class="form-control" [(ngModel)]="klant.klantSubscr" name="klantSubscr"
      ngbDatepicker #d="ngbDatepicker" placeholder="dd/mm/jjjj">
      <div class="input-group-append" (click)="d.toggle()">
        <button type="button" class="btn btn-secondary"><span class="fa fa-calendar"></span></button>
        <!-- <img src="img/calendar-icon.svg" style="width: 1.2rem; height: 1rem; cursor: pointer;"/> -->
      </div>
      </div>
    </div>
  </div>

  <div class="form-group row">
    <div class="col-12 col-md-3">
      <label for="datum" class="col-form-label">Land</label>
    </div>
    <div class="col-12 col-md-4">
      <input class="form-control" type="text" [(ngModel)]="klant.land" name="land">
    </div>
  </div>

  <div class="form-group row">
    <div class="col-12 col-md-3">
      <label for="datum" class="col-form-label">Straat + Nr</label>
    </div>
    <div class="col-12 col-md-4">
      <input class="form-control" type="text" [(ngModel)]="klant.adresStraat" name="adresStraat">
    </div>
  </div>

  <div class="form-group row">
    <div class="col-12 col-md-3">
      <label for="datum" class="col-form-label">Postcode</label>
    </div>
    <div class="col-12 col-md-4">
      <input class="form-control" type="text" [(ngModel)]="klant.postcode" name="postcode">
    </div>
  </div>

  <div class="form-group row">
    <div class="col-12 col-md-3">
      <label for="datum" class="col-form-label">Gemeente</label>
    </div>
    <div class="col-12 col-md-4">
      <input class="form-control" type="text" [(ngModel)]="klant.adresGemeente" name="adresGemeente">
    </div>
  </div>


  <div class="form-group row">
    <div class="col-12 col-md-3">
      <label for="datum" class="col-form-label">Telefoon</label>
    </div>
    <div class="col-12 col-md-4">
      <input class="form-control" type="text" [(ngModel)]="klant.telnr" name="telnr">
    </div>
  </div>

  <div class="form-group row">
    <div class="col-12 col-md-3">
      <label for="datum" class="col-form-label">IBAN</label>
    </div>
    <div class="col-12 col-md-4">
      <input class="form-control d-inline-block" type="text" [(ngModel)]="klant.iban" name="iban" appIBAN #inputIBAN="ngModel">
    </div>
    <div class="col-12 col-md-5 col-form-label">
      <span class="error ml-2" *ngIf="inputIBAN.errors">IBAN is niet correct</span>
    </div>
  </div>

  <div class="form-group row">
    <div class="col-12 col-md-3">
      <label for="datum" class="col-form-label">BIC</label>
    </div>
    <div class="col-12 col-md-4">
      <input class="form-control d-inline-block" type="text" [(ngModel)]="klant.bic" name="bic" appBIC #inputBIC="ngModel">
    </div>
    <div class="col-12 col-md-5">
      <span class="error ml-2" *ngIf="inputBIC.errors">BIC is niet correct</span>
    </div>
  </div>

  <div class="form-group row">
    <div class="col-12 col-md-3">
      <label for="datum" class="col-form-label">RPR</label>
    </div>
    <div class="col-12 col-md-4">
      <input class="form-control" type="text" [(ngModel)]="klant.rpr" name="rpr">
    </div>
  </div>

  <div class="error mb-3">
    * Verplicht in te vullen
  </div>

  <button type="button" class="btn btn-light mr-3" (click)="navigateAway()">Annuleren</button>
  <button type="submit" *ngIf="!isEdit" class="btn btn-primary" [disabled]="!klantForm.valid">Toevoegen</button>
  <button type="submit" *ngIf="isEdit" class="btn btn-primary" [disabled]="!klantForm.valid">Opslaan</button>
</form>
</div>

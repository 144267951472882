import { Component, OnInit } from '@angular/core';
import { Klant } from '../../../../models/Klant';
import { UitgCreditnotaService } from '../uitg-creditnota.service';
import { ActivatedRoute } from '@angular/router';
import { GeneralComponent } from '../../../general/general.component';
import { NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { I18n, CustomDatepickerI18n } from "../../../ngb-date-fr-parser-formatter/ngb-i18n"
import { NgbDatepickerConfig, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateFRParserFormatter } from "../../../ngb-date-fr-parser-formatter/ngb-date-fr-parser-formatter"
import { KlantService } from '../../../klant/klant.service';
import { Location } from '@angular/common';
import { take } from 'rxjs/operators';


@Component({
  selector: 'app-uitg-creditnota-edit',
  templateUrl: './uitg-creditnota-edit.component.html',
  providers: [
    I18n,
    {provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter},
    {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}
  ]
})
export class UitgCreditnotaEditComponent extends GeneralComponent implements OnInit {
  uitgCreditNota;
  calculatedBTW: number;
  klanten: Klant[];
  isEdit = false;
  currentUser;

  constructor(protected uitgCreditNotaService: UitgCreditnotaService,
    protected klantenService: KlantService,
    private location: Location,
    private route: ActivatedRoute) {
      super();
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }

  ngOnInit() {
    let param = this.route.snapshot.params['id'];
    if(param){
      this.isEdit = true;
      this.uitgCreditNotaService.getUitgCreditNota(param)
      .pipe(take(1))
      .subscribe(aankoop =>
        {
          this.uitgCreditNota = aankoop;
          this.uitgCreditNota.datum = this.convertInputDate(this.uitgCreditNota.datum);
          this.calculateBTW();
          this.uitgCreditNota.bedragExclBTW = this.uitgCreditNota.bedragExclBTW.toString().replace('.',',');
        });
    }
    this.uitgCreditNota = {
      _id: '',
      datum: null,
      klant: new Klant("test"),
      type: null,
      bedragExclBTW: 0,
      bedragInclBTW: 0,
      BTW: 0,
      omschrijving: '',
      bijlage: '',
      status: false,
      intracommunautair: false,
      icType: null
    }
    this.klantenService.getAllKlanten(this.currentUser).subscribe((klanten: Klant[]) => {
      this.klanten = klanten
    });

  }


  public onSubmit() {
    this.finalizeEdit(this.uitgCreditNota);
    const addOrUpdateUitgCreditNota = this.isNew(this.uitgCreditNota) ?
    this.uitgCreditNotaService.addUitgCreditNota(this.uitgCreditNota) :
    this.uitgCreditNotaService.updateUitgCreditNota(this.uitgCreditNota) ;

    addOrUpdateUitgCreditNota
    .pipe(take(1))
    .subscribe(
      response => {
        this.location.back();
      },
    );
  }

  navigateAway() {
    this.location.back();
  }

  calculateBTW() {
    let result = this.calcBTW(this.uitgCreditNota.BTW.toString(), this.uitgCreditNota.bedragExclBTW, this.uitgCreditNota.bedragInclBTW)
    this.uitgCreditNota.bedragExclBtw = result[0],
    this.calculatedBTW = result[1],
    this.uitgCreditNota.bedragInclBTW = result[2]
  }

  getKlant(klantName) {
    let klant = this.klanten.find(klant => klant.name === klantName);
    this.uitgCreditNota.klant = klant;
  }

}

import { Component, OnInit } from '@angular/core';
import { Verkoop } from '../../../../models/Verkoop';
import { VerkoopService } from '../verkoop.service';
import { Location } from '@angular/common';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Klant } from '../../../../models/Klant';
import { KlantService } from '../../../klant/klant.service';
import { GeneralComponent } from '../../../general/general.component';
import { NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { I18n, CustomDatepickerI18n } from "../../../ngb-date-fr-parser-formatter/ngb-i18n"
import { NgbDatepickerConfig, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateFRParserFormatter } from "../../../ngb-date-fr-parser-formatter/ngb-date-fr-parser-formatter"
import { Factuur } from '../../../../models/Factuur';
import { FacturenService } from '../../../facturen/facturen.service';
import { take } from 'rxjs/operators';


@Component({
  selector: 'app-verkoop-edit',
  templateUrl: './verkoop-edit.component.html',
  providers: [
    I18n,
    {provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter},
    {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}
  ]
})
export class VerkoopEditComponent extends GeneralComponent implements OnInit {
  newVerkoop;
  calculatedBTW: number;
  klanten: Klant[];
  facturen: Factuur[];
  verkopen: Verkoop[];
  isEdit = false;

  currentUser;

  constructor(protected verkoopService: VerkoopService,
              protected klantService: KlantService,
              protected facturenService: FacturenService,
              private location: Location,
              private route: ActivatedRoute) {
                super();
                this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
              }

  ngOnInit() {
    this.facturen = [];
    this.klantService.getAllKlanten(this.currentUser)
    .pipe(take(1))
    .subscribe((klanten: Klant[]) => this.klanten = klanten);
    this.facturenService.getAllFacturen(this.currentUser)
    .pipe(take(1))
    .subscribe((facturen: Factuur[]) => {
      this.facturen = facturen
      let filteredFacturen = [];
      let availableFacturen = [];
      this.verkoopService.getAllVerkopen(this.currentUser)
      .pipe(take(1))
      .subscribe((verkopen: Verkoop[]) => {
          this.verkopen = verkopen
          verkopen.forEach(verkoop => {
            if(verkoop.factuur){
              filteredFacturen.push(verkoop.factuur);
            }
          })
          this.facturen.forEach( factuur => {
            let found = filteredFacturen.find(filteredFactuur => filteredFactuur._id === factuur._id);
            if(!found || this.newVerkoop?.factuur?._id === found._id){
              availableFacturen.push(factuur);
            }
            this.facturen = availableFacturen;
          })
      })
    });

    let param = this.route.snapshot.params['id'];
    if(param){
      this.isEdit = true;
      this.verkoopService.getVerkoop(param)
      .pipe(take(1))
      .subscribe(verkoop =>
        { this.newVerkoop = verkoop;
          if(this.newVerkoop.factuur === null){
            this.newVerkoop.factuur = { _id: null };
          }
          this.newVerkoop.datum = this.convertInputDate(this.newVerkoop.datum);
          this.calculateBTW();
          this.newVerkoop.bedragExclBTW = this.newVerkoop.bedragExclBTW.toString().replace('.',',');
        });
    }

    // new verkoop
    this.newVerkoop = {
      datum: null,
      klant: new Klant("test") ,
      bedragExclBTW: 0,
      bedragInclBTW: 0,
      BTW: 0,
      omschrijving: '',
      bijlage: '',
      status: false,
      kortingVoorContant: null,
      terugstuurbareVerpakking: null,
      intracommunautair: false,
      icType: null,
      factuur: null,
    }
  }

  navigateAway(){
    this.location.back();
  }

  public onSubmit() {
    this.finalizeEdit(this.newVerkoop)
    const addOrUpdateVerkoop = this.isNew(this.newVerkoop) ?
    this.verkoopService.addVerkoop(this.newVerkoop) :
    this.verkoopService.updateVerkoop(this.newVerkoop);

    addOrUpdateVerkoop
    .pipe(take(1))
    .subscribe(
      response => {
        this.location.back();
      },
    );
  }



  getKlant(klantName){
    let klant = this.klanten.find(klant => klant.name === klantName);
    this.newVerkoop.klant = klant;
  }

  getFactuur(factuurNummer){
    let factuur = this.facturen.find(factuur => factuur.factuurNummer === factuurNummer);
    this.newVerkoop.factuur = factuur;
  }

  calculateBTW() {
    let result;
    if(!this.newVerkoop.kortingVoorContant) {
      result = this.calcBTW(this.newVerkoop.BTW.toString(), this.newVerkoop.bedragExclBTW, this.newVerkoop.bedragInclBTW)
    } else {
      result = this.calcBTW(this.newVerkoop.BTW.toString(), this.newVerkoop.bedragExclBTW, this.newVerkoop.bedragInclBTW, this.getMaatstafVanHeffing())
    }

    this.calculatedBTW = result[1]
    if (this.newVerkoop.terugstuurbareVerpakking)
      result[2] += parseFloat(this.newVerkoop.terugstuurbareVerpakking);
    this.newVerkoop.bedragInclBTW = result[2]
  }

  getMaatstafVanHeffing() {
    let bedragExclBTW = parseFloat(this.newVerkoop.bedragExclBTW.toString().replace(',','.'));
    let kortingVoorContant = parseFloat(this.newVerkoop.kortingVoorContant);
    let korting = (bedragExclBTW / 100) * kortingVoorContant;
    return bedragExclBTW - korting;
  }

}

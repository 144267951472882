<div class="container mt-5">
  <h1>Ontvangen Creditnota</h1>
  <form (ngSubmit)="onSubmit()" style="margin-top:50px;" #aankoopForm="ngForm">

    <div class="form-group row">
      <label for="datum" class="col-form-label col-12 col-md-3">Datum</label>
      <div class="col-12 col-md-4">
        <div class="input-group">
        <input class="form-control" ngbDatepicker #d="ngbDatepicker" [(ngModel)]="ontvCreditNota.datum" name="datum" required>
        <div class="input-group-append" (click)="d.toggle()" type="button">
          <button type="button" class="btn btn-secondary"><span class="fa fa-calendar"></span></button>
         </div>
         </div>
      </div>
      <div class="col-12 col-md-5">
        <div class="error col-form-label" *ngIf="aankoopForm.controls?.datum?.invalid">
          Vul een geldige datum in.
        </div>
      </div>
    </div>


    <div class="form-group row">
      <label for="leverancier" class="col-12 col-md-3 col-form-label">Leverancier
        <span class="error" *ngIf="!ontvCreditNota.leverancier._id"><strong>*</strong></span>
      </label>

      <div class="col-12 col-md-4">
        <select id="leverancier" name="leverancier" [(ngModel)]="ontvCreditNota.leverancier._id" (change)="getLeverancier(leverancierName.options[leverancierName.selectedIndex].text)"
          required class="form-control" #leverancierName="ngModel">
          <option></option>
          <option *ngFor="let leverancier of leveranciers" [ngValue]="leverancier._id">{{leverancier.name}}</option>
        </select>
      </div>
    </div>

    <div class="form-group row">
      <label for="klant" class="col-12 col-md-3 col-form-label">
        Intracommunautair <i class="fa fa-info-circle" aria-hidden="true" ngbTooltip="Onder een intracommunautaire aankoop verstaan we het in België binnenbrengen van een goed dat wordt verstuurd of getransporteerd vanuit een land dat lidstaat is van de EU. De btw wordt verlegd naar de koper."></i>
      </label>
      <div class="col-md-4 col-form-label">
        <input type="checkbox" name="intracommunautair" id="intracommunautair" [checked]="ontvCreditNota.intracommunautair" [(ngModel)]="ontvCreditNota.intracommunautair">
      </div>
    </div>

    <div class="form-group row" *ngIf="ontvCreditNota.intracommunautair">
      <label for="klant" class="col-md-3 col-form-label">
        IC Type <span class="error" *ngIf="!ontvCreditNota.icType"><strong>*</strong></span>
      </label>
      <div class="col-md-4">
        <select class="form-control" name="icType" id="icType" [(ngModel)]="ontvCreditNota.icType" required>
          <option value="Diensten">Diensten</option>
          <option value="Levering">Levering</option>
          <option value="Driehoeksverkeer">Driehoeksverkeer</option>
        </select>
      </div>
    </div>

    <div class="form-group row">
      <label for="type" class="col-form-label col-12 col-md-3">Type</label>
      <div class="col-12 col-md-4">
        <select name="type" [(ngModel)]="ontvCreditNota.type" class="form-control">
          <option [value]="'Handelsgoederen, grond- en hulpstoffen'">Handelsgoederen, grond- en hulpstoffen</option>
          <option [value]="'Diensten en diverse goederen'">Diensten en diverse goederen</option>
          <option [value]="'Investeringen'">Investeringen</option>
        </select>
      </div>
    </div>

    <div class="form-group row">
      <label for="datum" class="col-12 col-md-3 col-form-label">Bedrag excl. Btw
        <span class="error" *ngIf="!ontvCreditNota.bedragExclBTW"><strong>*</strong></span>
      </label>
      <div class="col-12 col-md-4">
        <input appNumber #bedragExclBtw="ngModel" class="form-control" type="text" [(ngModel)]="ontvCreditNota.bedragExclBTW" name="bedragExclBTW" required (ngModelChange)="calculateBTW()">
      </div>
      <div class="col-12 col-md-5 col-form-label">
        <span class="error" *ngIf="bedragExclBtw.errors?.number">Vul een geldig getal in</span>
      </div>
    </div>

    <div class="form-group row">
      <label for="datum" class="col-12 col-md-3 col-form-label">Btw
        <span class="error" *ngIf="!ontvCreditNota.BTW && ontvCreditNota.BTW !== 0"><strong>*</strong></span>
      </label>
      <div class="col-12 col-md-2">
        <select class="form-control" [(ngModel)]="ontvCreditNota.BTW" name="BTW" required (ngModelChange)="calculateBTW()">
          <option [value]="-1">n.v.t</option>
          <option [value]="0">0</option>
          <option [value]="6">6</option>
          <option [value]="12">12</option>
          <option [value]="21">21</option>
        </select>
      </div>
      <div class="col-12 col-md-4">
        <input type="text" class="form-control" disabled [ngModel]="calculatedBTW | currencyFormat" name="calculatedBTW" maxlength="5">
      </div>
    </div>

    <div class="form-group row">
      <label for="datum" class="col-12 col-md-3 col-form-label">Bedrag incl. Btw</label>
      <div class="col-12 col-md-4">
        <input class="form-control" type="text" [ngModel]="ontvCreditNota.bedragInclBTW | currencyFormat" name="bedragInclBTW" disabled>
      </div>
    </div>

    <div class="form-group row">
      <label for="datum" class="col-12 col-md-3 col-form-label">Omschrijving</label>
      <div class="col-12 col-md-4">
        <input class="form-control" type="text" [(ngModel)]="ontvCreditNota.omschrijving" name="omschrijving">
      </div>
    </div>

    <div class="row">
      <div class="col-12 error mb-3">
        * Verplicht in te vullen
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <button type="button" class="btn btn-light mr-2" (click)="navigateAway()">Annuleren</button>
        <button type="submit" *ngIf="!isEdit" class="btn btn-primary" [disabled]="!aankoopForm.valid">Toevoegen</button>
        <button type="submit" *ngIf="isEdit" class="btn btn-primary" [disabled]="!aankoopForm.valid">Opslaan</button>
      </div>
    </div>
  </form>

</div>

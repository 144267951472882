import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Verrichting } from '../../../models/Verrichting';
import { map } from 'rxjs/operators';

@Injectable()
export class FinancieelService {


  constructor(private http: HttpClient) {

   }


  addVerrichting(verrichting: Verrichting){
    let URI = '/api/financieel/';
    let headers = new HttpHeaders;
    headers.append('Cache-Control', 'no-cache');
    headers.append('Pragma', 'no-cache');
    headers.append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT');
    let body = {
      datum: verrichting.datum,
      type: verrichting.type,
      bedrag: verrichting.bedrag,
      omschrijving: verrichting.omschrijving,
      reknr: verrichting.reknr
    };
    headers.append('Content-Type','application/json');
    return this.http.post(URI, body, {headers: headers})
    .pipe(map(res => res));
  }

  getAllStudentenVerrichtingen(currentUser) {
    let headers = new HttpHeaders;
    headers.append('Cache-Control', 'no-cache')
    headers.append('Pragma', 'no-cache')
    headers.append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
    return this.http.get('/api/financieel/studenten/' + currentUser.leerkrachtCode, {headers: headers})
    .pipe(map(res => res));
  }

  getAllKlantenVerrichtingen(currentUser) {
    let headers = new HttpHeaders;
    headers.append('Cache-Control', 'no-cache')
    headers.append('Pragma', 'no-cache')
    headers.append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
    return this.http.get('/api/financieel/klanten/' + currentUser.boekhouderCode, {headers: headers})
    .pipe(map(res => res));
  }

  getAllVerrichtingen(currentUser) {
    let headers = new HttpHeaders;
    headers.append('Cache-Control', 'no-cache')
    headers.append('Pragma', 'no-cache')
    headers.append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
    return this.http.get('/api/financieel/' + currentUser._id, {headers:headers})
    .pipe(map(res => res));
  }

  getVerrichting(financieelId: string){
    let headers = new HttpHeaders;
    headers.append('Cache-Control', 'no-cache');
    headers.append('Pragma', 'no-cache');
    headers.append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT');
    return this.http.get('/api/financieel/verrichting/' + financieelId, {headers: headers})
    .pipe(map(res => res));
  }

  updateVerrichting(verrichting: Verrichting){
    let URI = `/api/financieel/${verrichting._id}`
    let headers = new HttpHeaders;
    headers.append('Cache-Control', 'no-cache');
    headers.append('Pragma', 'no-cache');
    headers.append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT');
    let body = {
      datum: verrichting.datum,
      type: verrichting.type,
      bedrag: verrichting.bedrag,
      omschrijving: verrichting.omschrijving,
      reknr: verrichting.reknr
    }
    headers.append('Content-Type', 'application/json');
    return this.http.put(URI, body, {headers})
    .pipe(map(res => res));
  }

  removeVerrichting(verrichtingId: string){
    let URI = `/api/financieel/${verrichtingId}`;
    let headers = new HttpHeaders;
    headers.append('Content-Type', 'application/json');
    return this.http.delete(URI, {headers})
    .pipe(map(res => res));
  }

}

import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '../../../models/user';
import { UitgCreditNota } from '../../../models/Creditnota';
import { map } from 'rxjs/operators';

@Injectable()
export class UitgCreditnotaService {

  constructor(private http: HttpClient) {
  }

  getAllUitgCreditNotas(currentUser){
    let headers = new HttpHeaders;
    headers.append('Cache-Control', 'no-cache')
    headers.append('Pragma', 'no-cache')
    headers.append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
    return this.http.get('/api/uitgaande-creditnotas/' + currentUser._id, {headers:headers})
      .pipe(map(res => res));
  }

  addUitgCreditNota(uitgCreditNota: UitgCreditNota){
    let URI = '/api/uitgaande-creditnotas/';
    let headers = new HttpHeaders;
    let body = {
      datum: uitgCreditNota.datum,
      klant: uitgCreditNota.klant,
      bedragExclBTW: uitgCreditNota.bedragExclBTW,
      BTW: uitgCreditNota.BTW,
      bedragInclBTW: uitgCreditNota.bedragInclBTW,
      omschrijving: uitgCreditNota.omschrijving,
      intracommunautair: uitgCreditNota.intracommunautair,
      icType: uitgCreditNota.icType
    };
    headers.append('Content-Type', 'application/json');
    return this.http.post(URI, body, {headers: headers})
    .pipe(map(res => res));
  }

  getStudentenUitgCreditNotas(currentUser) {
    let headers = new HttpHeaders;
    headers.append('Cache-Control', 'no-cache')
    headers.append('Pragma', 'no-cache')
    headers.append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
    return this.http.get('/api/uitgaande-creditnotas/studenten/' + currentUser.leerkrachtCode, {headers:headers})
    .pipe(map(res => res));
  }

  getKlantenUitgCreditNotas(currentUser) {
    let headers = new HttpHeaders;
    headers.append('Cache-Control', 'no-cache')
    headers.append('Pragma', 'no-cache')
    headers.append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
    return this.http.get('/api/uitgaande-creditnotas/klanten/' + currentUser.boekhouderCode, {headers:headers})
    .pipe(map(res => res));
  }

  updateUitgCreditNota(uitgCreditNota: UitgCreditNota){
    let URI = `/api/uitgaande-creditnotas/creditnota/${uitgCreditNota._id}`
    let headers = new HttpHeaders;
    let body = {
      datum: uitgCreditNota.datum,
      klant: uitgCreditNota.klant,
      bedragExclBTW: uitgCreditNota.bedragExclBTW,
      BTW: uitgCreditNota.BTW,
      bedragInclBTW: uitgCreditNota.bedragInclBTW,
      omschrijving: uitgCreditNota.omschrijving,
      intracommunautair: uitgCreditNota.intracommunautair,
      icType: uitgCreditNota.icType
    }
    headers.append('Content-Type', 'application/json');
    return this.http.put(URI, body, {headers})
    .pipe(map(res => res));
  }

  getUitgCreditNota(uitgCreditNotaId: string) {
    let headers = new HttpHeaders;
    headers.append('Cache-Control', 'no-cache');
    headers.append('Pragma', 'no-cache');
    headers.append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT');
    return this.http.get('/api/uitgaande-creditnotas/creditnota/' + uitgCreditNotaId, {headers:headers}).pipe(map(res => res));
  }

  removeUitgCreditNota(uitgCreditNotaId: string) {
    let URI = `/api/uitgaande-creditnotas/creditnota/${uitgCreditNotaId}`;
    let headers = new HttpHeaders;
    headers.append('Content-Type', 'application/json');
    return this.http.delete(URI, {headers})
    .pipe(map(res => res));
  }

}

<div class="container mt-5">
  <h1>Verrichting</h1>

  <form name="formFinancieel" #formFinancieel="ngForm" (ngSubmit)="onSubmit()" class="mt-5">
    <div class="form-group row">
      <label class="form-label col-12 col-md-3">Datum</label>
      <div class="input-group col-12 col-md-4">
      <input ngbDatepicker #d="ngbDatepicker" name="datum" [(ngModel)]="verrichting.datum" class="form-control">
      <div class="input-group-append" (click)="d.toggle()" type="button">
        <button type="button" class="btn btn-secondary"><span class="fa fa-calendar"></span></button>
      </div>
    </div>
  </div>

<div class="form-group row">
  <label class="form-label col-12 col-md-3">Type</label>
  <div class="col-12 col-md-4">
    <select name="type" [(ngModel)]="verrichting.type" class="form-control">
      <option value='Ontvangst'>Ontvangst</option>
      <option value='Uitgave'>Uitgave</option>
    </select>
  </div>
</div>

<div class="form-group row">
  <label class="form-label col-12 col-md-3">IBAN </label>
  <div class="col-12 col-md-4">
    <input type="text" name="iban" [(ngModel)]="verrichting.reknr" class="form-control" appIBAN #inputIBAN="ngModel">
  </div>
  <div class="col-12 col-md-5 col-form-label">
    <span class="error" *ngIf="inputIBAN.errors">IBAN is niet correct</span>
  </div>
</div>


<div class="form-group row">
  <label class="form-label col-12 col-md-3">Bedrag</label>
  <div class="col-12 col-md-4">
    <input type="text" name="bedrag" [(ngModel)]="verrichting.bedrag" class="form-control" #bedrag="ngModel" appNumber>
  </div>
  <div class="col-12 col-md-5 col-form-label">
    <span class="error" *ngIf="bedrag.errors?.number">Vul een geldig getal in</span>
  </div>
</div>

<div class="form-group row">
  <label class="form-label col-12 col-md-3">Omschrijving</label>
  <div class="col-12 col-md-4">
    <input type="text" name="omschrijving" [(ngModel)]="verrichting.omschrijving" class="form-control">
  </div>
</div>

<button type="button" class="btn btn-light mr-3" (click)="navigateAway()">Annuleren</button>
<button *ngIf="!isEdit" type="submit" class="btn btn-primary" [disabled]="formFinancieel.invalid">Toevoegen</button>
<button *ngIf="isEdit" type="submit" class="btn btn-primary" [disabled]="formFinancieel.invalid">Opslaan</button>
  </form>
</div>

import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-voorraadbeweging-modal',
  templateUrl: './voorraadbeweging-modal.component.html'
})
export class VoorraadbewegingModalComponent implements OnInit {
  @Input() voorraadBewegingen;
  @Input() productNaam;
  @Input() productNr;

  voorraadBewegingenReversed;
  voorlopigeAantallen = [];

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    this.voorraadBewegingenReversed = this.voorraadBewegingen.reverse();

    let voorlopigAantal = this.voorraadBewegingenReversed[this.voorraadBewegingenReversed.length - 1].verschil;

      this.voorlopigeAantallen.push(voorlopigAantal);

      for (let i = this.voorraadBewegingenReversed.length - 2; i >= 0; i--) {
        voorlopigAantal = voorlopigAantal + this.voorraadBewegingenReversed[i].verschil;
        this.voorlopigeAantallen.unshift(voorlopigAantal);
      }
  }

  getVoorlopig(index) {
    return this.voorlopigeAantallen[index];
  }
}

<div class="container-fluid mt-5">
  <h1>Aankopen</h1>
  <div class="row">
    <div class="col-12">
      <div class="alert alert-danger" *ngIf="expired">Uw account is verstreken. Ga naar de <a href="account">account</a> pagina om een betaling uit te voeren en het account opnieuw te activeren.</div>
    </div>
  </div>
  <div class="row mt-4">
    <app-table-filter-menu #tableMenuFilter class="col-sm-8"></app-table-filter-menu>
    <div class="col-sm-4">
      <button class="btn btn-primary pull-right" (click)="createAankoop()" [disabled]="leveranciers?.length === 0">Nieuwe Aankoop</button>
    </div>
    </div>
   <div class="row">
     <div class="col-12">
       <div class="table-responsive">
    <table class="table table-sm table-striped mt-4">
      <thead>
        <tr>
          <th style="min-width:40px;">
            <span class="fa fa-sort" (click)="setOrderBy('id')"></span>   #
          </th>
          <th>
            <span class="fa fa-sort" (click)="setOrderBy('type')"></span> Type
          </th>
          <th>
              <span class="fa fa-sort" (click)="setOrderBy('datum')"></span> Datum
          </th>
          <th style="min-width:150px;">
              <span class="fa fa-sort" (click)="setOrderBy('leverancier.name')"></span> Leverancier
          </th>
          <th style="min-width:150px;" class="text-right">Bedrag excl. Btw</th>
          <th class="text-right" (click)="btwValue = !btwValue" style="cursor:pointer; min-width:150px;">Btw
              <span class="fa fa-percent" *ngIf="btwValue"></span>
              <span class="fa fa-euro" *ngIf="!btwValue"></span>
            </th>
          <th style="min-width:150px;" class="text-right">Bedrag incl. Btw</th>
          <th class="pl-4">Omschrijving</th>
          <th>Bijlage</th>
          <th>
              <span class="fa fa-sort" (click)="setOrderBy('status')"></span> Betaald
          </th>
          <th colspan="2">Acties</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let aankoop of paginatedRange; let i = index">
          <td>{{aankoop.id}}</td>
          <td>
            <span class="badge badge-warning" *ngIf="aankoop.type === 'Investeringen'">I</span>
            <span class="badge badge-primary" *ngIf="aankoop.type === 'Handelsgoederen, grond- en hulpstoffen'">H</span>
            <span class="badge badge-info" *ngIf="aankoop.type === 'Diensten en diverse goederen'">D</span>
          </td>
          <td>{{aankoop.datum | date: 'dd/MM/yyyy'}}</td>
          <td>{{aankoop.leverancier.name}}</td>
          <td class="text-right">{{aankoop.bedragExclBTW | currencyFormat}}</td>
          <td class="text-right" *ngIf="btwValue">{{aankoop.BTW === -1 ? 'n.v.t' : aankoop.BTW + '%'}}</td>
          <td class="text-right" *ngIf="!btwValue">{{(aankoop.bedragInclBTW - aankoop.bedragExclBTW) | currencyFormat}}</td>
          <td class="text-right">{{aankoop.bedragInclBTW | currencyFormat}}</td>
          <td class="pl-4">{{aankoop.omschrijving}}</td>
          <td>
            <a target="_blank" *ngIf="aankoop.bijlage" [href]="getAttachment(aankoop)"><i class="fa fa-paperclip"></i> {{aankoop.bijlage}}</a>
          </td>
          <td>
            <span class="badge badge-danger" *ngIf="!aankoop.status">NIET BETAALD</span>
            <span class="badge badge-success" *ngIf="aankoop.status">BETAALD</span>
          </td>
          <td>
            <div class="btn-group btn-group-sm pull-right" role="group">
              <button class="btn-secondary btn btn-light" (click)="deleteAankoop(aankoop)">
                <span class="fa fa-trash"></span>
              </button>
              <button class="btn-secondary btn btn-light" (click)="editAankoop(aankoop)">
                <span class="fa fa-pencil"></span>
              </button>
            </div>
          </td>
        </tr>
        <tr style="background-color:#343434; color:white;">
          <td colspan="4">
            Totaal {{tableMenuFilter.selectedYear}}:
          </td>
          <td class="text-right" colspan="1">{{paginatedItemsExclBtw | currencyFormat}}</td>
          <td class="text-right">{{totaalBtw() | currencyFormat}}</td>
          <td class="text-right" colspan="1">{{paginatedItemsInclBtw | currencyFormat}}</td>
          <td colspan="4"></td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="10">

          </td>
        </tr>
      </tfoot>
    </table>
  </div>

    <div class="row">
      <div class="column-sm-1 ml-3">
          <span class="badge badge-primary">H</span> Handelsgoederen, grond- en hulpstoffen
      </div>
      <div class="column-sm-1 ml-3">
        <span class="badge badge-info">D</span> Diensten en diverse goederen
      </div>
      <div class="column-sm-1 ml-3">
          <span class="badge badge-warning">I </span> Investeringen
      </div>
    </div>
  </div>
  </div>
  <app-pagination #pagination
    [(nrOfItems)]="numberOfItems"
    [(paginationMin)]="paginationMin"
    [(paginationMax)]="paginationMax"
    [totalItems]="paginatedItems.length">
</app-pagination>
<button class="btn btn-primary" type="button" (click)="downloadFile(paginatedItems,'aankopen','aankopen')">Export CSV</button>
  </div>

import { Component, OnInit, ViewChild } from '@angular/core';
import { Aankoop } from '../../../../models/Aankoop';
import { AankoopService } from '../aankoop.service';
import { Location } from '@angular/common';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Leverancier } from '../../../../models/Leverancier';
import { LeverancierService } from '../../../leverancier/leverancier.service';
import { NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { I18n, CustomDatepickerI18n } from "../../../ngb-date-fr-parser-formatter/ngb-i18n"
import { NgbDatepickerConfig, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateFRParserFormatter } from "../../../ngb-date-fr-parser-formatter/ngb-date-fr-parser-formatter"
import { GeneralComponent } from '../../../general/general.component';
import { take } from 'rxjs/operators';
import { UntypedFormBuilder, UntypedFormGroup, NgForm } from '@angular/forms';

@Component({
  selector: 'app-aankoop-edit',
  templateUrl: './aankoop-edit.component.html',
  providers: [
    I18n,
    {provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter},
    {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}
  ]
})
export class AankoopEditComponent extends GeneralComponent implements OnInit {
  @ViewChild('aankoopForm') aankoopForm: NgForm;

  newAankoop;
  calculatedBTW: number;
  leveranciers: Leverancier[];
  isEdit = false;
  currentUser;

  uploadForm: UntypedFormGroup;

  fileFormData: FormData = undefined;


  constructor(protected aankoopService: AankoopService,
              protected leverancierService: LeverancierService,
              private location: Location,
              private router: Router,
              private route: ActivatedRoute,
              private formBuilder: UntypedFormBuilder) {
                super();
                this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
               }

  ngOnInit() {
    this.uploadForm = this.formBuilder.group({
      profile: ['']
    });

    const param = this.route.snapshot.params['id'];
    if (param) {
      this.isEdit = true;
      this.aankoopService.getAankoop(param)
      .pipe(take(1))
      .subscribe(aankoop => {
          console.log(aankoop.type)
          this.newAankoop = aankoop;
          console.log(aankoop);
          this.newAankoop.datum = this.convertInputDate(this.newAankoop.datum);
          this.calculateBTW()
          this.newAankoop.bedragExclBTW = this.newAankoop.bedragExclBTW.toString().replace('.', ',');
          console.log(this.newAankoop);
        });
    }
      this.newAankoop = {
        _id: '',
        datum: null,
        leverancier: new Leverancier('test'),
        type: '',
        bedragExclBTW: 0,
        bedragInclBTW: 0,
        BTW: 0,
        omschrijving: '',
        bijlage: '',
        status: false,
        intracommunautair: false,
        icType: null
      }
    this.leverancierService.getAllLeveranciers(this.currentUser)
      .pipe(take(1))
      .subscribe((leveranciers: Leverancier[]) => this.leveranciers = leveranciers);
  }

  public onSubmit() {
    this.finalizeEdit(this.newAankoop);
    const addUpdateAankoop = this.isNew(this.newAankoop) ?
    this.aankoopService.addAankoop(this.newAankoop) :
    this.aankoopService.updateAankoop(this.newAankoop);

    addUpdateAankoop
    .pipe(take(1))
    .subscribe(
      response => {
          if(this.fileFormData) {
            this.fileFormData.append('aankoop', JSON.stringify(response));
            console.log(this.fileFormData)
            return this.aankoopService.uploadAttachment(this.fileFormData)
            .pipe(take(1))
            .subscribe(() => this.navigateAway());
          }
          return this.navigateAway();
      },
    );
  }

  navigateAway(){
    return this.router.navigate(['./aankopen'])
  }

  calculateBTW() {
    let result = this.calcBTW(this.newAankoop.BTW.toString(), this.newAankoop.bedragExclBTW, this.newAankoop.bedragInclBTW)

    this.newAankoop.bedragExclBTW = result[0],
    this.calculatedBTW = result[1],
    this.newAankoop.bedragInclBTW = result[2]
  }

  getLeverancier(leverancierName) {
    let leverancier = this.leveranciers.find(leverancier => leverancier.name === leverancierName);
    this.newAankoop.leverancier = leverancier;
  }

  getAttachment() {
    return 'assets/uploads/' + this.currentUser.username + '/aankopen/' + this.newAankoop._id + '/' + this.newAankoop.bijlage;
  }



  handleFileInput(files: FileList) {
    const file = files[0];
      this.uploadForm.get('profile').setValue(file);

      const formData = new FormData();
      formData.append('file', this.uploadForm.get('profile').value);
      formData.append('user', JSON.stringify(this.currentUser));


      this.newAankoop.bijlage = file.name

      this.fileFormData = formData;
  }

  removeAttachment() {
    this.aankoopService.deleteAttachment(
      {user: this.currentUser,
      aankoopId: this.newAankoop._id,
      fileName: this.newAankoop.bijlage
    }
      ).pipe(take(1)).subscribe(() => this.newAankoop.bijlage = null);
  }

  getForm() {
    console.log(this.aankoopForm)
    return 'test';
  }



}

<div class="container">
  <div class="row">
    <div class="col-12">
  <h1 class="mb-4 mt-4">Intracommunautaire Listing</h1>

  <app-table-filter-menu [showKwartaalFilter]="false"></app-table-filter-menu>
</div>
</div>

  <div class="row mt-4">
    <div class="col-12">
      <ng-container>
        <div class="table-responsive">
        <table class="table table-sm table-striped">
          <thead>
            <tr>
              <th>Klant</th>
              <th style="min-width: 150px;" class="text-right">Omzet (excl. BTW)</th>
              <th style="min-width: 150px;" class="text-right">Creditnota's</th>
              <th style="min-width: 150px;" class="text-right">Totaal</th>
              <th class="text-right">Type</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let listKlant of klantenIds">
            <tr *ngIf="listKlant.klant.btwNr && listKlant.list">
              <ng-container>
                <td>{{listKlant.klant.name}} ({{listKlant.klant.btwNr}})</td>
                <td class="text-right">{{listKlant.totaal | currencyFormat}}</td>
                <td class="text-right">{{listKlant.creditTotaal | currencyFormat}}</td>
                <td class="text-right">{{listKlant.totaal - listKlant.creditTotaal | currencyFormat}}</td>
                <td class="text-right">{{listKlant.icType}}</td>
              </ng-container>
            </tr>
          </ng-container>
          </tbody>
          <tfoot>
            <tr style="background-color: #343434; color: white;">
              <td>Totaal</td>
              <td></td>
              <td></td>
              <td class="text-right">{{totaalICListing | currencyFormat}}</td>
              <td></td>
            </tr>
          </tfoot>
        </table>
      </div>
      </ng-container>
    </div>
  </div>
</div>

<div class="container mt-5">
  <div class="row">
    <div class="col-12">
      <h1>Account</h1>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="alert alert-danger" *ngIf="expired">
        Uw account is verstreken, u kan het opnieuw activeren via Bancontact:
        <form novalidate (ngSubmit)="createCheckout()" [formGroup]="stripeTest">
          <button class="btn btn-sm" type="submit">
            <img src="/assets/pictures/bancontact.png" width="150px">
          </button>
        </form>
      </div>
    </div>
<div class="col-md-6 col-md-offset-3">

  <label><strong>Gebruikersnaam</strong></label>
  <div>
    {{currentUser.username}} <i *ngIf="currentUser.role">({{currentUser.role}})</i>
  </div>
  <label><strong>Voornaam</strong></label>
  <div>
    {{currentUser.firstName}}
  </div>
  <label><strong>Achternaam</strong></label>
  <div>
    {{currentUser.lastName}}
  </div>
  <label><strong>Account vervaldatum</strong></label>
  <div>
    {{currentUser.accountExpiryDate | date:'shortDate'}}
  </div>
  <label><strong>Betalingsoverzicht</strong></label>
  <div>
    <table class="table">
      <thead>
        <th>Datum</th>
        <th>Factuurnummer</th>
        <th class="text-right">Acties</th>
      </thead>
      <tbody>
        <tr *ngFor="let factuur of accountFacturen$ | async">
          <td>{{factuur.datum | date}}</td>
          <td>{{factuur.factuurNummer}}</td>
          <td class="text-right">
            <button type="button" class="btn btn-primary" (click)="downloadFactuur(factuur)">Download</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
  <div class="col-md-6">
  <div *ngIf="currentUser.role === 'Ondernemer'">
    <label><strong>Bedrijfsgegevens</strong></label>
    <div>
      <form name="bedrijfsGegevensForm" #bedrijfsGegevensForm="ngForm">
      <div>
        <img src={{currentUser.bedrijf?.logo}} alt="" id="img">
      </div>
      Logo (aangeraden resolutie 350x90, formaat JPG):
      <input name="logoFile" class="form-control form-control-sm" type="file" (change)="onUploadChange($event)" accept=".jpg, .jpeg" />
      Naam: <input name="bedrijfsNaam" class="form-control form-control-sm" [ngModel]="currentUser.bedrijf?.naam" (ngModelChange)="currentUser.bedrijf.naam = $event">
      Straat + Nr: <input name="straatNr" class="form-control form-control-sm" [ngModel]="currentUser.bedrijf?.straatNr" (ngModelChange)="currentUser.bedrijf.straatNr = $event">
      Postcode + Stad: <input name="postCodeStad" class="form-control form-control-sm" [ngModel]="currentUser.bedrijf?.postCodeStad" (ngModelChange)="currentUser.bedrijf.postCodeStad = $event">
      BTW nr.:  <input name="btwNr" class="form-control form-control-sm" [ngModel]="currentUser.bedrijf?.btwNr" (ngModelChange)="currentUser.bedrijf.btwNr = $event"
                  appBTW #inputBtwNr="ngModel">
                  <div class="error" *ngIf="inputBtwNr.invalid">Btw nummer is niet correct <i class="fa fa-info-circle" ngbTooltip="Een Btw nummer bestaat uit een landcode en nummer, bijvoorbeeld: BE 0000 000 000"></i></div>
      IBAN:  <input name="iban" class="form-control form-control-sm" [ngModel]="currentUser.bedrijf?.IBAN" (ngModelChange)="currentUser.bedrijf.IBAN = $event"
                  appIBAN #inputIBAN="ngModel">
                  <div class="error" *ngIf="inputIBAN.invalid">IBAN is niet correct</div>
      BIC:  <input name="bic" class="form-control form-control-sm" [ngModel]="currentUser.bedrijf?.BIC" (ngModelChange)="currentUser.bedrijf.BIC = $event"
                  appBIC #inputBIC="ngModel">
                  <div class="error" *ngIf="inputBIC.invalid">BIC is niet correct</div>
      Telefoon:  <input name="telefoon" class="form-control form-control-sm" [ngModel]="currentUser.bedrijf?.Telefoon" (ngModelChange)="currentUser.bedrijf.Telefoon = $event">
      RPR:  <input name="rpr" class="form-control form-control-sm" [ngModel]="currentUser.bedrijf?.RPR" (ngModelChange)="currentUser.bedrijf.RPR = $event">
      </form>
    </div>
    <button class="btn btn-primary mt-2" (click)="saveBedrijf()" [disabled]="bedrijfsGegevensForm.invalid">Bedrijfsgegevens opslaan</button>
  </div>
  <div *ngIf="currentUser.role === 'Leerkracht' || currentUser.role === 'Student'">
  <label><strong>Leerkracht code</strong></label>
  <p *ngIf="currentUser.role === 'Leerkracht'">
    Deel deze code met uw leerlingen zodat u boekingen van uw leerlingen kan bekijken.
  </p>
  <div class="row">
  <div class="col-sm-10">
      <input class="form-control" [(ngModel)]="currentUser.leerkrachtCode" [disabled]="codeDisabled">
  </div>
  <div class="col-sm-2" *ngIf="codeDisabled && currentUser.role === 'Student'">
      <button class="btn btn-primary" (click)="changeCode()">Wijzig code</button>
  </div>
  <div class="col-sm-2" *ngIf="!codeDisabled">
      <button class="btn btn-success" (click)="submitCode()"><i class="fa fa-check" style="color:white;"></i> Bevestig code</button>
    </div>
  </div>
  </div>



  <div *ngIf="currentUser.role === 'Boekhouder' || currentUser.role === 'Ondernemer'">
    <label><strong>Boekhouder code</strong></label>
    <p *ngIf="currentUser.role === 'Boekhouder'">
      Deel deze code met uw klanten zodat u boekingen van uw klanten kan bekijken.
    </p>
    <div class="row">
    <div class="col-sm-10">
        <input class="form-control" [(ngModel)]="currentUser.boekhouderCode" [disabled]="codeDisabled">
    </div>
    <div class="col-sm-2" *ngIf="codeDisabled && currentUser.role === 'Ondernemer'">
        <button class="btn btn-primary" (click)="changeCode()">Wijzig code</button>
    </div>
    <div class="col-sm-2" *ngIf="!codeDisabled">
        <button class="btn btn-success" (click)="submitCode()"><i class="fa fa-check" style="color:white;"></i> Bevestig code</button>
      </div>
    </div>
  </div>
</div>
</div>
</div>

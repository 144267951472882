<div class="container">
  <h1 class="mt-5">Btw</h1>
  <div class="row mt-4">
  <ng-container *ngIf="isLeerkracht() || currentUser.role === 'Boekhouder'">
    <div class="col-12 col-md-2 col-xl-1">
      <label class="col-form-label">
        <span *ngIf="isLeerkracht()">Student</span>
        <span *ngIf="currentUser.role === 'Boekhouder'">Klant</span>
      </label>
    </div>
    <ng-container *ngIf="isLeerkracht()">
      <div class="col-12 col-md-10 col-xl-3">
        <select [compareWith]="compareByID" class="form-control" name="student" [(ngModel)]="currentStudent" (ngModelChange)="filterByStudent()">
          <option [value]="'--Eigen Boekingen--'" selected>--Eigen Boekingen--</option>
          <option *ngFor="let student of this.currentUser.studenten" [ngValue]="student">{{student.firstName}} {{student.lastName}}</option>
        </select>
      </div>
    </ng-container>
    <ng-container *ngIf="currentUser.role === 'Boekhouder'">
      <div class="col-12 col-md-10 col-xl-3">
        <select [compareWith]="compareByID" class="form-control" name="klant" [(ngModel)]="currentKlant" (ngModelChange)="filterByKlant()">
          <option *ngFor="let klant of this.currentUser.klanten" [ngValue]="klant">{{klant.firstName}} {{klant.lastName}}</option>
        </select>
      </div>
    </ng-container>
  </ng-container>

      <div class="col-12 col-md-2 mt-md-2 col-xl-1 mt-xl-0">
          <label class="col-form-label">Jaar</label>
          </div>
      <div class="col-12 col-md-10 mt-md-2 col-xl-3 mt-xl-0">
          <select id="year" name="year" [(ngModel)]="selectedYear" class="form-control" (change)="changeYear()">
            <option [value]="year" *ngFor="let year of years">{{year}}</option>
          </select>
          </div>
    <div class="col-12 col-md-2 mt-md-2 col-xl-1 mt-xl-0">
      <label class="col-form-label">Kwartaal</label>
    </div>
    <div class="col-12 col-md-10 mt-md-2 col-xl-3 mt-xl-0">
      <select class="form-control" [(ngModel)]="kwartaal" (ngModelChange)="changeKwartaal(true)">
        <option *ngFor="let kwartaal of kwartalen" [ngValue]="kwartaal">{{kwartaal.startDatum.toLocaleDateString()}} t/m {{kwartaal.eindDatum.toLocaleDateString()}}</option>
      </select>
    </div>

  </div>

  <div class="table-responsive">
  <table class="table mt-5">
    <thead>
      <th colspan="8">Kader II <small>Verkopen</small></th>
    </thead>
    <tbody>
      <tr>
        <td style="min-width:125px;">0% <i><strong>(00)</strong></i></td>
        <td style="min-width:125px;">6% <i><strong>(01)</strong></i></td>
        <td style="min-width:125px;">12% <i><strong>(02)</strong></i></td>
        <td style="min-width:125px;">21% <i><strong>(03)</strong></i></td>
        <td style="min-width:125px;">IC <i><strong>(44)</strong></i></td>
        <td style="min-width:125px;">Vrijgestelde IC <i><strong>(46)</strong></i></td>
        <td style="min-width:125px;">IC Creditnota's <i><strong>(48)</strong></i></td>
        <td style="min-width:125px;">Uitgaande creditnota's <i><strong>(49)</strong></i></td>
      </tr>
      <tr>
        <td>{{verkopen0pct |currencyFormat}}</td>
        <td>{{verkopen6pct |currencyFormat}}</td>
        <td>{{verkopen12pct | currencyFormat}}</td>
        <td>{{verkopen21pct | currencyFormat}}</td>
        <td>{{icVerkopen | currencyFormat}}</td>
        <td>{{vrijgesteldeIcVerkopen | currencyFormat}}</td>
        <td>{{icCreditNotas | currencyFormat}}</td>
        <td>{{uitgCreditNotasTotaal | currencyFormat}}</td>
      </tr>
    </tbody>
  </table>
</div>

<div class="table-responsive">
  <table class="table mt-5">
      <thead>
        <th colspan="8">Kader III <small>Aankopen
            <span class="pull-right">
                <span class="badge badge-primary">H</span> Handelsgoederen
                <span class="badge badge-info">D</span> Diensten en diverse goederen
                <span class="badge badge-warning">I</span> Investeringen
              </span>
        </small>
      </th>
      </thead>
      <tbody>
          <tr>
              <td style="min-width:125px;"><span class="badge badge-primary">H</span><i><strong> (81)</strong></i></td>
              <td style="min-width:125px;"><span class="badge badge-info">D</span><i><strong> (82)</strong></i></td>
              <td style="min-width:125px;"><span class="badge badge-warning">I </span><i><strong> (83)</strong></i></td>
              <td style="min-width:125px;">IC creditnota's <i><strong> (84)</strong></i></td>
              <td style="min-width:125px;">Ontvangen creditnota's <i><strong> (85)</strong></i></td>
              <td style="min-width:125px;">IC Levering en Driehoeksverkeer <i><strong> (86)</strong></i></td>
              <td style="min-width:125px;">IC Diensten <i><strong> (88)</strong></i></td>
            </tr>
            <tr>
              <td>{{aankopenH | currencyFormat}}</td>
              <td>{{aankopenD | currencyFormat}}</td>
              <td>{{aankopenI | currencyFormat}}</td>
              <td>{{icCreditNotas84 | currencyFormat}}</td>
              <td>{{ontvCreditNotasTotaal | currencyFormat}}</td>
              <td>{{aankopen86 | currencyFormat}}</td>
              <td>{{aankopen88 | currencyFormat}}</td>
            </tr>
      </tbody>
    </table>
  </div>

  <div class="table-responsive">
    <table class="table mt-5">
        <thead>
          <th colspan="6">Kader IV <small>Verschuldigde Btw</small></th>
        </thead>
        <tbody>
          <tr>
            <td style="min-width:125px;">Totaal Btw verkopen <i><strong>(54)</strong></i></td>
            <td style="min-width:125px;">{{verkopenBTW | currencyFormat}}</td>
            <td style="min-width:125px;">Btw IC aankopen<strong>(55)</strong></td>
            <td style="min-width:125px;">{{btwICAankopen | currencyFormat}}</td>
            <td style="min-width:125px;">Terug te storten Btw <i><strong>(63)</strong></i></td>
            <td style="min-width:125px;">{{terugTeStortenBtw | currencyFormat}}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="table-responsive">
      <table class="table mt-5">
          <thead>
            <th colspan="5">Kader V <small>Aftrekbare Btw</small></th>
          </thead>
          <tbody>
            <tr>
              <td style="min-width:125px;">Totaal aftrekbare Btw <i><strong>(59)</strong></i></td>
              <td style="min-width:125px;">{{totaalAftrekbareBtw | currencyFormat}}</td>
              <td style="min-width:125px;">Terug te vragen Btw <i><strong>(64)</strong></i></td>
              <td style="min-width:125px;">{{terugTeVragenBtw | currencyFormat}}</td>
            </tr>
          </tbody>
        </table>
      </div>
</div>

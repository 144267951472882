<div class="row mb-4">
<ng-container *ngIf="isLeerkracht() || currentUser.role === 'Boekhouder'">
  <div class="col-12 col-md-2 col-xl-1 mb-md-2 pr-md-0">
    <label class="col-form-label">
      {{isLeerkracht() ? 'Student' : 'Klant'}}
    </label>
  </div>
  <div class="col-12 col-md-10 col-xl-3">
    <ng-container *ngIf="isLeerkracht()">
      <select [compareWith]="compareByID" class="form-control" name="student" [(ngModel)]="currentStudent" (ngModelChange)="filterByStudent(null, null, true)">
        <option [value]="'--Eigen Boekingen--'">--Eigen Boekingen--</option>
        <option [value]="'Alle Studenten'">Alle Studenten</option>
        <option *ngFor="let student of this.currentUser.studenten" [ngValue]="student">{{student?.firstName}} {{student?.lastName}}</option>
      </select>
    </ng-container>
    <ng-container *ngIf="currentUser.role === 'Boekhouder'">
      <select [compareWith]="compareByID" class="form-control" name="student" [(ngModel)]="currentKlant" (ngModelChange)="filterByKlant(null, true)">
        <option *ngFor="let klant of this.currentUser.klanten" [ngValue]="klant">{{klant?.firstName}} {{klant?.lastName}}</option>
      </select>
    </ng-container>
  </div>
</ng-container>

  <div class="col-12 col-md-1 col-xl-1 pr-md-0" [class.text-xl-right]="isLeerkracht()">
      <label class="col-form-label">Jaar: </label>
  </div>
  <div class="col-12 col-md-3 col-xl-2">
  <select id="year" name="year" [disabled]="disableJaar" [(ngModel)]="selectedYear" class="form-control" (change)="changeYear(null, true)">
    <option [value]="year" *ngFor="let year of years">{{year}}</option>
  </select>
  </div>
  <ng-container *ngIf="showKwartaalFilter">
    <div class="col-12 col-md-2 col-xl-2 text-xl-right p-md-0">
    <input type="checkbox" [checked]="filterByKwartaal" name="filterByKwartaal" (click)="changeFiltering()">
    <label class="col-form-label ml-1">Kwartaal:</label>
    </div>

    <div class="col-12 col-md-6 col-xl-3 pr-md-0">
      <select class="form-control" [(ngModel)]="kwartaal" (change)="changeKwartaal(null, true)" [disabled]="!filterByKwartaal">
        <option *ngFor="let kwartaal of kwartalen" [ngValue]="kwartaal">{{kwartaal.startDatum.toLocaleDateString()}} t/m {{kwartaal.eindDatum.toLocaleDateString()}}</option>
      </select>
    </div>
  </ng-container>
</div>

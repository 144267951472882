import { OntvCreditnotaService } from './../_boeken/ontv-creditnota/ontv-creditnota.service';
import { UitgCreditnotaService } from './../_boeken/uitg-creditnota/uitg-creditnota.service';
import { AankoopService } from './../_boeken/aankoop/aankoop.service';
import { VerkoopService } from './../_boeken/verkoop/verkoop.service';
import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-btw-overview',
  templateUrl: './btw-overview.component.html'
})
export class BtwOverviewComponent implements OnInit {
  kwartalen;
  kwartaal;
  verkopen;
  filteredVerkopen;
  verkopen0pct;
  verkopen6pct;
  verkopen12pct;
  verkopen21pct;
  verkopenBTW;

  aankopen;
  filteredAankopen;
  aankopenH;
  aankopenD;
  aankopenI;
  aankopen86; //levering + driehoeksverkeer
  aankopen88; //diensten

  uitgCreditNotas;
  filteredUitgCreditNotas;
  uitgCreditNotasTotaal;

  ontvCreditNotas;
  icCreditNotas84;
  btwICAankopen;
  filteredOntvCreditNotas;
  ontvCreditNotasTotaal;

  icVerkopen;
  vrijgesteldeIcVerkopen;
  icCreditNotas;

  terugTeStortenBtw;
  totaalAftrekbareBtw;
  terugTeVragenBtw;

  years;
  selectedYear: string;

  currentUser;
  currentKlant;
  currentStudent;

  enabled;

  constructor(protected verkopenService: VerkoopService,
              protected aankopenService: AankoopService,
              protected uitgCreditNotasService: UitgCreditnotaService,
              protected ontvCreditNotaService: OntvCreditnotaService,
              private router: Router) {
                this.years = [2025,2024,2023,2022,2021,2020,2019,2018,2017,2016,2015]
                this.selectedYear = this.years.find(year => year === new Date().getFullYear());
                if(localStorage.getItem('jaarFilter')) {
                  this.selectedYear = localStorage.getItem('jaarFilter');
                }

                this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
                this.enabled = this.router.parseUrl(this.router.url).queryParams['extraFeatures']
   }

  ngOnInit() {
    this.currentStudent = localStorage.getItem('studentFilter')
    ? JSON.parse(localStorage.getItem('studentFilter')) :  (this.isLeerkracht() ? '--Eigen Boekingen--' : null);

    this.currentKlant = localStorage.getItem('klantFilter')
    ? JSON.parse(localStorage.getItem('klantFilter')) :  null;

    this.changeYear();
  }

  isLeerkracht() {
    return this.currentUser.role === 'Leerkracht';
  }

  compareByID(itemOne, itemTwo) {
    return itemOne && itemTwo && itemOne._id === itemTwo._id;
}

  generateKwartalen(){
    this.kwartalen = [{startDatum: new Date('01/01/' + this.selectedYear), eindDatum: new Date('03/31/' + this.selectedYear)},
                 {startDatum: new Date('04/01/' + this.selectedYear), eindDatum: new Date('06/30/' + this.selectedYear)},
                 {startDatum:new Date('07/01/' + this.selectedYear), eindDatum: new Date('09/30/' + this.selectedYear)},
                 {startDatum:new Date('10/01/' + this.selectedYear), eindDatum: new Date('12/31/' + this.selectedYear)}]

    this.kwartaal = this.kwartalen[0];

    this.setKwartaalFromStoredFilter();
  }

  setKwartaalFromStoredFilter() {
    const kwartaalFilter = localStorage.getItem('kwartaalFilter');
    if (parseInt(kwartaalFilter, 0) >= 0) {
      const _kwartaal = this.kwartalen[parseInt(kwartaalFilter, 0)];
      this.kwartaal = _kwartaal;
    }
  }

  changeYear() {
    this.aankopenOriginal = null;
    this.verkopenOriginal = null;
    this.uitgCreditNotasOriginal = null;
    this.ontvCreditNotasOriginal = null;
    this.generateKwartalen();
    this.getVerkopen();
    this.getAankopen();
    this.getUitgCreditNotas();
    this.getOntvCreditNotas();
  }

  getVerkopen() {
    const verkopenSource$ = this.isLeerkracht() ? this.verkopenService.getStudentenVerkopen(this.currentUser) :
    (this.currentUser.role === 'Boekhouder' ? this.verkopenService.getKlantenVerkopen(this.currentUser) :
    this.verkopenService.getAllVerkopen(this.currentUser))

      verkopenSource$
      .pipe(take(1))
      .subscribe(verkopen => {
        this.verkopen = verkopen;
        this.filteredVerkopen = verkopen;
        this.verkopenOriginal = verkopen;
        if (this.isLeerkracht()) {
          return this.filterByStudent();
        }
        if (this.currentUser.role === 'Boekhouder') {
          return this.filterByKlant();
        } else {
          return this.changeKwartaal(null);
        }
      });
  }

  getAankopen() {
    const aankopenSource$ = this.isLeerkracht() ? this.aankopenService.getStudentenAankopen(this.currentUser) :
    (this.currentUser.role === 'Boekhouder' ? this.aankopenService.getKlantenAankopen(this.currentUser) :
    this.aankopenService.getAllAankopen(this.currentUser));

    aankopenSource$
      .pipe(take(1))
      .subscribe(aankopen => {
        this.aankopen = aankopen;
        this.filteredAankopen = aankopen;
        this.aankopenOriginal = aankopen;
        if (this.isLeerkracht()) {
          return this.filterByStudent();
        }
        if (this.currentUser.role === 'Boekhouder') {
          return this.filterByKlant();
        } else {
          return this.changeKwartaal(null);
        }
      });
  }

  getUitgCreditNotas(){
    const uitgCreditNotaSource$ = this.isLeerkracht() ? this.uitgCreditNotasService.getStudentenUitgCreditNotas(this.currentUser) :
    (this.currentUser.role === 'Boekhouder' ? this.uitgCreditNotasService.getKlantenUitgCreditNotas(this.currentUser) :
    this.uitgCreditNotasService.getAllUitgCreditNotas(this.currentUser));

      uitgCreditNotaSource$
      .pipe(take(1))
      .subscribe(uitgCreditNotas =>  {
          this.uitgCreditNotas = uitgCreditNotas;
          this.filteredUitgCreditNotas = uitgCreditNotas;
          this.uitgCreditNotasOriginal = uitgCreditNotas;
          if (this.isLeerkracht()) {
            return this.filterByStudent();
          }
          if (this.currentUser.role === 'Boekhouder') {
            return this.filterByKlant();
          } else {
            return this.changeKwartaal(null);
          }
        });
  }

  getOntvCreditNotas(){
    const ontvCreditNotaSource$ = this.isLeerkracht() ? this.ontvCreditNotaService.getStudentenOntvCreditNotas(this.currentUser) :
    (this.currentUser.role === 'Boekhouder' ? this.ontvCreditNotaService.getKlantenOntvCreditNotas(this.currentUser) :
    this.ontvCreditNotaService.getAllOntvCreditNotas(this.currentUser))

      ontvCreditNotaSource$
      .pipe(take(1))
      .subscribe(ontvCreditNotas =>  {
          this.ontvCreditNotas = ontvCreditNotas;
          this.filteredOntvCreditNotas = ontvCreditNotas;
          this.ontvCreditNotasOriginal = ontvCreditNotas;
          if (this.isLeerkracht()) {
            return this.filterByStudent();
          }
          if (this.currentUser.role === 'Boekhouder') {
            return this.filterByKlant();
          } else {
            return this.changeKwartaal(null);
          }
        });
  }

  aankopenOriginal;
  verkopenOriginal;
  uitgCreditNotasOriginal;
  ontvCreditNotasOriginal;

  filterByStudent(){
    localStorage.setItem('studentFilter', JSON.stringify(this.currentStudent))

    if(!this.aankopenOriginal){
      this.aankopenOriginal = Object.assign([], this.aankopen);
    }
    this.filteredAankopen = this.aankopenOriginal.filter(aankoop => {
      return aankoop.user.leerkrachtCode === this.currentStudent.leerkrachtCode && aankoop.user._id === this.currentStudent._id;
    })
    this.aankopen = this.filteredAankopen;

    if(!this.verkopenOriginal){
      this.verkopenOriginal = Object.assign([], this.verkopen);
    }
    this.filteredVerkopen = this.verkopenOriginal.filter(verkoop => {
      return verkoop.user.leerkrachtCode === this.currentStudent.leerkrachtCode && verkoop.user._id === this.currentStudent._id;
    })
    this.verkopen = this.filteredVerkopen;

    if(!this.uitgCreditNotasOriginal){
      this.uitgCreditNotasOriginal = Object.assign([], this.uitgCreditNotas);
    }
    this.filteredUitgCreditNotas = this.uitgCreditNotasOriginal.filter(uitgCreditNota => {
      return uitgCreditNota.user.leerkrachtCode === this.currentStudent.leerkrachtCode && uitgCreditNota.user._id === this.currentStudent._id;
    })
    this.uitgCreditNotas = this.filteredUitgCreditNotas;

    if(!this.ontvCreditNotasOriginal){
      this.ontvCreditNotasOriginal = Object.assign([], this.ontvCreditNotas);
    }
    this.filteredOntvCreditNotas = this.ontvCreditNotasOriginal.filter(ontvCreditNota => {
       return ontvCreditNota.user.leerkrachtCode === this.currentStudent.leerkrachtCode && ontvCreditNota.user._id === this.currentStudent._id;
    });
    this.ontvCreditNotas = this.filteredOntvCreditNotas;

    if(this.currentStudent === '--Eigen Boekingen--'){
      this.filteredOntvCreditNotas = this.ontvCreditNotasOriginal.filter(ontvCreditNota => {
        return ontvCreditNota.user._id === this.currentUser._id;
      })
      this.ontvCreditNotas = this.filteredOntvCreditNotas;
      this.filteredUitgCreditNotas = this.uitgCreditNotasOriginal.filter(uitgCreditNota => {
        return uitgCreditNota.user._id === this.currentUser._id;
      })
      this.uitgCreditNotas = this.filteredUitgCreditNotas;
      this.filteredAankopen = this.aankopenOriginal.filter(aankoop => {
        return aankoop.user._id === this.currentUser._id;
      })
      this.aankopen = this.filteredAankopen;
      this.filteredVerkopen = this.verkopenOriginal.filter(verkoop => {
        return verkoop.user._id === this.currentUser._id;
      })
      this.verkopen = this.filteredVerkopen;
    }

    this.changeKwartaal(null);
  }

  filterByKlant(){
    localStorage.setItem('klantFilter', JSON.stringify(this.currentKlant))

    if(!this.aankopenOriginal){
      this.aankopenOriginal = Object.assign([], this.aankopen);
    }
    this.filteredAankopen = this.aankopenOriginal.filter(aankoop => {
      return aankoop.user.boekhouderCode === this.currentKlant.boekhouderCode && aankoop.user._id === this.currentKlant._id;
    })
    this.aankopen = this.filteredAankopen;

    if(!this.verkopenOriginal){
      this.verkopenOriginal = Object.assign([], this.verkopen);
    }
    this.filteredVerkopen = this.verkopenOriginal.filter(verkoop => {
      return verkoop.user.boekhouderCode === this.currentKlant.boekhouderCode && verkoop.user._id === this.currentKlant._id;
    })
    this.verkopen = this.filteredVerkopen;

    if(!this.uitgCreditNotasOriginal){
      this.uitgCreditNotasOriginal = Object.assign([], this.uitgCreditNotas);
    }
    this.filteredUitgCreditNotas = this.uitgCreditNotasOriginal.filter(uitgCreditNota => {
      return uitgCreditNota.user.boekhouderCode === this.currentKlant.boekhouderCode && uitgCreditNota.user._id === this.currentKlant._id;
    })
    this.uitgCreditNotas = this.filteredUitgCreditNotas;

    if(!this.ontvCreditNotasOriginal){
      this.ontvCreditNotasOriginal = Object.assign([], this.ontvCreditNotas);
    }
    this.filteredOntvCreditNotas = this.ontvCreditNotasOriginal.filter(ontvCreditNota => {
       return ontvCreditNota.user.boekhouderCode === this.currentKlant.boekhouderCode && ontvCreditNota.user._id === this.currentKlant._id;
    });
    this.ontvCreditNotas = this.filteredOntvCreditNotas;

    this.changeKwartaal(null);
  }

  changeKwartaal(fromSelect) {

    if (fromSelect) {
      if (this.currentStudent) {
        this.filterByStudent();
      }
      if (this.currentKlant) {
        this.filterByKlant();
      }
      localStorage.setItem('kwartaalFilter', this.kwartalen.indexOf(this.kwartaal));
    }

    let startDatum = this.kwartaal.startDatum;
    let eindDatum = this.kwartaal.eindDatum;

    if(this.verkopen !== null && this.verkopen !== undefined){
    let verkopen = this.verkopen.filter(verkoop => {
      let verkoopDatum = new Date(verkoop.datum);
      verkoopDatum.setHours(0,0,0,0);
      return verkoopDatum >= startDatum && verkoopDatum <= eindDatum;
    });
    this.filteredVerkopen = verkopen;
    this.verkopen0pct = this.getTotaalVerkopen(verkopen, 0);
    this.verkopen6pct = this.getTotaalVerkopen(verkopen, 6);
    this.verkopen12pct = this.getTotaalVerkopen(verkopen, 12);
    this.verkopen21pct = this.getTotaalVerkopen(verkopen, 21);
    this.verkopenBTW = this.getTotaalBTWVerkopen(verkopen);
    this.icVerkopen = this.getTotaalIC(verkopen);
    this.vrijgesteldeIcVerkopen = this.getTotaalVrijgesteldeIC(verkopen);
    }

    if(this.aankopen !== null && this.aankopen !== undefined){
    let aankopen = this.aankopen.filter(aankoop => {
      let aankoopDatum = new Date(aankoop.datum);
      aankoopDatum.setHours(0,0,0,0);
      return aankoopDatum >= startDatum && aankoopDatum <= eindDatum && aankoop.BTW !== -1;
    })
    this.filteredAankopen = aankopen;
    this.aankopenH = this.getTotaalAankopen(this.filteredAankopen, 'Handelsgoederen, grond- en hulpstoffen')
    this.aankopenD = this.getTotaalAankopen(this.filteredAankopen, 'Diensten en diverse goederen');
    this.aankopenI = this.getTotaalAankopen(this.filteredAankopen, 'Investeringen');
    this.aankopen86 = this.getTotaalAankopen86(this.filteredAankopen);
    this.aankopen88 = this.getTotaalAankopen88(this.filteredAankopen);
    this.btwICAankopen = this.getBtwICAankopen(this.filteredAankopen);


    this.totaalAftrekbareBtw = this.getTotaalAftrekbareBtw(this.filteredAankopen);
    }

    if(this.uitgCreditNotas !== null && this.uitgCreditNotas !== undefined){
      let uitgCreditNotas = this.uitgCreditNotas.filter(uitgCreditNota => {
        let uitgCreditNotaDatum = new Date(uitgCreditNota.datum);
        uitgCreditNotaDatum.setHours(0,0,0,0);
        return uitgCreditNotaDatum >= startDatum && uitgCreditNotaDatum <= eindDatum;
      })
      this.filteredUitgCreditNotas = uitgCreditNotas;
      this.uitgCreditNotasTotaal = this.getTotaalUitgCreditNotas(this.filteredUitgCreditNotas);
      this.icCreditNotas = this.getTotaalICCreditNotas(this.filteredUitgCreditNotas)
      this.terugTeVragenBtw = this.getTerugTeVragenBtw(this.filteredUitgCreditNotas);
    }

    if(this.ontvCreditNotas !== null && this.ontvCreditNotas !== undefined){
      let ontvCreditNotas = this.ontvCreditNotas.filter(ontvCreditNota => {
        let ontvCreditNotaDatum = new Date(ontvCreditNota.datum);
        ontvCreditNotaDatum.setHours(0,0,0,0);
        return ontvCreditNotaDatum >= startDatum && ontvCreditNotaDatum <= eindDatum;
      })
      this.filteredOntvCreditNotas = ontvCreditNotas;
      this.ontvCreditNotasTotaal = this.getTotaalOntvCreditNotas(this.filteredOntvCreditNotas);


      this.icCreditNotas84 = this.getTotaalOntvCreditNotas84(this.filteredOntvCreditNotas);
      this.terugTeStortenBtw = this.getTerugTeStortenBtw(this.filteredOntvCreditNotas);

      let ontvCreditNotasH = this.filteredOntvCreditNotas.filter(ontvCreditNota => ontvCreditNota.type === 'Handelsgoederen, grond- en hulpstoffen')
      let ontvCreditNotasD = this.filteredOntvCreditNotas.filter(ontvCreditNota => ontvCreditNota.type === 'Diensten en diverse goederen')
      let ontvCreditNotasI = this.filteredOntvCreditNotas.filter(ontvCreditNota => ontvCreditNota.type === 'Investeringen')
      ontvCreditNotasH.forEach(ontvCreditNotaH => this.aankopenH = parseFloat(this.aankopenH) - parseFloat(ontvCreditNotaH.bedragExclBTW));
      ontvCreditNotasD.forEach(ontvCreditNotaD => this.aankopenD = parseFloat(this.aankopenD) - parseFloat(ontvCreditNotaD.bedragExclBTW));
      ontvCreditNotasI.forEach(ontvCreditNotaI => this.aankopenI = parseFloat(this.aankopenI) - parseFloat(ontvCreditNotaI.bedragExclBTW));
    }


  }

  getTotaalUitgCreditNotas(uitgCreditNotas){
    let _uitgCreditNotas = [];
    let totaal = 0;
    _uitgCreditNotas = uitgCreditNotas.filter(creditNota => !creditNota.intracommunautair);
    _uitgCreditNotas.forEach(creditNota => totaal = totaal + parseFloat(creditNota.bedragExclBTW));
    if(_uitgCreditNotas.length === 0) {
      return 0;
    } else {
      return totaal;
    }
  }

  getTotaalICCreditNotas(uitgCreditNotas) {
    let _uitgCreditNotas = [];
    let totaal = 0;
    _uitgCreditNotas = uitgCreditNotas.filter(creditNota => creditNota.intracommunautair);
    _uitgCreditNotas.forEach(creditNota => totaal = totaal + parseFloat(creditNota.bedragExclBTW));
    if(_uitgCreditNotas.length === 0) {
      return 0;
    } else {
      return totaal;
    }
  }


  getTotaalOntvCreditNotas(ontvCreditNotas){
    let _ontvCreditNotas = [];
    let totaal = 0;
    _ontvCreditNotas = ontvCreditNotas.filter(ontvCreditNota => !ontvCreditNota.intracommunautair);
    _ontvCreditNotas.forEach(creditNota => totaal = totaal + parseFloat(creditNota.bedragExclBTW));
    if(_ontvCreditNotas.length === 0) {
      return 0;
    } else {
      return totaal;
    }
  }

  getTotaalAankopen86(aankopen) {
    let _aankopen = [];
    let totaal = 0;
    _aankopen = aankopen.filter(aankoop => aankoop.intracommunautair && (aankoop.icType === 'Levering' || aankoop.icType === 'Driehoeksverkeer'));
    _aankopen.forEach(aankoop => totaal = totaal + parseFloat(aankoop.bedragExclBTW));
    if(_aankopen.length === 0) {
      return 0;
    } else {
      return totaal;
    }
  }

  getTotaalAankopen88(aankopen) {
    let _aankopen = [];
    let totaal = 0;
    _aankopen = aankopen.filter(aankoop => aankoop.intracommunautair && aankoop.icType === 'Diensten');
    _aankopen.forEach(aankoop => totaal = totaal + parseFloat(aankoop.bedragExclBTW));
    if(_aankopen.length === 0) {
      return 0;
    } else {
      return totaal;
    }
  }

  getTotaalOntvCreditNotas84(ontvCreditNotas) {
    let _ontvCreditNotas = [];
    let totaal = 0;
    _ontvCreditNotas = ontvCreditNotas.filter(ontvCreditNota => ontvCreditNota.intracommunautair);
    _ontvCreditNotas.forEach(creditNota => totaal = totaal + parseFloat(creditNota.bedragExclBTW));
    if(_ontvCreditNotas.length === 0) {
      return 0;
    } else {
      return totaal;
    }
  }

  getTotaalVerkopen(verkopen, percentage: number){
    let _verkopen = [];
    _verkopen = verkopen.filter(verkoop => verkoop.BTW === percentage);
    let totaal = 0;
    _verkopen.forEach(verkoop => totaal = totaal + parseFloat(verkoop.bedragExclBTW));
    if(_verkopen.length === 0){
      return 0;
    } else {
      return totaal;
    }
  }

  getTotaalIC(verkopen) {
    let _verkopen = []
    _verkopen = verkopen.filter(verkoop => verkoop.intracommunautair && verkoop.icType === 'Diensten');
    let totaal = 0;
    _verkopen.forEach(verkoop => totaal += parseFloat(verkoop.bedragExclBTW));
    if(_verkopen.length === 0){
      return 0;
    } else {
      return totaal;
    }
  }

  getTotaalVrijgesteldeIC(verkopen) {
    let _verkopen = [];
    _verkopen = verkopen.filter(verkoop => verkoop.intracommunautair && verkoop.BTW === 0 && verkoop.icType && verkoop.icType !== 'Diensten');
    let totaal = 0;
    _verkopen.forEach(verkoop => totaal += parseFloat(verkoop.bedragExclBTW));
    if(_verkopen.length === 0){
      return 0;
    } else {
      return totaal;
    }
  }

  getTotaalAankopen(aankopen, type:string){
    let _aankopen = [];
    _aankopen = aankopen.filter(aankoop => aankoop.type === type);
    let totaal = 0;
    _aankopen.forEach(aankoop => totaal = totaal + parseFloat(aankoop.bedragExclBTW));
    if(_aankopen.length === 0) {
      return 0;
    } else {
      return totaal;
    }
  }

  getTotaalBTWVerkopen(verkopen){
    let _verkopen = [];
    _verkopen = verkopen;
    let totaalBTW = 0;
    _verkopen.forEach(verkoop => totaalBTW = totaalBTW + (parseFloat(verkoop.bedragExclBTW) * (verkoop.BTW / 100)));
    return totaalBTW;
  }

  getTerugTeStortenBtw(ontvCreditNotas){
    let _ontvCreditNotas = [];
    _ontvCreditNotas = ontvCreditNotas;
    let totaal = 0;
    _ontvCreditNotas.forEach(ontvCreditNota => totaal = totaal + (parseFloat(ontvCreditNota.bedragExclBTW) * ontvCreditNota.BTW / 100));
    return totaal;
  }

  getBtwICAankopen(ontvCreditNotas) {
    let _ontvCreditNotas = [];
    _ontvCreditNotas = ontvCreditNotas.filter(ontvCreditNota => ontvCreditNota.intracommunautair);
    let totaal = 0;
    _ontvCreditNotas.forEach(ontvCreditNota => totaal = totaal + (parseFloat(ontvCreditNota.bedragExclBTW) * ontvCreditNota.BTW / 100));
    return totaal;
  }

  getTerugTeVragenBtw(uitgCreditNotas) {
    let _uitgCreditNotas = [];
    _uitgCreditNotas = uitgCreditNotas;
    let totaal = 0;
    _uitgCreditNotas.forEach(uitgCreditNota => totaal = totaal + (parseFloat(uitgCreditNota.bedragExclBTW) * uitgCreditNota.BTW / 100));
    return totaal;
  }

  getTotaalAftrekbareBtw(aankopen) {
    let _aankopen = [];
    _aankopen = aankopen;
    let totaal = 0;
    _aankopen.forEach(aankoop => totaal = totaal + (parseFloat(aankoop.bedragExclBTW) * aankoop.BTW / 100));
    return totaal;
  }

}

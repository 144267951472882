<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Inventaris vorig jaar kopiëren</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>
      Wanneer u de inhoud van de inventaris van vorig jaar kopieert zal de eindvoorraad van het vorige jaar ingesteld worden
      als de beginvoorraad van de huidige inventaris.<br/>
      Kijk dus de eindvoorraden van vorig jaar na vooraleer u verder gaat.
    </p>
    <p>
      Wil u verder gaan?
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('Save click')">Nee</button>
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Ja</button>
  </div>
</ng-template>



<div class="container-fluid mt-5">
  <div class="alert alert-info" *ngIf="notFound">Er werd geen inventaris gevonden voor het geselecteerde jaar. <button type="button" class="btn btn-primary" (click)="initInventaris()">Aanmaken</button></div>
  <div class="row mb-2">
    <div class="col-12">
      <h1 class="d-inline">Inventaris</h1>
    </div>
    <div class="col-12">
      <h2 class="d-inline">Producten</h2>
      <div class="float-right">
        <button type="button" class="btn btn-primary" *ngIf="!notFound && !expired" (click)="addProductGroep()">Nieuwe productgroep</button>
        <button type="button" class="btn btn-primary ml-2" (click)="openCopyModal(content)" *ngIf="inventaris?.productGroepen?.length === 0">Vorig jaar kopiëren</button>
      </div>
    </div>
    <div class="col-12">
      <app-table-filter-menu #appTableFilterMenu [showKwartaalFilter]="false"></app-table-filter-menu>
    </div>
  </div>
  <div *ngIf="inventaris">
  <div class="row mb-2">
    <div class="col-12" *ngIf="productGroep">
      <div style="background-color:gray; color:white; border-radius:5px;" class="p-2">
          <form id="groepForm" #groepForm="ngForm">
            <input name="groepNr" required [ngClass]="{'invalid-input': groepForm.controls?.groepNr?.errors?.required}" id="productGroepNummer" placeholder="Groep Nr." class="col-3 d-inline-block form-control form-control-sm" [(ngModel)]="productGroep.groepNummer">
            <input name="groepNaam" required [ngClass]="{'invalid-input': groepForm.controls?.groepNaam?.errors?.required}" id="productGroepNaam" placeholder="Groepsnaam" class="col-3 ml-2 d-inline-block form-control form-control-sm" [(ngModel)]="productGroep.naam">
            <div class="btn-group float-right">
              <button type="button" class="btn btn-sm btn-light" (click)="saveProductGroep()" [disabled]="groepForm.invalid"><i class="fa fa-check"></i></button>
              <button type="button" class="btn btn-sm btn-light" (click)="cancelProductGroep()"><i class="fa fa-remove"></i></button>
            </div>
          </form>
      </div>
    </div>
  </div>
  <div class="row mb-2" *ngFor="let productGroep of productGroepen">
    <div class="col-12">
      <div style="background-color:gray; color:white; border-radius:5px; padding-bottom:12.5px !important;" class="p-2">
        <h5 class="d-inline">{{productGroep.groepNummer}} - {{productGroep.naam}}</h5>
        <div class="float-right">
          <button type="button" class="btn btn-sm btn-light" (click)="addProduct(productGroep)" *ngIf="!expired"><i class="fa fa-plus"></i> Product Toevoegen</button>
          <button type="button" class="ml-2 btn btn-sm btn-light" (click)="deleteProductGroep(productGroep)" *ngIf="!expired"><i class="fa fa-remove"></i> Groep Verwijderen</button>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="row">
        <div class="col"><strong>Artikel Nr.</strong></div>
        <div class="col"><strong>Productnaam</strong></div>
        <div class="col"><strong>Beginvoorraad</strong></div>
        <div class="col"><strong>Huidig Aantal</strong></div>
        <div class="col"><strong>Aankoopprijs (excl. BTW)</strong></div>
        <div class="col"><strong>BTW Percentage</strong></div>
        <div class="col"><strong>Totaal</strong></div>
        <div class="col"><strong>Verkoopprijs (excl. BTW)</strong></div>
        <div class="col"><strong>Eindvoorraad</strong></div>
        <div class="col text-right"><strong>Acties</strong></div>
      </div>
    </div>
    <div class="col-12">
      <form id="newForm" #newForm="ngForm">
      <div class="row" *ngIf="product && productGroep === selectedProductGroep">
        <div class="col">
          <input name="artikelNr" id="productArtikelNr" class="form-control form-control-sm" [(ngModel)]="product.artikelNummer">
        </div>
        <div class="col">
          <input [ngClass]="{'invalid-input': newForm.controls?.productNaam?.errors?.required}" required name="productNaam" id="productNaam" class="form-control form-control-sm" [(ngModel)]="product.naam">
        </div>
        <div class="col">
          <input name="beginVoorraad" required [ngClass]="{'invalid-input': newForm.controls?.beginVoorraad?.errors?.required}" id="beginVoorraad" class="form-control form-control-sm" [(ngModel)]="productInfo.beginVoorraad">
        </div>
        <div class="col">
          <i>(= Beginvoorraad)</i>
          <!-- <input name="productAantal" required [ngClass]="{'invalid-input': newForm.controls?.productAantal?.errors?.required}" id="productAantal" class="form-control form-control-sm" [(ngModel)]="product.aantal"> -->
        </div>
        <div class="col">
          <input name="productPrijs" required [ngClass]="{'invalid-input': newForm.controls?.productPrijs?.errors?.required}" id="productPrijs" class="form-control form-control-sm" [(ngModel)]="productInfo.prijs">
        </div>
        <div class="col">
          <select name="btwPercentage"
          id="btwPercentage"
          class="form-control form-control-sm"
          [(ngModel)]="productInfo.btwPercentage">
            <option [value]="-1">n.v.t</option>
            <option [value]="0">0</option>
            <option [value]="6">6</option>
            <option [value]="12">12</option>
            <option [value]="21">21</option>
          </select>
        </div>
        <div class="col">
          {{productInfo.prijs * productInfo.beginVoorraad | currencyFormat}}
        </div>
        <div class="col">
          <input required [ngClass]="{'invalid-input': newForm.controls?.verkoopPrijs?.errors?.required}" name="verkoopPrijs" required [ngClass]="{'invalid-input': newForm.controls?.verkoopPrijs?.errors?.required}" id="verkoopPrijs" class="form-control form-control-sm" [(ngModel)]="productInfo.verkoopPrijs">
        </div>
        <div class="col">
          <!-- <input name="eindVoorraad" id="eindVoorraad" class="form-control form-control-sm" [(ngModel)]="product.eindVoorraad"> -->
        </div>
        <div class="col text-right">
          <button type="button" class="btn btn-sm btn-light" (click)="saveProduct(productGroep)" [disabled]="newForm.invalid"><i class="fa fa-check"></i></button>
          <button type="button" class="btn btn-sm btn-light" *ngIf="product" (click)="cancel()"><i class="fa fa-remove"></i></button>
        </div>
      </div>
    </form>
    </div>
    <div class="col-12" *ngFor="let product of productGroep.producten | orderBy: 'artikelNummer'; let i = index;">
      <form id="editForm" #editForm="ngForm">
      <div class="row">
        <div class="col">
          <input id="productArtikelNr" name="artikelNr" *ngIf="editEnabled(i, productGroep)" class="form-control form-control-sm" [(ngModel)]="product.artikelNummer">
          <span *ngIf="!isEdit || i !== editIndex || this.editGroep !== productGroep">{{product.artikelNummer}}</span>
        </div>
        <div class="col">
          <input [ngClass]="{'invalid-input': editForm.controls?.productNaam?.errors?.required}" required id="productNaam" name="productNaam" *ngIf="editEnabled(i, productGroep)" class="form-control form-control-sm" [(ngModel)]="product.naam">
          <span *ngIf="!isEdit || i !== editIndex || this.editGroep !== productGroep">{{product.naam}}</span>
        </div>
        <div class="col">
          <input [ngClass]="{'invalid-input': editForm.controls?.beginVoorraad?.errors?.required}" [disabled]="productInfo.beginVoorraad !== null" id="beginVoorraad" name="beginVoorraad" *ngIf="editEnabled(i, productGroep)" class="form-control form-control-sm" [(ngModel)]="productInfo.beginVoorraad" required>
          <span *ngIf="!isEdit || i !== editIndex || this.editGroep !== productGroep">{{jaarProductInfo(product).beginVoorraad}}</span>
        </div>
        <div class="col">
          <input [ngClass]="{'invalid-input': editForm.controls?.productAantal?.errors?.required}" id="productAantal" name="productAantal" *ngIf="editEnabled(i, productGroep)" class="form-control form-control-sm" [(ngModel)]="productInfo.aantal" required>
          <span *ngIf="!isEdit || i !== editIndex || this.editGroep !== productGroep">{{jaarProductInfo(product).aantal}} <button class="btn btn-sm btn-light float-right" (click)="getVoorraadBewegingen(product)"><i class="fa fa-exchange" aria-hidden="true"></i></button></span>
        </div>
        <div class="col">
          <input [ngClass]="{'invalid-input': editForm.controls?.productPrijs?.errors?.required}" id="productPrijs" name="productPrijs" #productPrijs="ngModel" *ngIf="editEnabled(i, productGroep)" class="form-control form-control-sm" [(ngModel)]="productInfo.prijs" required>
          <span *ngIf="!isEdit || i !== editIndex || this.editGroep !== productGroep">{{jaarProductInfo(product).prijs | currencyFormat}}</span>
        </div>
        <div class="col">
          <input id="btwPercentage" name="btwPercentage" *ngIf="editEnabled(i, productGroep)" class="form-control form-control-sm" [(ngModel)]="productInfo.btwPercentage">
          <span *ngIf="!isEdit || i !== editIndex || this.editGroep !== productGroep">{{jaarProductInfo(product).btwPercentage}}</span>
        </div>
        <div class="col">{{jaarProductInfo(product).prijs * jaarProductInfo(product).aantal | currencyFormat}}</div>
        <div class="col">
          <input required [ngClass]="{'invalid-input': editForm.controls?.verkoopPrijs?.errors?.required}" id="verkoopPrijs" name="verkoopPrijs" *ngIf="editEnabled(i, productGroep)" class="form-control form-control-sm" [(ngModel)]="productInfo.verkoopPrijs">
          <span *ngIf="!isEdit || i !== editIndex || this.editGroep !== productGroep">{{jaarProductInfo(product).verkoopPrijs | currencyFormat}}</span>
        </div>
        <div class="col">
          <input id="eindVoorraad" name="eindVoorraad" *ngIf="editEnabled(i, productGroep)" class="form-control form-control-sm" [(ngModel)]="productInfo.eindVoorraad">
          <span *ngIf="!isEdit || i !== editIndex || this.editGroep !== productGroep">{{jaarProductInfo(product).eindVoorraad}}</span>
        </div>
        <div class="col">
          <div class="btn-group float-right">
            <button class="btn btn-sm btn-light" (click)="edit(productGroep, i, product)"><i class="fa fa-pencil"></i></button>
            <button class="btn btn-sm btn-light" (click)="delete(product)"><i class="fa fa-trash"></i></button>
            <button class="btn btn-sm btn-light" *ngIf="editEnabled(i, productGroep)" (click)="saveProduct(product)" [disabled]="editForm.invalid"><i class="fa fa-check"></i></button>
            <button class="btn btn-sm btn-light" *ngIf="editEnabled(i, productGroep)" (click)="cancel()"><i class="fa fa-remove"></i></button>
          </div>
        </div>
      </div>

    </form>
  </div>
</div>





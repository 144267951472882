import { Component, OnInit } from '@angular/core';
import { NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { I18n, CustomDatepickerI18n } from "../../../ngb-date-fr-parser-formatter/ngb-i18n"
import { NgbDatepickerConfig, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateFRParserFormatter } from "../../../ngb-date-fr-parser-formatter/ngb-date-fr-parser-formatter"
import { GeneralComponent } from '../../../general/general.component';
import { OntvCreditnotaService } from '../ontv-creditnota.service';
import { ActivatedRoute } from '@angular/router';
import { Leverancier } from '../../../../models/Leverancier';
import { LeverancierService } from '../../../leverancier/leverancier.service';
import { Location } from '@angular/common';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-ontv-creditnota-edit',
  templateUrl: './ontv-creditnota-edit.component.html',
  providers: [
    I18n,
    {provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter},
    {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}
  ]
})
export class OntvCreditnotaEditComponent extends GeneralComponent implements OnInit {
  ontvCreditNota;
  calculatedBTW: number;
  leveranciers: Leverancier[];
  isEdit = false;
  currentUser;

  constructor(protected ontvCreditNotaService: OntvCreditnotaService,
    protected leverancierService: LeverancierService,
    private location: Location,
    private route: ActivatedRoute) {
      super();
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
     }

  ngOnInit() {
    let param = this.route.snapshot.params['id'];
    if(param){
      this.isEdit = true;
      this.ontvCreditNotaService.getOntvCreditNota(param)
      .pipe(take(1))
      .subscribe(aankoop =>
        {
          this.ontvCreditNota = aankoop;
          this.ontvCreditNota.datum = this.convertInputDate(this.ontvCreditNota.datum);
          this.calculateBTW();
          this.ontvCreditNota.bedragExclBTW = this.ontvCreditNota.bedragExclBTW.toString().replace('.',',');
        });
    }
    this.ontvCreditNota = {
      _id: '',
      datum: null,
      leverancier: new Leverancier("test"),
      type: null,
      bedragExclBTW: 0,
      bedragInclBTW: 0,
      BTW: 0,
      omschrijving: '',
      bijlage: '',
      status: false,
      intracommunautair: false,
      icType: null
    }
    this.leverancierService.getAllLeveranciers(this.currentUser)
    .pipe(take(1))
    .subscribe((leveranciers: Leverancier[]) => this.leveranciers = leveranciers);
  }

  public onSubmit() {
    this.finalizeEdit(this.ontvCreditNota);
    const addOrUpdateOntvCreditNota = this.isNew(this.ontvCreditNota) ?
    this.ontvCreditNotaService.addOntvCreditNota(this.ontvCreditNota) :
    this.ontvCreditNotaService.updateOntvCreditNota(this.ontvCreditNota);

    addOrUpdateOntvCreditNota
    .pipe(take(1))
    .subscribe(
      response => {
        this.location.back();
      },
    );
  }

  navigateAway(){
    this.location.back();
  }

  calculateBTW() {
    let result = this.calcBTW(this.ontvCreditNota.BTW.toString(), this.ontvCreditNota.bedragExclBTW, this.ontvCreditNota.bedragInclBTW)
    this.ontvCreditNota.bedragExclBtw = result[0],
    this.calculatedBTW = result[1],
    this.ontvCreditNota.bedragInclBTW = result[2]
  }

  getLeverancier(leverancierName) {
    let leverancier = this.leveranciers.find(leverancier => leverancier.name === leverancierName);
    this.ontvCreditNota.leverancier = leverancier;
  }



}

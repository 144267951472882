import { Component, OnInit, Output, EventEmitter, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-student-bedrijf-selector',
  templateUrl: './student-bedrijf-selector.component.html',
  providers: [{   // <================================================ ADD THIS
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => StudentBedrijfSelectorComponent),
    multi: true
}]
})
export class StudentBedrijfSelectorComponent implements ControlValueAccessor {
  @Output() onSelectBedrijf = new EventEmitter();

  currentUser;
  selectedBedrijf = null;

  bedrijven = [{logo: 'pakenzak.jpg', naam:'Pak & Zak', straatEnNr:'Proeftuinstraat 28',
  postcodeEnStad:'9000 Gent', btwNr:'BE 0401 089 654',
  IBAN:'BE33 7374 1801 2146', BIC: 'KREDBEBB',
  telefoon: '', RPR: 'Gent'},
  {logo: 'spelenco.jpg', naam:'Spel & Co', straatEnNr:'Regenboog 7',
  postcodeEnStad:'2800 Mechelen', btwNr:'BE 0401 089 654',
  IBAN:'BE33 7374 1801 2146', BIC: 'KREDBEBB',
  telefoon: '015 40 70 44', RPR: 'Antwerpen, afd. Mechelen'}]

  private _value: any;
  // Whatever name for this (myValue) you choose here, use it in the .html file.
  public get myValue(): any { return this._value }
  public set myValue(v: any) {
    if (v !== this._value) {
      this._value = v;
      this.onChange(v);
    }
  }
  constructor() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
  }

  onChange = (_) => { };
  onTouched = () => { };

  writeValue(value: any): void {
    this.myValue = value;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    throw new Error("Method not implemented.");
  }



  ngOnInit(): void {
  }

  selectBedrijf() {
    this.onSelectBedrijf.emit();
  }

}

import { Factuur } from './../../models/Factuur';
import { FacturenService } from './../facturen/facturen.service';
import { AuthenticationService } from './../_services/authentication.service';
import { Component, OnInit, ViewChild, LOCALE_ID } from '@angular/core';
import { User } from '../../models/user';
import { UserService } from '../_services/user.service';
import { AlertService } from '../_services/alert.service';
import { filter, map, take } from 'rxjs/operators';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  providers: [
   {provide: LOCALE_ID, useValue: 'nl-BE'}
  ]
})
export class AccountComponent implements OnInit {
  currentUser: User;
  codeDisabled = true;
  picture: string;
  stripeTest: UntypedFormGroup;

  paymentOption = 'bancontact';

  expired = false;

  accountFacturen$ = new Observable<Object>();

  constructor(protected userService: UserService,
              protected alertService: AlertService,
              private router: Router,
              private fb: UntypedFormBuilder,
              private authService: AuthenticationService,
              private factuurService: FacturenService) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if(this.currentUser.role === 'Ondernemer') {
      this.expired = this.authService.accountExpired;
      this.accountFacturen$ = this.factuurService.getAllFacturen(this.currentUser)
      .pipe(
        map((facturen: Factuur[]) => facturen.filter(factuur => factuur.autoGenerated)),
        take(1)
      )
    }



    if(this.currentUser.role === 'Ondernemer' && !this.currentUser.bedrijf) {
      this.currentUser.bedrijf = {
        naam: null,
        straatNr: null,
        postCodeStad: null,
        btwNr: null,
        IBAN: null,
        BIC: null,
        Telefoon: null,
        RPR: null,
        logo: null
      }
    }
  }


  base64textString = [];

  onUploadChange(evt: any) {
    const file = evt.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = this.handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  }

  handleReaderLoaded(e) {
    this.base64textString.push('data:image/png;base64,' + btoa(e.target.result));
    this.currentUser.bedrijf.logo = this.base64textString[0];
  }


  changeCode() {
    this.codeDisabled = false;
  }

  submitCode() {
    this.userService.update(this.currentUser)
    .pipe(take(1))
    .subscribe(user => this.codeDisabled = true, error => this.alertService.error(error.error));
  }

  saveBedrijf() {
    this.userService.update(this.currentUser)
    .pipe(take(1))
    .subscribe((result: any) => {
      this.currentUser.bedrijf = result.value.bedrijf;
      localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
      this.alertService.success('Nieuwe bedrijfsgegevens opgeslaan');
    }, error => this.alertService.error(error.message));
  }



  ngOnInit(): void {
    this.stripeTest = this.fb.group({
      name: ['', [Validators.required]]
    });
  }

  downloadFactuur(factuur: Factuur) {
    return this.router.navigate(['facturen/edit/' + factuur._id], { queryParams: {'download': true}});
  }
}

import { CurrencyFormatPipe } from './../../_pipes/currency-format.pipe';
import { AuthenticationService } from './../../_services/authentication.service';
import { OntvCreditNota } from './../../../models/Creditnota';
import { Leverancier } from './../../../models/Leverancier';
import { PaginationService } from './../../_services/pagination/pagination.service';
import { LeverancierService } from './../../leverancier/leverancier.service';
import { TableFilterMenuComponent } from './../../shared/table-filter-menu/table-filter-menu.component';
import { PaginatedComponent } from '../../paginated/paginated.component';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { OntvCreditnotaService } from './ontv-creditnota.service';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { OrderPipe } from 'ngx-order-pipe';


@Component({
  selector: 'app-ontv-creditnota',
  templateUrl: './ontv-creditnota.component.html'
})
export class OntvCreditnotaComponent extends PaginatedComponent implements OnInit, AfterViewInit {
  @ViewChild(TableFilterMenuComponent) menuFilter: TableFilterMenuComponent;

  leveranciers: Leverancier[];

  expired = false;
  orderByReversed = {
    datum: false,
    leverancier: false,
    type: false
  }

  constructor(private authService: AuthenticationService,
              private ontvCreditNotasService: OntvCreditnotaService,
              private leveranciersService: LeverancierService,
              protected router: Router,
              protected paginationService: PaginationService,
              currencyFormatPipe: CurrencyFormatPipe,
              orderPipe: OrderPipe
              ) {
                super(paginationService, 'ontvCreditNotas', currencyFormatPipe, orderPipe);
                this.expired = this.authService.accountExpired;
              }


  loadItems() {
    return this.isLeerkracht() ? this.ontvCreditNotasService.getStudentenOntvCreditNotas(this.currentUser) :
    (this.currentUser.role === 'Boekhouder' ? this.ontvCreditNotasService.getKlantenOntvCreditNotas(this.currentUser) :
    this.ontvCreditNotasService.getAllOntvCreditNotas(this.currentUser));
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.leveranciersService.getAllLeveranciers(this.currentUser)
    .pipe(take(1))
    .subscribe((leveranciers: Leverancier[]) =>
    this.leveranciers = leveranciers);
  }

  createOntvCreditNota(){
    this.router.navigate(['/ontvangen-creditnotas/create'])
  }

  editOntvCreditNota(creditNota){
    this.router.navigate(['/ontvangen-creditnotas/edit/', creditNota._id])
  }

  deleteOntvCreditNota(creditNota: OntvCreditNota){
    if (confirm('Bent u zeker dat u deze ontvangen creditnota wil verwijderen?')) {
    this.ontvCreditNotasService.removeOntvCreditNota(creditNota._id.toString())
    .pipe(take(1))
    .subscribe(
      (response: OntvCreditNota[]) => {
        this.refresh$.next(true);
      });
    }
  }
}

import { CurrencyFormatPipe } from './../../_pipes/currency-format.pipe';
import { AuthenticationService } from './../../_services/authentication.service';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { AankoopService } from './aankoop.service';
import { Aankoop } from '../../../models/Aankoop';
import { Router } from '@angular/router';
import { Leverancier } from '../../../models/Leverancier';
import { LeverancierService } from '../../leverancier/leverancier.service';
import { AlertService } from '../../_services/alert.service';
import { PaginationService, PaginatedEntity } from '../../_services/pagination/pagination.service';
import { PaginatedComponent } from '../../paginated/paginated.component';
import { take } from 'rxjs/operators';
import { TableFilterMenuComponent } from '../../shared/table-filter-menu/table-filter-menu.component';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { OrderPipe } from 'ngx-order-pipe';

@Component({
  selector: 'app-aankoop',
  templateUrl: './aankoop.component.html'
})
export class AankoopComponent extends PaginatedComponent implements OnInit, AfterViewInit {
  @ViewChild(TableFilterMenuComponent) menuFilter: TableFilterMenuComponent;

  leveranciers: Leverancier[];

  filteredAankopen: Aankoop[];

  refresh$ = new BehaviorSubject(false);

  expired = false;


  orderByReversed = {
    datum: false,
    leverancier: false,
    status: false,
    type: false
  }

  constructor(private authService: AuthenticationService,
              private aankoopService: AankoopService,
              private leverancierService: LeverancierService,
              private alertService: AlertService,
              private router: Router,
              protected paginationService: PaginationService,
              currencyFormatPipe: CurrencyFormatPipe,
              orderPipe: OrderPipe) {
                super(paginationService, 'aankoop', currencyFormatPipe, orderPipe);
                this.expired = this.authService.accountExpired;
              }


  ngAfterViewInit() {
    super.ngAfterViewInit();

    this.leverancierService.getAllLeveranciers(this.currentUser)
    .pipe(take(1))
    .subscribe((leveranciers: Leverancier[]) => {
      this.leveranciers = leveranciers;
      if (this.leveranciers.length === 0) {
        this.alertService.info('U dient leveranciers aan te maken alvorens u een aankoop kan creëren');
      }
    });
  }

  loadItems() {
    return this.isLeerkracht() ?
    this.aankoopService.getStudentenAankopen(this.currentUser) :
    (this.currentUser.role === 'Boekhouder' ? this.aankoopService.getKlantenAankopen(this.currentUser) :
    this.aankoopService.getAllAankopen(this.currentUser));
  }

  createAankoop() {
    this.router.navigate(['/aankopen/create'])
  }

  editAankoop(aankoop) {
    this.router.navigate(['/aankopen/edit/', aankoop._id])
  }

  deleteAankoop(aankoop: Aankoop){
    if (confirm('Bent u zeker dat u deze aankoop wil verwijderen?')) {
    this.aankoopService.removeAankoop(aankoop._id.toString())
    .pipe(take(1))
    .subscribe(
      (response: Aankoop[]) => {
          this.refresh$.next(true);
      }
    );
    }
  }

  getAttachment(aankoop: Aankoop) {
    return 'assets/uploads/' + this.currentUser.username + '/aankopen/' + aankoop._id + '/' + aankoop.bijlage;
  }

}

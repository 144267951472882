
import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';


@Component({
  selector: 'app-table-filter-menu',
  templateUrl: './table-filter-menu.component.html'
})
export class TableFilterMenuComponent implements OnInit {
  // tslint:disable-next-line: no-input-rename
  @Input('showKwartaalFilter') showKwartaalFilter = true;
  @Input() disableJaar = false;

  currentUser;
  currentStudent;
  currentKlant;
  selectedYear = new Date().getFullYear();
  kwartaal;
  kwartalen;
  filterByKwartaal = false;
  years = [2025,2024,2023,2022,2021, 2020, 2019, 2018, 2017, 2016, 2015];

  jaar$ = new BehaviorSubject<number>(new Date().getFullYear());
  kwartaal$ = new BehaviorSubject(false);
  student$ = new BehaviorSubject(false);

  constructor() {

  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.currentStudent = localStorage.getItem('studentFilter')
    ? JSON.parse(localStorage.getItem('studentFilter')) :  (this.isLeerkracht() ? '--Eigen Boekingen--' : null);

    this.currentKlant = localStorage.getItem('klantFilter')
    ? JSON.parse(localStorage.getItem('klantFilter')) :  null;

    this.setJaarFromStoredFilter();

    this.generateKwartalen();
    this.setKwartaalFromStoredFilter();
  }

  setJaarFromStoredFilter() {
    const jaarFilter = localStorage.getItem('jaarFilter');
    if (jaarFilter) {
      this.selectedYear = parseInt(jaarFilter);
      this.jaar$.next(parseInt(jaarFilter));
    }
  }

  setKwartaalFromStoredFilter() {
    const kwartaalFilter = localStorage.getItem('kwartaalFilter');
    if (parseInt(kwartaalFilter, 0) >= 0) {
      const _kwartaal = this.kwartalen[parseInt(kwartaalFilter, 0)];
      this.kwartaal = _kwartaal;
      this.filterByKwartaal = true;
    }
  }

  compareByID(itemOne, itemTwo) {
    return itemOne && itemTwo && itemOne._id === itemTwo._id;
}

  generateKwartalen(){
    this.kwartalen = [{startDatum: new Date('01/01/' + this.selectedYear), eindDatum: new Date('03/31/' + this.selectedYear)},
                 {startDatum: new Date('04/01/' + this.selectedYear), eindDatum: new Date('06/30/' + this.selectedYear)},
                 {startDatum: new Date('07/01/' + this.selectedYear), eindDatum: new Date('09/30/' + this.selectedYear)},
                 {startDatum: new Date('10/01/' + this.selectedYear), eindDatum: new Date('12/31/' + this.selectedYear)}]

    this.kwartaal = this.kwartalen[0];
  }

  changeYear(items, fromSelect?) {
    if(fromSelect) {
      localStorage.setItem('jaarFilter', this.selectedYear.toString());
      this.generateKwartalen();
      this.setKwartaalFromStoredFilter();
      return this.jaar$.next(this.selectedYear);
    }

    let yearItems = items.filter(item => {
      const itemDatum: Date = new Date(item.datum);
      return itemDatum.getFullYear().toString() === this.selectedYear.toString();
    });

    return yearItems;
  }

  changeKwartaal(items, fromSelect?): Object[] | void {
    if(fromSelect) {
      localStorage.setItem('kwartaalFilter', this.kwartalen.indexOf(this.kwartaal));
      return this.kwartaal$.next(true);
    }

    const startDatum = this.kwartaal.startDatum;
    const eindDatum = this.kwartaal.eindDatum;

    const _items: Object[] = items.filter(item => {
    const itemDatum = new Date(item.datum);
    //compare date without hours
    itemDatum.setHours(0,0,0,0)
    return itemDatum >= startDatum && itemDatum <= eindDatum;
    });

    return _items;
  }

  changeFiltering() {
    this.filterByKwartaal = !this.filterByKwartaal;
    if (!this.filterByKwartaal) {
      localStorage.removeItem('kwartaalFilter');
    }
    this.kwartaal$.next(true);
  }

  filterByStudent(items, itemsOriginal, fromSelect?): Object[] | void {
    if (fromSelect) {
      localStorage.setItem('studentFilter', JSON.stringify(this.currentStudent))
      return this.student$.next(true);
    }

    let studentItems = [];
    studentItems = items.filter(item => {
       return item.user.leerkrachtCode === this.currentStudent.leerkrachtCode && item.user._id === this.currentStudent._id;
    });

    if(this.currentStudent === 'Alle Studenten'){
      studentItems = itemsOriginal.filter(item => {
        return item.user._id !== this.currentUser._id;
      })
    }

    if(this.currentStudent === '--Eigen Boekingen--'){
      studentItems = itemsOriginal.filter(item => {
        return item.user._id === this.currentUser._id;
      })
    }

    console.log('student items', studentItems)
    return studentItems;
  }

  filterByKlant(items, fromSelect?): Object[] | void {
    if (fromSelect) {
      localStorage.setItem('klantFilter', JSON.stringify(this.currentKlant));
      return this.student$.next(true);
    }

    let klantItems = [];
    klantItems = items.filter(item => {
       return item.user.boekhouderCode === this.currentKlant.boekhouderCode && item.user._id === this.currentKlant._id;
    });

    return klantItems;
  }

  isLeerkracht() {
    return this.currentUser.role === 'Leerkracht';
  }



}

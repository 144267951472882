<div class="container-fluid mt-5">
  <h1>Klanten</h1>
  <button class="btn btn-primary mt-4 mb-3 pull-right" (click)="createKlant()" *ngIf="!expired">Nieuwe Klant</button>
<div class="table-responsive">
<table class="table table-sm table-striped" style="margin-top:50px;">
<thead>
  <tr>
    <th style="min-width:40px;">
      <span class="fa fa-sort sort" (click)="setOrderBy('id')"></span> #
    </th>
    <th style="min-width:150px;"><span class="fa fa-sort sort" (click)="setOrderBy('type')"></span> Type/Nummer</th>
    <th style="min-width:150px;">
      <span class="fa fa-sort sort" (click)="setOrderBy('name')"></span> Naam
    </th>
    <th style="min-width:150px;">BTW Nummer</th>
    <th style="min-width:200px;">IBAN</th>
    <th>Telefoon</th>
    <th style="min-width:250px;">Adres</th>
    <th colspan="2">Acties</th>
  </tr>
</thead>
<tbody>
  <tr *ngFor = "let klant of paginatedRange;">
    <td>{{klant.id}}</td>
    <td>{{klant.type}}</td>
    <td>{{klant.name}}</td>
    <td>{{klant.btwNr}}</td>
    <td>{{klant.iban}}</td>
    <td>{{klant.telnr}}</td>
    <td>
      {{klant.adresStraat}} {{klant.adresGemeente}}
    </td>
    <td>
      <div class="btn-group btn-group-sm pull-right" role="group">
      <!-- <button class="btn btn-light btn-secondary" (click)="deleteKlant(klant)" [disabled]=true data-toggle="tooltip" title="Functie niet beschikbaar"><span class="fa fa-trash"></span></button> -->
      <button class="btn btn-light btn-secondary" (click)="editKlant(klant)"><span class="fa fa-pencil"></span></button>
    </div>
    </td>
  </tr>
</tbody>
<tfoot>
    <tr>
      <td colspan="10">

      </td>
    </tr>
  </tfoot>
</table>
</div>
<app-pagination #pagination
  [(nrOfItems)]="numberOfItems"
  [(paginationMin)]="paginationMin"
  [(paginationMax)]="paginationMax"
  [totalItems]="paginatedItems.length">
</app-pagination>
</div>

<div class="container mt-5">
  <h1>Verkoop</h1>

  <form (ngSubmit)="onSubmit()" style="margin-top:50px;" #formVerkoop="ngForm">
    <div class="form-group row">
      <label for="datum" class="col-12 col-md-3 col-form-label">Datum</label>
      <div class="col-12 col-md-4">
      <div class="input-group">
        <input ngbDatepicker #d="ngbDatepicker" class="form-control" [(ngModel)]="newVerkoop.datum" name="datum" required #verkoopDatum="ngModel">
      <div class="input-group-append" (click)="d.toggle()" type="button">
        <button type="button" class="btn btn-secondary"><span class="fa fa-calendar"></span></button>
        </div>
        </div>
      </div>
      <div class="col-12 col-md-5 error col-form-label" *ngIf="formVerkoop.controls?.datum?.invalid">
        Vul een geldige datum in.
      </div>
    </div>

    <div class="form-group row">
      <label for="klant" class="col-12 col-md-3 col-form-label">Klant
        <span class="error" *ngIf="!newVerkoop.klant._id"><strong>*</strong></span>
      </label>
      <div class="col-12 col-md-4">
        <select name="klant" id="klant" class="form-control" [(ngModel)]="newVerkoop.klant._id" (change)="getKlant(klantId.options[klantId.selectedIndex].text)"
          #klantId required>
          <option></option>
          <option *ngFor="let klant of klanten" [ngValue]="klant._id">{{klant.name}}</option>
        </select>
      </div>
    </div>

    <div class="form-group row">
      <label for="klant" class="col-12 col-md-3 col-form-label">
        Intracommunautair <i class="fa fa-info-circle" aria-hidden="true" ngbTooltip="Een intracommunautaire levering is een verkoop van goederen door een btw-plichtige verkoper uit een EU-lidstaat aan een btw-plichtige koper met een geldig btw-nummer uit een andere EU-lidstaat. Hierbij moeten de goederen effectief van de ene lidstaat naar de andere lidstaat worden vervoerd."></i>
      </label>
      <div class="col-12 col-md-4 col-form-label">
        <input type="checkbox" name="intracommunautair" id="intracommunautair" [checked]="newVerkoop.intracommunautair" [(ngModel)]="newVerkoop.intracommunautair">
      </div>
    </div>

    <div class="form-group row" *ngIf="newVerkoop.intracommunautair">
      <label for="klant" class="col-12 col-md-3 col-form-label">
        IC Type <span  class="error" *ngIf="!newVerkoop.icType"><strong>*</strong></span>
      </label>
      <div class="col-12 col-md-4">
        <select class="form-control" name="icType" id="icType" [(ngModel)]="newVerkoop.icType" required>
          <option value="Diensten">Diensten</option>
          <option value="Levering">Levering</option>
          <option value="Driehoeksverkeer">Driehoeksverkeer</option>
        </select>
      </div>
    </div>


    <div class="form-group row">
      <label for="datum" class="col-12 col-md-3 col-form-label">Bedrag excl. Btw
        <span  class="error" *ngIf="!newVerkoop.bedragExclBTW && newVerkoop.BTW !== 0"><strong>*</strong></span>
      </label>
      <div class="col-12 col-md-4">
        <input class="form-control"
        type="text"
        [(ngModel)]="newVerkoop.bedragExclBTW"
        name="bedragExclBTW"
        required
        (ngModelChange)="calculateBTW()"
        appNumber
        #bedragExclBtw="ngModel"
        >
      </div>
      <div class="col-12 col-md-5 col-form-label">
        <span class="error" *ngIf="bedragExclBtw.errors?.number">Vul een geldig getal in</span>
      </div>
    </div>

    <div class="form-group row">
      <label for="datum" class="col-12 col-md-3 col-form-label">Korting voor contant (%)</label>
      <div class="col-12 col-md-4">
        <input class="form-control"
        type="text"
        [(ngModel)]="newVerkoop.kortingVoorContant"
        name="kortingVoorContant"
        (ngModelChange)="calculateBTW()"
        appNumber
        #kortingVoorContant="ngModel"
        >
      </div>
      <div class="col-12 col-md-5 col-form-label">
        <span class="error" *ngIf="kortingVoorContant.errors?.number">Vul een geldig getal in</span>
      </div>
    </div>

    <div class="form-group row" *ngIf="newVerkoop.kortingVoorContant">
      <label for="datum" class="col-12 col-md-3 col-form-label">Maatstaf van heffing</label>
      <div class="col-12 col-md-4 col-form-label">
        {{getMaatstafVanHeffing() | currencyFormat}}
      </div>
    </div>

    <div class="form-group row">
      <label for="datum" class="col-12 col-md-3 col-form-label">Btw
        <span  class="error" *ngIf="!newVerkoop.BTW"><strong>*</strong></span>
      </label>
      <div class="col-12 col-md-2">
        <select class="form-control" [(ngModel)]="newVerkoop.BTW" name="BTW" required (ngModelChange)="calculateBTW()">
          <option [value]="-1">n.v.t</option>
          <option [value]="0">0</option>
          <option [value]="6">6</option>
          <option [value]="12">12</option>
          <option [value]="21">21</option>
        </select>
      </div>
      <div class="col-12 col-md-4">
        <input type="text" class="form-control" disabled [ngModel]="calculatedBTW | currencyFormat" name="calculatedBTW" maxlength="5">
      </div>
    </div>

    <div class="form-group row">
      <label for="datum" class="col-12 col-md-3 col-form-label">Terugstuurbare verpakking</label>
      <div class="col-12 col-md-4">
        <input class="form-control"
        type="text"
        [(ngModel)]="newVerkoop.terugstuurbareVerpakking"
        name="terugstuurbareVerpakking"
        (ngModelChange)="calculateBTW()"
        appNumber
        #terugstuurbareVerpakking="ngModel"
        >
      </div>
      <div class="col-12 col-md-5 col-form-label">
        <span class="error" *ngIf="terugstuurbareVerpakking.errors?.number">Vul een geldig getal in</span>
      </div>
    </div>

    <div class="form-group row">
      <label for="datum" class="col-12 col-md-3 col-form-label">Bedrag incl. Btw</label>
      <div class="col-12 col-md-4">
        <input class="form-control" type="text" [ngModel]="newVerkoop.bedragInclBTW | currencyFormat" name="bedragInclBTW" disabled>
      </div>
    </div>


    <div class="form-group row">
      <label for="datum" class="col-12 col-md-3 col-form-label">Omschrijving</label>
      <div class="col-12 col-md-4">
        <input class="form-control" type="text" [(ngModel)]="newVerkoop.omschrijving" name="omschrijving">
      </div>
    </div>


    <!-- <div class="col-sm-8">
      <div class="form-group row">
        <label for="datum" class="col-sm-3 col-form-label">Bijlage</label>
        <div class="col-sm-2">
          <input class="form-control col-form-label" type="text" [(ngModel)]="newVerkoop.bijlage" name="bijlage">
        </div>
      </div>
    </div> -->

    <div class="p-3" style="background-color:#efefef; border-radius:10px;">
        <h4>Facturatie</h4>

        <div class="form-group row">
          <label for="datum" class="col-12 col-md-3 col-form-label">Betaald</label>
          <div class="col-12 col-md-4 col-form-label">
            <input type="checkbox" [(ngModel)]="newVerkoop.status" name="status">
          </div>
        </div>

        <div class="form-group row">
          <label for="factuur" class="col-12 col-md-3 col-form-label">Factuur</label>
          <div class="col-12 col-md-4" *ngIf="facturen?.length !== 0 || newVerkoop.factuur?._id !== null">
            <select  name="factuur" id="factuur" class="form-control" [ngModel]="newVerkoop.factuur?._id" (change)="getFactuur(factuurId.options[factuurId.selectedIndex].text)"
              #factuurId>
              <option [ngValue]="null"></option>
              <option *ngFor="let factuur of facturen" [ngValue]="factuur?._id">{{factuur.factuurNummer}}</option>
            </select>
          </div>
          <div class="col-12 col-md-5">
              <span *ngIf="facturen?.length === 0 && newVerkoop.factuur?._id === null" class="col-form-label" style="color:gray; font-size:12px;">Er zijn geen facturen beschikbaar</span>
          </div>
        </div>
    </div>

    <div class="row">
      <div class="col-12 error mb-3">
          * Verplicht in te vullen
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <button type="button" class="btn btn-light mr-3" (click)="navigateAway()">Annuleren</button>
        <button *ngIf="!isEdit" [disabled]="!formVerkoop.valid" type="submit" class="btn btn-primary">Toevoegen</button>
        <button *ngIf="isEdit" [disabled]="!formVerkoop.valid" type="submit" class="btn btn-primary">Opslaan</button>
      </div>
    </div>
  </form>
</div>

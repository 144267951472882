import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '../../models/user';
import { Offerte } from '../../models/Offerte';
import { map } from 'rxjs/operators';

@Injectable()
export class OffertesService {

  constructor(private http: HttpClient) {
  }

  addOfferte(offerte: Offerte){
    let URI = '/api/offertes/';
    let headers = new HttpHeaders;
    let body = {
      klantNaam: offerte.klantNaam,
      klantAdres1: offerte.klantAdres1,
      klantAdres2: offerte.klantAdres2,
      klantNummer: offerte.klantNummer,

      bedrijfsNaam: offerte.bedrijfsNaam,
      bedrijfsAdres1: offerte.bedrijfsAdres1,
      bedrijfsAdres2: offerte.bedrijfsAdres2,

      btwNr: offerte.btwNr,
      IBAN: offerte.IBAN,
      BIC: offerte.BIC,
      telefoon: offerte.telefoon,

      offerteNummer: offerte.offerteNummer,
      datum: offerte.datum,
      geldigheidsDatum: offerte.geldigheidsDatum,
      artikels: offerte.artikels,
      btwPercentage: offerte.btwPercentage,

      opgesteldDoor: offerte.opgesteldDoor,
      opgesteldDoorRol: offerte.opgesteldDoorRol,
      specialeVoorwaarden: offerte.specialeVoorwaarden,
      bestelbon: offerte.bestelbon,
      orderBevestiging: offerte.orderBevestiging,
      pakbon: offerte.pakbon,
      leveringsnota: offerte.leveringsnota,
      btwPerArtikel: offerte.btwPerArtikel
    };
    headers.append('Content-Type', 'application/json');
    return this.http.post(URI, body, {headers: headers})
    .pipe(map(res => res));
  }

  getAllOffertes(currentUser){
    let headers = new HttpHeaders;
    headers.append('Cache-Control', 'no-cache')
    headers.append('Pragma', 'no-cache')
    headers.append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
    return this.http.get('/api/offertes/' + currentUser._id, {headers:headers})
    .pipe(map(res => res));
  }

  getStudentenOffertes(currentUser) {
    let headers = new HttpHeaders;
    headers.append('Cache-Control', 'no-cache')
    headers.append('Pragma', 'no-cache')
    headers.append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
    return this.http.get('/api/offertes/studenten/' + currentUser.leerkrachtCode, {headers:headers})
    .pipe(map(res => res));
  }

  getKlantenOffertes(currentUser) {
    let headers = new HttpHeaders;
    headers.append('Cache-Control', 'no-cache')
    headers.append('Pragma', 'no-cache')
    headers.append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
    return this.http.get('/api/offertes/klanten/' + currentUser.boekhouderCode, {headers:headers})
    .pipe(map(res => res));
  }

  removeOfferte(offerteId: string) {
    let URI = `/api/offertes/${offerteId}`;
    let headers = new HttpHeaders;
    headers.append('Content-Type', 'application/json');
    return this.http.delete(URI, {headers})
    .pipe(map(res => res));
  }

  getOfferte(offerteId: string){
    let headers = new HttpHeaders;
    headers.append('Cache-Control', 'no-cache')
    headers.append('Pragma', 'no-cache')
    headers.append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
    return this.http.get('/api/offertes/offerte/' + offerteId, {headers:headers})
    .pipe(map(res => res));
  }

  updateOfferte(offerte: Offerte){
    let URI = `/api/offertes/offerte/${offerte._id}`;
    let headers = new HttpHeaders;
    let body = {
      klantNaam: offerte.klantNaam,
      klantAdres1: offerte.klantAdres1,
      klantAdres2: offerte.klantAdres2,
      klantNummer: offerte.klantNummer,

      bedrijfsNaam: offerte.bedrijfsNaam,
      bedrijfsAdres1: offerte.bedrijfsAdres1,
      bedrijfsAdres2: offerte.bedrijfsAdres2,

      btwNr: offerte.btwNr,
      IBAN: offerte.IBAN,
      BIC: offerte.BIC,
      telefoon: offerte.telefoon,

      offerteNummer: offerte.offerteNummer,
      datum: offerte.datum,
      geldigheidsDatum: offerte.geldigheidsDatum,
      artikels: offerte.artikels,
      btwPercentage: offerte.btwPercentage,

      opgesteldDoor: offerte.opgesteldDoor,
      opgesteldDoorRol: offerte.opgesteldDoorRol,
      specialeVoorwaarden: offerte.specialeVoorwaarden,
      bestelbon: offerte.bestelbon,
      orderBevestiging: offerte.orderBevestiging,
      pakbon: offerte.pakbon,
      leveringsnota: offerte.leveringsnota,
      generatedFactuur: offerte.generatedFactuur,
      btwPerArtikel: offerte.btwPerArtikel
    };
    headers.append('Content-Type', 'application/json');
    return this.http.put(URI, body, {headers: headers})
    .pipe(map(res => res));
  }

}

<div class="container-fluid mt-5">
  <h1>Financiële verrichtingen</h1>
  <div class="row">
    <div class="col-12">
      <div class="alert alert-danger" *ngIf="expired">Uw account is verstreken. Ga naar de <a href="account">account</a> pagina om een betaling uit te voeren en het account opnieuw te activeren.</div>
    </div>
  </div>
  <div class="row mt-4">
    <app-table-filter-menu #tableMenuFilter class="col-sm-8"></app-table-filter-menu>
  <div class="col-sm-4">
  <button class="btn btn-primary mb-3 pull-right" *ngIf="!expired" (click)="createVerrichting()">Nieuwe Verrichting</button>
</div>
</div>
<div class="row">
  <div class="col-12">
    <div class="table-responsive">
  <table class="table table-sm table-striped mt-4">
    <thead>
      <th>#</th>
      <th><i class="fa fa-sort" (click)="setOrderBy('datum')"></i> Datum</th>
      <th><i class="fa fa-sort" (click)="setOrderBy('type')"></i> Type</th>
      <th style="min-width:150px;" class="text-right">Bedrag</th>
      <th class="pl-4">Omschrijving</th>
      <th>Acties</th>
    </thead>
    <tbody>
      <tr *ngFor="let verrichting of paginatedRange; let i = index">
        <td>{{i+1}}</td>
        <td>{{verrichting.datum | date: 'dd/MM/yyyy'}}</td>
        <td>{{verrichting.type}}</td>
        <td class="text-right">{{verrichting.bedrag.replace(' ','').replace(',','.') | currencyFormat}}</td>
        <td class="pl-4">{{verrichting.omschrijving}}</td>
        <td>
          <div class="btn-group pull-right">
            <button class="btn btn-sm btn-light" (click)="editVerrichting(verrichting)"><i class="fa fa-pencil"></i></button>
            <button class="btn btn-sm btn-light" (click)="deleteVerrichting(verrichting)"><i class="fa fa-trash"></i></button>
          </div>
        </td>
      </tr>
      <tr style="background-color:#343434; color:white;">
        <td colspan="3">
          Saldo {{tableMenuFilter.selectedYear}}:
        </td>
        <td class="text-right">
          {{getSaldo(paginatedItems) | currencyFormat}}
        </td>
        <td colspan="2"></td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <td colspan="10">
        </td>
      </tr>
    </tfoot>
  </table>
</div>
</div>
</div>
<app-pagination #pagination
    [(nrOfItems)]="numberOfItems"
    [(paginationMin)]="paginationMin"
    [(paginationMax)]="paginationMax"
    [totalItems]="paginatedItems.length">
</app-pagination>
<button class="btn btn-primary" type="button" (click)="downloadFile(paginatedItems,'verrichtingen','verrichtingen')">Export CSV</button>
</div>

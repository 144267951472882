<div class="container mt-5">
  <div class="row mt-4">
    <div class="col-12 table-responsive">
      <div class="alert alert-danger" *ngIf="expired">Uw account is verstreken. Ga naar de <a href="account">account</a> pagina om een betaling uit te voeren en het account opnieuw te activeren.</div>
    <div class="current-year">
    <h2>Uw cijfers voor {{currYear}}</h2>

      <table class="table">
        <thead>
          <th><span class="fa fa-caret-square-o-up"></span> Verkopen</th>
          <th><span class="fa fa-caret-square-o-down"></span> Aankopen</th>
          <th>Resultaat</th>
        </thead>
        <tbody>
          <td>{{totaalVerkopen | currencyFormat}}</td>
          <td>{{totaalAankopen | currencyFormat}}</td>
          <td>{{(totaalVerkopen - totaalAankopen) | currencyFormat}}</td>
        </tbody>
      </table>
    </div>
    </div>
  </div>
<div class="row mt-4">
  <div class="col-12 col-md-6">
    <div class="text-center" style="height:300px;">
      <h3 class="mb-1">Verkopen</h3>
      <ngx-charts-bar-vertical
        [scheme]="colorScheme"
        [results]="somVerkopen"
        [xAxis]="true"
        [yAxis]="true"
        [legend]="true"
        [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel"
        [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="yAxisLabel"
        [yAxisTickFormatting]="yToEuro"
        >
        <ng-template #tooltipTemplate let-model="model">
          {{model.value | currencyFormat}}
        </ng-template>
      </ngx-charts-bar-vertical>
    </div>
  </div>
  <div class="col-12 col-md-6">
    <div class="text-center" style="height:300px;">
      <h3 class="mb-1">Aankopen</h3>
      <ngx-charts-bar-vertical
        [scheme]="colorScheme"
        [results]="somAankopen"
        [xAxis]="true"
        [yAxis]="true"
        [legend]="true"
        [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel"
        [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="yAxisLabel"
        [yAxisTickFormatting]="yToEuro"
        >
        <ng-template #tooltipTemplate let-model="model">
          {{model.value | currencyFormat}}
        </ng-template>
      </ngx-charts-bar-vertical>
    </div>
  </div>
</div>

<div *ngIf="currentUser.role === 'Leerkracht'">
  <h1 class="mt-4">Studenten</h1>
  <p>
    U bent ingelogd als leerkracht en vindt hieronder een lijst terug van uw studenten. Om studenten aan uw account te linken
    dienen ze zich te registreren en gebruik te maken van de code die u terugvindt op uw gebruikerspagina. Klik op uw gebruikersnaam
    in de navigatie bovenaan om naar uw gebruikerspagina te gaan.
  </p>
  <div>
    <table class="table table-striped mt-4">
      <thead>
        <th>Voornaam</th>
        <th>Naam</th>
        <th>Acties</th>
      </thead>
      <tbody>
        <tr *ngFor="let student of currentUser.studenten">
        <td>{{student?.firstName}}</td>
        <td>{{student?.lastName}}</td>
        <td><i class="fa fa-remove" style="cursor:pointer;" ngbTooltip="Student verwijderen" (click)="removeStudent(student)"></i></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>


</div>

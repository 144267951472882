import { AuthenticationService } from './../_services/authentication.service';
import { TableFilterMenuComponent } from './../shared/table-filter-menu/table-filter-menu.component';
import { VoorraadbewegingModalComponent } from './voorraadbeweging-modal/voorraadbeweging-modal.component';
import { Product, ProductGroep, ProductInfo } from './../../models/Producten';
import { Component, OnInit, ViewChild, ViewChildren, AfterViewInit } from '@angular/core';
import { map, mergeMap, startWith, switchMap, take } from 'rxjs/operators';
import { ProductenService } from './producten.service';
import { combineLatest, GroupedObservable, Subject, BehaviorSubject, merge } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-inventaris',
  templateUrl: './inventaris.component.html'
})
export class InventarisComponent implements OnInit, AfterViewInit {
  @ViewChild('appTableFilterMenu') appTableFilterMenu: TableFilterMenuComponent;

  productGroep: ProductGroep;
  product: Product;
  productGroepen: ProductGroep[] = [];
  selectedProductGroep: ProductGroep;
  inventaris = null;
  isEdit = false;
  editIndex = 0;
  editGroep = null;

  refresh$ = new BehaviorSubject(true);

  notFound = false;

  productInfo: ProductInfo;

  copyDisabled = true;

  constructor(private productenService: ProductenService,
              private modalService: NgbModal,
              private authenticationService: AuthenticationService) {
  }

  ngAfterViewInit() {
    combineLatest(
      this.appTableFilterMenu.jaar$,
      this.refresh$
    ).pipe(switchMap(() => this.productenService.getInventaris(this.appTableFilterMenu.jaar$.getValue())))
    .subscribe(value => {
      console.log(value)
      const _inventaris: any = value;

      if (_inventaris) {
        this.inventaris = _inventaris;
        this.productGroepen = _inventaris.productGroepen.sort((a,b) => a.groepNummer.localeCompare(b.groepNummer));
        console.log(this.productGroepen)
        this.notFound = false;
      }
      if (!_inventaris) {
        this.notFound = true;
        this.inventaris = null;
      }
    });
  }

  initInventaris() {
    const jaar = this.appTableFilterMenu.jaar$.getValue();
    this.productenService.createInventaris(jaar)
    .pipe(take(1))
    .subscribe((_inventaris: any) => {
      console.log('reassign inventaris')
      this.inventaris = _inventaris;
      this.productGroepen = _inventaris.productGroepen;
      this.refresh$.next(true);
    });
  }

  ngOnInit() {

  }

  addProduct(productGroep: ProductGroep) {
    if (this.isEdit) {
      this.cancel();
    }
    this.product = new Product(this.inventaris.jaar);
    console.log(this.product);
    this.productInfo = this.product.productInfo.find(info => info.jaar === this.inventaris.jaar);
    console.log(this.productInfo);
    this.selectedProductGroep = productGroep;
    console.log(productGroep);
  }

  async saveProduct(productGroep: ProductGroep) {
    let productInfo = this.productInfo;

    if (productInfo.prijs) {
      productInfo.prijs = parseFloat(productInfo.prijs.toString().replace(',', '.'));
    }
    if (productInfo.verkoopPrijs) {
      productInfo.verkoopPrijs = parseFloat(productInfo.verkoopPrijs.toString().replace(',', '.'));
    }

    console.log(this.productInfo._id);
    const saveProduct$ = this.isEdit ?
           this.productenService.updateProduct(this.product, this.productInfo._id) :
           this.productenService.addProduct(this.product, productGroep, this.inventaris.jaar);

    await saveProduct$.pipe(take(1)).subscribe(() => this.refresh$.next(true));
    this.isEdit = false;
    this.selectedProductGroep = null;
    this.product = null;
  }

  addProductGroep() {
    this.productGroep = new ProductGroep();
  }

  async saveProductGroep() {
    await this.productenService.addProductGroep(this.productGroep, this.inventaris).pipe(take(1))
    .subscribe(() => this.refresh$.next(true));
    this.productGroep = null;
  }

  edit(productGroep, i, product) {
    this.product = product;
    this.productInfo = product.productInfo.find(info => info.jaar === this.inventaris.jaar);
    this.editGroep = productGroep;
    this.isEdit = true;
    this.editIndex = i;
  }

  cancel() {
    this.isEdit = false;
    this.selectedProductGroep = null;
    this.product = null;
    this.refresh$.next(true);
  }

  editEnabled(i, groep) {
    return this.isEdit && this.editIndex === i && this.editGroep === groep;
  }

  async delete(product) {
    await this.productenService.deleteProduct(product).pipe(take(1)).subscribe(() => this.refresh$.next(true));
  }

  cancelProductGroep() {
    this.productGroep = null;
  }

  async deleteProductGroep(productGroep) {
    await this.productenService.deleteProductGroep(productGroep).pipe(take(1)).subscribe(() => this.refresh$.next(true));
  }

  getVoorraadBewegingen(product) {
    this.productenService.getVoorraadBewegingen(product.productInfo.find(info => info.jaar === this.inventaris.jaar)._id)
    .pipe(take(1))
    .subscribe(bewegingen => {
      const modalRef = this.modalService.open(VoorraadbewegingModalComponent, { size: 'xl'});
      modalRef.componentInstance.voorraadBewegingen = bewegingen;
      modalRef.componentInstance.productNaam = product.naam;
      modalRef.componentInstance.productNr = product.artikelNummer;
    });
  }

  jaarProductInfo(product) {
    return this.inventaris ? product.productInfo.find(info => info.jaar === this.inventaris.jaar) : null;
  }

  copyInventaris() {
    this.productenService.copyInventaris(this.inventaris).pipe(take(1)).subscribe(() => this.refresh$.next(true));
  }

  openCopyModal(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.copyInventaris();
    }, (reason) => {

    });
  }

  get expired() {
    return this.authenticationService.accountExpired;
  }

}

import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OntvCreditNota } from '../../../models/Creditnota';
import { map } from 'rxjs/operators';


@Injectable()
export class OntvCreditnotaService {


  constructor(private http: HttpClient) {
  }

  getAllOntvCreditNotas(currentUser){
    let headers = new HttpHeaders;
    headers.append('Cache-Control', 'no-cache')
    headers.append('Pragma', 'no-cache')
    headers.append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
    return this.http.get('/api/ontvangen-creditnotas/' + currentUser._id, {headers:headers})
    .pipe(map(res => res));
  }

  addOntvCreditNota(ontvCreditNota: OntvCreditNota){
    let URI = '/api/ontvangen-creditnotas/';
    let headers = new HttpHeaders;
    let body = {
      datum: ontvCreditNota.datum,
      leverancier: ontvCreditNota.leverancier,
      type: ontvCreditNota.type,
      bedragExclBTW: ontvCreditNota.bedragExclBTW,
      BTW: ontvCreditNota.BTW,
      bedragInclBTW: ontvCreditNota.bedragInclBTW,
      omschrijving: ontvCreditNota.omschrijving,
      intracommunautair: ontvCreditNota.intracommunautair,
      icType: ontvCreditNota.icType
    };
    headers.append('Content-Type', 'application/json');
    return this.http.post(URI, body, {headers: headers})
    .pipe(map(res => res));
  }

  getStudentenOntvCreditNotas(currentUser) {
    let headers = new HttpHeaders;
    headers.append('Cache-Control', 'no-cache')
    headers.append('Pragma', 'no-cache')
    headers.append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
    return this.http.get('/api/ontvangen-creditnotas/studenten/' + currentUser.leerkrachtCode, {headers:headers})
    .pipe(map(res => res));
  }

  getKlantenOntvCreditNotas(currentUser) {
    let headers = new HttpHeaders;
    headers.append('Cache-Control', 'no-cache')
    headers.append('Pragma', 'no-cache')
    headers.append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
    return this.http.get('/api/ontvangen-creditnotas/klanten/' + currentUser.boekhouderCode, {headers:headers})
    .pipe(map(res => res));
  }

  updateOntvCreditNota(ontvCreditNota: OntvCreditNota){
    let URI = `/api/ontvangen-creditnotas/creditnota/${ontvCreditNota._id}`
    let headers = new HttpHeaders;
    let body = {
      datum: ontvCreditNota.datum,
      leverancier: ontvCreditNota.leverancier,
      type: ontvCreditNota.type,
      bedragExclBTW: ontvCreditNota.bedragExclBTW,
      BTW: ontvCreditNota.BTW,
      bedragInclBTW: ontvCreditNota.bedragInclBTW,
      omschrijving: ontvCreditNota.omschrijving,
      intracommunautair: ontvCreditNota.intracommunautair,
      icType: ontvCreditNota.icType
    }
    headers.append('Content-Type', 'application/json');
    return this.http.put(URI, body, {headers})
    .pipe(map(res => res));
  }

  getOntvCreditNota(ontvCreditNotaId: string){
    let headers = new HttpHeaders;
    headers.append('Cache-Control', 'no-cache')
    headers.append('Pragma', 'no-cache')
    headers.append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
    return this.http.get('/api/ontvangen-creditnotas/creditnota/' + ontvCreditNotaId, {headers:headers})
    .pipe(map(res => res));
  }

  removeOntvCreditNota(ontvCreditNotaId: string) {
    let URI = `/api/ontvangen-creditnotas/creditnota/${ontvCreditNotaId}`;
    let headers = new HttpHeaders;
    headers.append('Content-Type', 'application/json');
    return this.http.delete(URI, {headers: headers})
    .pipe(map(res => res));
  }
}

import { OrderPipe } from 'ngx-order-pipe';
import { CurrencyFormatPipe } from './../../_pipes/currency-format.pipe';
import { AuthenticationService } from './../../_services/authentication.service';
import { KlantService } from './../../klant/klant.service';
import { AlertService } from './../../_services/alert.service';
import { PaginationService } from './../../_services/pagination/pagination.service';
import { Verkoop } from './../../../models/Verkoop';
import { Klant } from './../../../models/Klant';
import { PaginatedComponent } from '../../paginated/paginated.component';
import { Component, OnInit, Output, EventEmitter, DoCheck, ViewChild, AfterViewInit } from '@angular/core';
import { VerkoopService } from './verkoop.service';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';
import { $ } from 'protractor';


import { Router } from '@angular/router';




@Component({
  selector: 'app-verkoop',
  templateUrl: './verkoop.component.html'
})

export class VerkoopComponent extends PaginatedComponent implements OnInit, AfterViewInit {


  klanten: Klant[];
  filteredVerkopen: Verkoop[];
  btwValue;
  omzetInclBtw = 0;
  omzetExclBtw = 0;
  jaarVerkopen;

  kwartaal;
  selectedYear: string;
  kwartalen;

  verkopen$;

  refresh$ = new BehaviorSubject(false);

  expired = false;

  orderByReversed = {
    id: false,
    klant: false,
    datum: false,
    factuur: {
      factuurNummer: false
    }
  }

  constructor(private authService: AuthenticationService,
              private verkoopService: VerkoopService,
              private klantService: KlantService,
              private alertService: AlertService,
              private router: Router,
              protected paginationService: PaginationService,
              currencyFormatPipe: CurrencyFormatPipe,
              orderPipe: OrderPipe) {
                super(paginationService, 'verkoop', currencyFormatPipe, orderPipe);
                this.expired = this.authService.accountExpired;
              }

  loadItems() {
    return this.isLeerkracht() ?
    this.verkoopService.getStudentenVerkopen(this.currentUser) :
    (this.currentUser.role === 'Boekhouder' ? this.verkoopService.getKlantenVerkopen(this.currentUser) :
     this.verkoopService.getAllVerkopen(this.currentUser));
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.klantService.getAllKlanten(this.currentUser)
    .pipe(take(1))
    .subscribe((klanten: Klant[]) => {
      this.klanten = klanten;
      if (this.klanten.length === 0 && !this.isLeerkracht()) {
        this.alertService.info('U dient eerst klanten aan te maken alvorens u een verkoop kan creëren', false);
      }
    });
  }

  createVerkoop() {
    this.router.navigate(['/verkopen/create']);
  }

  editVerkoop(verkoop){
    this.router.navigate(['/verkopen/edit/', verkoop._id]);
  }

  deleteVerkoop(verkoop: Verkoop) {
    if(confirm('Bent u zeker dat u deze verkoop wil verwijderen?')) {
    this.verkoopService.removeVerkoop(verkoop._id.toString())
    .pipe(take(1))
    .subscribe(
     (response: any) => {
          this.refresh$.next(true);
      });
    }
  }

  getBtwBedrag(verkoop) {
    if(verkoop.BTW === -1) return 0;
    let maatstafVanHeffing = (verkoop.bedragExclBTW - ((verkoop.bedragExclBTW / 100) * verkoop.kortingVoorContant ));
    return (maatstafVanHeffing / 100) * verkoop.BTW;
  }

  getTotaalBtw() {
    let btwTotaal = 0;
    this.paginatedRange.forEach(verkoop => {
      btwTotaal += this.getBtwBedrag(verkoop);
    })
    return btwTotaal;
  }


}



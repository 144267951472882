import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Location } from '@angular/common';
import { Verrichting } from '../../../../models/Verrichting';
import { FinancieelService } from '../financieel.service';
import { NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { I18n, CustomDatepickerI18n } from "../../../ngb-date-fr-parser-formatter/ngb-i18n"
import { NgbDatepickerConfig, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateFRParserFormatter } from "../../../ngb-date-fr-parser-formatter/ngb-date-fr-parser-formatter"
import { GeneralComponent } from '../../../general/general.component';
import { take } from 'rxjs/operators';




@Component({
  selector: 'app-financieel-edit',
  templateUrl: './financieel-edit.component.html',
  providers: [
    I18n,
    {provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter},
    {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}
  ]
})
export class FinancieelEditComponent extends GeneralComponent implements OnInit {
  isEdit = false;
  verrichting;

  constructor(protected location: Location,
              protected financieelService: FinancieelService,
              private route: ActivatedRoute) {
                super();
              }

  ngOnInit() {
    let param = this.route.snapshot.params['id'];
    if(param){
      this.isEdit = true;
      this.financieelService.getVerrichting(param)
      .pipe(take(1))
      .subscribe(verrichting =>
      {
        this.verrichting = verrichting;
        this.verrichting.datum = this.convertInputDate(this.verrichting.datum);
        this.verrichting.bedrag = this.verrichting.bedrag.toString().replace('.',',');
      })
    }

    this.verrichting = {
      _id: null,
      datum: null,
      type: null,
      bedrag: 0,
      omschrijving: '',
    }
  }

  navigateAway(){
    this.location.back();
  }

  public onSubmit() {
    this.finalizeEdit(this.verrichting);
    const addOrUpdateVerrichting = this.isNew(this.verrichting) ?
    this.financieelService.addVerrichting(this.verrichting) :
    this.financieelService.updateVerrichting(this.verrichting);

      addOrUpdateVerrichting
      .pipe(take(1))
      .subscribe(
        response => {
          this.location.back();
        },
      );
  }

}

import { CurrencyFormatPipe } from './../../_pipes/currency-format.pipe';
import { AuthenticationService } from './../../_services/authentication.service';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { Verrichting } from '../../../models/Verrichting';
import { FinancieelService } from './financieel.service';
import { PaginatedComponent } from '../../paginated/paginated.component';
import { PaginationService } from '../../_services/pagination/pagination.service';
import { TableFilterMenuComponent } from '../../shared/table-filter-menu/table-filter-menu.component';
import { take } from 'rxjs/operators';
import { OrderPipe } from 'ngx-order-pipe';

@Component({
  selector: 'app-financieel',
  templateUrl: './financieel.component.html'
})
export class FinancieelComponent extends PaginatedComponent implements OnInit, AfterViewInit {
  @ViewChild(TableFilterMenuComponent) menuFilter: TableFilterMenuComponent;

  ontvangstenSaldo = 0;
  uitgavenSaldo = 0;

  expired = false;

  orderByReversed = {
    datum: false,
    type: false
  }

  constructor(private authService: AuthenticationService,
              protected router: Router,
              protected financieelService: FinancieelService,
              protected paginationService: PaginationService,
              currencyFormatPipe: CurrencyFormatPipe,
              orderPipe: OrderPipe) {
                super(paginationService, 'financieel', currencyFormatPipe, orderPipe);
                this.expired = this.authService.accountExpired;
                }


  loadItems() {
    return this.isLeerkracht() ? this.financieelService.getAllStudentenVerrichtingen(this.currentUser) :
    (this.currentUser.role === 'Boekhouder' ? this.financieelService.getAllKlantenVerrichtingen(this.currentUser) :
    this.financieelService.getAllVerrichtingen(this.currentUser));
  }

  getSaldo(verrichtingen: Verrichting[]) {
    if (verrichtingen) {
      this.ontvangstenSaldo = 0;
      this.uitgavenSaldo = 0;
      let totaalSaldo = 0;
      const ontvangsten = verrichtingen.filter( verrichting =>
      verrichting.type === 'Ontvangst');

      const uitgaven = verrichtingen.filter( verrichting =>
      verrichting.type === 'Uitgave');

      ontvangsten.forEach(ontvangst =>
      this.ontvangstenSaldo = parseFloat(this.ontvangstenSaldo.toString().replace(' ','').replace(',','.')) + parseFloat(ontvangst.bedrag.toString().replace(' ','').replace(',','.')));

      uitgaven.forEach(uitgave =>
      this.uitgavenSaldo = parseFloat(this.uitgavenSaldo.toString().replace(' ','').replace(',','.')) + parseFloat(uitgave.bedrag.toString().replace(' ','').replace(',','.')));

      if (ontvangsten.length !== 0 || uitgaven.length !== 0) {
        totaalSaldo = parseFloat(this.ontvangstenSaldo.toString().replace(' ','').replace(',','.')) - parseFloat(this.uitgavenSaldo.toString().replace(' ','').replace(',','.'));
      } else {
        totaalSaldo = 0;
      }

      return totaalSaldo;
    }
  }

  editVerrichting(verrichting) {
    this.router.navigate(['/financieel/edit/', verrichting._id])
  }

  deleteVerrichting(verrichting: Verrichting){
    if (confirm('Bent u zeker dat u deze fincanciële verrichting wil verwijderen?')) {
    this.financieelService.removeVerrichting(verrichting._id.toString())
    .pipe(take(1))
    .subscribe(
      (response: Verrichting[]) => {
        this.refresh$.next(true);
      });
    }
  }

  createVerrichting(){
    this.router.navigate(['/financieel/create']);
  }
}

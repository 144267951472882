import { IcListingComponent } from './ic-listing/ic-listing.component';
import { KlantenlistingComponent } from './klantenlisting/klantenlisting.component';
import { FinancieelComponent } from './_boeken/financieel/financieel.component';
import { AankoopEditComponent } from './_boeken/aankoop/aankoop-edit/aankoop-edit.component';
import { AankoopComponent } from './_boeken/aankoop/aankoop.component';
import { VerkoopEditComponent } from './_boeken/verkoop/verkoop-edit/verkoop-edit.component';
import { VerkoopComponent } from './_boeken/verkoop/verkoop.component';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { AuthGuard } from './guards/auth.guard';
import { KlantComponent } from './klant/klant.component';
import { LeverancierComponent } from './leverancier/leverancier.component';
import { KlantEditComponent } from './klant/klant-edit/klant-edit.component';
import { LeverancierEditComponent } from './leverancier/leverancier-edit/leverancier-edit.component';
import { OfferteComponent } from './offertes/offerte/offerte.component';
import { FactuurComponent } from './facturen/factuur/factuur.component';
import { OffertesComponent } from './offertes/offertes.component';
import { FacturenComponent } from './facturen/facturen.component';
import { BtwOverviewComponent } from './btw-overview/btw-overview.component';
import { InventarisComponent } from './inventaris/inventaris.component';
import { AccountComponent } from './account/account.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { FinancieelEditComponent } from './_boeken/financieel/financieel-edit/financieel-edit.component';
import { OntvCreditnotaComponent } from './_boeken/ontv-creditnota/ontv-creditnota.component';
import { OntvCreditnotaEditComponent } from './_boeken/ontv-creditnota/ontv-creditnota-edit/ontv-creditnota-edit.component';
import { UitgCreditnotaComponent } from './_boeken/uitg-creditnota/uitg-creditnota.component';
import { UitgCreditnotaEditComponent } from './_boeken/uitg-creditnota/uitg-creditnota-edit/uitg-creditnota-edit.component';

const appRoutes: Routes = [
   { path: '', component: HomeComponent, canActivate: [AuthGuard] },
   { path: 'facturen', loadChildren: () => import('./facturen/facturen.module').then(m => m.FacturenModule)},
   { path: 'offertes', loadChildren: () => import('./offertes/offertes.module').then(m => m.OffertesModule)},
   { path: 'login', component: LoginComponent },
   { path: 'register', component: RegisterComponent },
   { path: 'forgot_password', component: ForgotPasswordComponent},
   { path: 'reset_password', component: ResetPasswordComponent},
   { path: 'verkopen', component: VerkoopComponent, canActivate: [AuthGuard] },
   { path: 'verkopen/create', component: VerkoopEditComponent, canActivate: [AuthGuard] },
   { path: 'verkopen/edit/:id', component: VerkoopEditComponent, canActivate: [AuthGuard] },
   { path: 'aankopen', component: AankoopComponent, canActivate: [AuthGuard] },
   { path: 'aankopen/create', component: AankoopEditComponent, canActivate: [AuthGuard] },
   { path: 'aankopen/edit/:id', component: AankoopEditComponent, canActivate: [AuthGuard] },
   { path: 'klanten', component: KlantComponent, canActivate: [AuthGuard] },
   { path: 'klant/create', component: KlantEditComponent, canActivate: [AuthGuard]},
   { path: 'klant/edit/:id', component: KlantEditComponent, canActivate: [AuthGuard]},
   { path: 'leveranciers', component: LeverancierComponent, canActivate: [AuthGuard]},
   { path: 'leverancier/edit/:id', component: LeverancierEditComponent, canActivate: [AuthGuard]},
   { path: 'leverancier/create', component: LeverancierEditComponent, canActivate: [AuthGuard]},
   { path: 'financieel', component: FinancieelComponent, canActivate:[AuthGuard]},
   { path: 'financieel/create', component: FinancieelEditComponent, canActivate:[AuthGuard]},
   { path: 'financieel/edit/:id', component: FinancieelEditComponent, canActivate:[AuthGuard]},
   { path: 'btw', component: BtwOverviewComponent, canActivate:[AuthGuard]},
   { path: 'ontvangen-creditnotas', component: OntvCreditnotaComponent, canActivate:[AuthGuard]},
   { path: 'ontvangen-creditnotas/create', component: OntvCreditnotaEditComponent, canActivate:[AuthGuard]},
   { path: 'ontvangen-creditnotas/edit/:id', component: OntvCreditnotaEditComponent, canActivate:[AuthGuard]},
   { path: 'uitgaande-creditnotas', component: UitgCreditnotaComponent, canActivate:[AuthGuard]},
   { path: 'uitgaande-creditnotas/create', component: UitgCreditnotaEditComponent, canActivate:[AuthGuard]},
   { path: 'uitgaande-creditnotas/edit/:id', component: UitgCreditnotaEditComponent, canActivate:[AuthGuard]},
   { path: 'inventaris', component: InventarisComponent, canActivate:[AuthGuard]},
   { path: 'account', component: AccountComponent, canActivate:[AuthGuard]},
   { path: 'klantenlisting', component: KlantenlistingComponent, canActivate: [AuthGuard]},
   { path: 'ic-listing', component: IcListingComponent, canActivate: [AuthGuard]},

   // otherwise redirect to home
   { path: '**', redirectTo: '' }
];

export const routing = RouterModule.forRoot(appRoutes, {
    scrollPositionRestoration: 'top',
    anchorScrolling: 'enabled'
});
